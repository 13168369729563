.debug-grid {
	position: fixed;
	z-index: 1000;
	background-color: red;
	width: 100%;
	height: 100%;
	opacity: 0.2;
	display: none;
	pointer-events: none;

	.grid-margin-offset {
		@include poly-margin-fluid-property('padding-left');
		@include poly-margin-fluid-property('padding-right');

		width: calc(100%);
		height: 100%;
		display: flex;
		max-width: #{$max-page-width};

		div {
			background-color: yellow;
			height: 100%;
			width: 100%;
			margin-right: $gutter-size;
			display: none;
		}

		@media (min-width: $media-breakpoint-xs) {
			div:nth-child(-n + 4) {
				display: block;
			}

			div:nth-child(4) {
				margin-right: 0px;
			}
		}

		@media (min-width: $media-breakpoint-sm) {
			div {
				display: block;
			}

			div:nth-child(4) {
				margin-right: $gutter-size;
			}

			div:last-child {
				margin-right: 0px;
			}
		}
	}

	&.shown {
		display: flex;
		align-content: center;
		justify-content: space-around;
	}
}
