.LearnMoreBreakerModule {
	position: relative;

	& + .LearnMoreBreakerModule {
		.spacing {
			margin-top: 40px;
		}
	}

	@mixin createColorSetting($color) {
		&.#{$color} {
			$map: get-color-map($color);

			background-color: map-get($map, $key: 'background');

			a.btn-pill {
				color: map-get($map, $key: 'background') !important;

				&:hover {
					color: $color-white !important;
				}
			}
		}
	}

	.text {
		@include color('background-color', 'background');

		@include createColorSetting('green');
		@include createColorSetting('orange');
		@include createColorSetting('purple');
		@include createColorSetting('blue');
		@include createColorSetting('teal');
		@include createColorSetting('yellow');

		a:not(.btn-pill) {
			color: $color-white !important;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	.wrapper {
		display: flex;
		width: 100%;

		flex-direction: column;

		@media (min-width: $media-breakpoint-md) {
			flex-direction: row;
			min-height: 400px;
		}

		.image {
			width: 100%;
			background-color: $color-grey-light-background;
			overflow: hidden;

			@include aspect-ratio(280px, 189px);

			@media (min-width: $media-breakpoint-md) {
				&::before {
					display: none;
				}
			}

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				font-family: 'object-fit: cover';
			}

			@media (min-width: $media-breakpoint-md) {
				height: auto;
				width: 50%;
			}
		}

		.text {
			position: relative;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: flex-start;

			width: 100%;
			padding: $gutter-size;

			min-height: 280px;

			@media (min-width: $media-breakpoint-md) {
				height: initial;
				padding: 45px 30px 30px 40px;
				width: 50%;
			}

			p {
				align-self: flex-start;
				height: 100%;
				margin-top: 20px;
			}

			h2 {
				width: 100%;

				@media (min-width: $media-breakpoint-lg) {
					width: 80%;

					&.rounded-28-light {
						width: 90%;
					}
				}
			}

			color: $color-white;

			a {
				@include color('color', 'background');

				@media (min-width: $media-breakpoint-md) {
					align-self: flex-end;
				}
			}
		}
	}

	.btn-pill {
		margin-top: $gutter-size;
	}
}
