.NewsletterTemplate {
	.HeroModule {
		.text-container {
			@media (min-width: $media-breakpoint-lg) {
				background-image: url('/assets/svg/embedded/newsletter_bg.svg');
				background-repeat: no-repeat;
				background-position: 45% center;
			}
		}
	}
}
