.SmallImageCarouselModule {
	position: relative;
	overflow-x: hidden;

	background-color: $color-grey-light-background;
	padding: $section-top-spacing 0px $section-top-spacing;

	@media (min-width: $media-breakpoint-lg) {
		padding: $section-top-spacing * 2 0px $section-top-spacing * 1.5;
	}

	+ footer {
		margin-top: -$section-top-spacing !important;

		@media (min-width: $media-breakpoint-lg) {
			margin-top: -$section-top-spacing * 1.5;
		}
	}

	.header-container {
		display: flex;
		justify-content: space-between;
		width: 100%;

		.subheader {
			padding-right: 0px;
			width: 100%;
			@include arabic() {
				padding-left: 0;
				@media (min-width: $text-inset-breakpoint) {
					padding-right: getColWidth(1, 12, 1);
				}

				@media (min-width: $burger-menu-breakpoint) {
					padding-right: getColWidth(2, 12, 1);
				}
			}
		}

		.carousel-next-prev-buttons {
			align-self: flex-end;
			margin-bottom: 30px;
			flex-grow: 0;
			flex-shrink: 0;
			margin-left: 20px;
			@include arabic() {
				margin-left: 0px;
				margin-right: 20px;
			}
		}
	}

	.carousel-container {
		position: relative;

		.dragged-area {
			display: flex;
			height: 100%;
			// background-color: $color-grey-light-background;

			@media (min-width: $media-breakpoint-md) {
				margin-left: getColWidth(1, 12, 1);
				@include arabic() {
					margin-left: 0;
					margin-right: getColWidth(1, 12, 1);
				}
			}

			@media (min-width: $media-breakpoint-lg) {
				margin-left: getColWidth(2, 12, 1);
				@include arabic() {
					margin-left: 0;
					margin-right: getColWidth(2, 12, 1);
				}
			}

			.ImageGridItem {
				flex-shrink: 0;
				width: 100%;
				margin-right: $gutter-size;
				@include arabic() {
					margin-right: 0;
					margin-left: $gutter-size;
				}
				@media (min-width: $media-breakpoint-lg) {
					width: getColWidth(4, 10, 1);

					margin-right: 0px;
					padding-right: $gutter-size;
					@include arabic() {
						padding-right: 0;
					}
				}

				&:last-child {
					padding-right: 0px;
					@include arabic() {
						padding-left: 0;
					}
					@media (min-width: $media-breakpoint-lg) {
						width: getColWidth(4, 10);
					}
				}
			}
		}
	}
}
