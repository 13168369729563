html,
body {
	margin: 0;
	padding: 0;
	font-size: 22px;
	font-family: $font-family-serif;
	vertical-align: baseline;
	height: 100%;
	//	text-rendering: auto;
	//	text-rendering: optimizeSpeed;
	//text-rendering: optimizeLegibility;
	//	text-rendering: geometricPrecision;
	//	text-rendering: auto;

	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	-webkit-tap-highlight-color: transparent;
}

html[lang='ar'] {
	direction: rtl;
}

html[lang='ja'] {
	font-family: $font-family-serif, $font-japanese;

	body {
		font-family: $font-family-serif, $font-japanese;
	}
}
html[lang='zh-hans'] {
	font-family: $font-family-serif, $font-mandarin;

	body {
		font-family: $font-family-serif, $font-mandarin;
	}
}

body {
	overflow-y: scroll; //hidden;//scroll;
	overflow-x: hidden;
	background-color: $color-white;
	color: $color-black;
}

* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	box-sizing: border-box;
}

a,
a:visited,
a:active,
a:hover {
	//color: inherit;
	text-decoration: none;
}
a:focus {
	outline: none;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
sub,
sup,
tt,
var,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	//border: 0;
	//font: inherit;
	//vertical-align: baseline;
}

#TemplateLayer {
	display: flex;
	flex-direction: column;
	top: 0;
	left: 0;
	position: absolute;
	width: 100%;
	height: 100%;
	min-height: 100%;
	padding-top: $menu-mobile-height;
	@media (min-width: $burger-menu-breakpoint) {
		padding-top: $menu-desktop-height;
	}
}

#ScrollToBottomExample {
	position: fixed;
	top: 0;
	z-index: 20;
	color: red;
	left: 0;
}
