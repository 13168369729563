.TextModule {
	position: relative;

	.padding {
		position: relative;

		figure {
			max-width: 100%;
			line-height: 0;
		}

		figcaption {
			@include font('Source Sans Pro', 15px, 20px, 500);
			width: 100%;
			//background-color: $color-grey;
			color: rgba($color: $color-dark-grey, $alpha: 0.5);
			padding: 15px 10px;
			margin: 0;

			@media (min-width: $media-breakpoint-md) {
				padding: 15px 10px;
			}
		}

		img {
			max-width: 100%;
		}

		.align-right {
			text-align: right;
		}

		.align-left {
			text-align: left;
		}

		.align-center {
			text-align: center;
		}

		> p,
		> figure {
			@media (min-width: $text-inset-breakpoint) {
				padding-left: getColWidth(1, 12, 1);
				padding-right: getColWidth(1, 12, 1);
			}

			@media (min-width: $burger-menu-breakpoint) {
				padding-left: getColWidth(2, 12, 1);
				padding-right: getColWidth(2, 12, 1);
			}

			color: $color-dark-grey;
			margin-bottom: 70px;
		}

		> p.body + p.body,
		> p:not([class]) + p:not([class]),
		> p.body + p:not([class]),
		> p:not([class]) + p.body {
			margin-top: -40px;
		}

		> p:last-child {
			margin-bottom: 0px;
		}

		ul,
		ol {
			@media (min-width: $text-inset-breakpoint) {
				margin-left: getColWidth(1, 12, 1);
				margin-right: getColWidth(1, 12, 1);
			}

			@media (min-width: $burger-menu-breakpoint) {
				margin-left: getColWidth(2, 12, 1);
				margin-right: getColWidth(2, 12, 1);
			}

			margin-bottom: 70px;

			@include color('color', 'text');

			li {
				margin-top: 20px;
				padding-left: 40px;
			}
		}

		ol {
			padding-left: 17px;
		}

		ul {
			list-style-type: none;
			display: block;
			position: relative;

			li {
				display: list-item;
				position: relative;
				padding-left: 60px;

				&:before {
					position: absolute;
					content: '—';
					left: 0px;
					font-size: 18px;
				}
			}
		}

		h2 {
			@include color('color', 'text');

			@media (min-width: $text-inset-breakpoint) {
				padding-left: getColWidth(1, 12, 1);
				padding-right: getColWidth(1, 12, 1);
			}

			@media (min-width: $burger-menu-breakpoint) {
				padding-left: getColWidth(2, 12, 1);
				padding-right: getColWidth(2, 12, 1);
			}

			margin-bottom: 45px;
		}

		h3.subheader {
			margin-top: 0px;
			margin-bottom: 15px;
		}

		h4 {
			@media (min-width: $text-inset-breakpoint) {
				padding-left: getColWidth(1, 12, 1);
				padding-right: getColWidth(1, 12, 1);
			}

			@media (min-width: $burger-menu-breakpoint) {
				padding-left: getColWidth(2, 12, 1);
				padding-right: getColWidth(2, 12, 1);
			}

			color: $color-dark-grey;
			margin-bottom: 70px;
		}

		blockquote {
			margin-top: -2px;
			margin-bottom: 33px;
			padding: 0px;

			@media (min-width: $text-inset-breakpoint) {
				width: getColWidth(8, 12);
			}

			cite {
				display: block;
				opacity: 0.5;
			}

			p {
				padding-left: 0px;
				padding-right: 0px;
				margin-bottom: 0px;
			}

			p:first-child {
				@include color('color', 'background');
			}

			> p:first-child::before {
				content: '"';
			}

			> p:first-child::after {
				content: '"';
			}
		}

		a {
			@include color('color', 'links');

			&:link,
			&:visited {
				@include color('color', 'links');

				text-decoration: underline;
			}

			&:hover {
				text-decoration: none;
			}

			&:active {
				text-decoration: underline;
			}
		}
	}
}
