.HomeTemplate {
  .padding>.content {
      margin-top: 100px;

      color: $color-dark-grey;

      margin-left: getColWidth(1, 12, 1);
      margin-right: getColWidth(1, 12, 1); 

      @media(min-width: $media-breakpoint-md) {
          margin-left: getColWidth(2, 12, 1);
          margin-right: getColWidth(2, 12, 1);    
      }

      p {
          margin-top: 18px;
          color: $color-dark-grey; 
      }

      a {
          display:block;
          margin-top: 25px;
      }
  }
}
