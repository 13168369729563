.FooterBannerModule {
	width: 100%;

	max-width: 330px;

	display: flex;

	@include aspect-ratio(660, 552);

	@media (min-width: $media-breakpoint-md) {
		order: 2;
		width: 50%;
	}

	@media (min-width: $media-breakpoint-lg) {
		order: 0;
		flex-direction: column;
		align-items: flex-end;
		//width: auto;
	}

	& > * {
		pointer-events: none;
		opacity: 0;
		transition: opacity 0.3s;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		&.FooterBannerModule--active {
			opacity: 1;
			pointer-events: all;
		}

		picture,
		img {
			width: 100%;
		}
	}
}
