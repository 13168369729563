.PercentageBarModule {
	$vert-breakpoint: $media-breakpoint-sm;

	position: relative;

	padding-bottom: 52px;
	border-bottom: 1px solid $color-light-grey;

	p {
		color: $color-brand;
	}

	.subheader {
		margin-bottom: 4px;
	}

	.padding {
		position: relative;

		.text {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: flex-start;

			.source {
				margin-top: 14px;
				opacity: 0.5;
			}

			@media (min-width: $text-inset-breakpoint) {
				p:first-child {
					max-width: getColWidth(6, 8);
				}

				padding-left: getColWidth(1, 12, 1);
				padding-right: getColWidth(1, 12, 1);
			}

			@media (min-width: $burger-menu-breakpoint) {
				p:first-child {
					max-width: getColWidth(4, 8);
				}

				padding-left: getColWidth(2, 12, 1);
				padding-right: getColWidth(2, 12, 1);
			}
		}

		.percentage-bar {
			position: relative;

			width: 100%;
			display: flex;
			height: 40px;
			border-radius: 78px;
			overflow: hidden;

			background-color: $color-grey-light-background;

			margin-top: 40px;

			.bar {
				position: relative;
				height: 100%;
				display: flex;
				width: 100%;

				@include color('background-color', 'background');

				&:nth-child(2) {
					@include color('background-color', 'text');
				}

				&:nth-child(3) {
					@include color('background-color', 'illustration');
				}

				&.hidden {
					width: 0% !important;
				}
			}
		}

		.percentage-data {
			display: flex;
			margin-top: 30px;
			width: auto !important;

			flex-direction: row;

			// @media (min-width: $vert-breakpoint) {
			// flex-direction: row;
			// }

			.data-text {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				padding-right: $gutter-size;
				min-width: 70px;
				margin-right: 3px;

				&.hidden {
					transform: translate3d(0, 50px, 0);
					opacity: 0;
				}

				@media (max-width: $vert-breakpoint) {
					// width: 100% !important;
				}

				@media (min-width: $vert-breakpoint) {
					// flex-direction: column;
					// align-items: flex-start;
				}

				&:nth-child(2) {
					h4 {
						@include color('color', 'text');
					}

					@media (max-width: $media-maxwidth-breakpoint) {
						margin-right: 30px;
					}

					@media (min-width: $media-breakpoint-md) {
						margin-right: 20px;
					}

					@media (min-width: $media-breakpoint-lg) {
						margin-right: 3px;
					}
				}

				&:nth-child(3) {
					h4 {
						@include color('color', 'illustration');
					}
				}

				h4 {
					min-width: 70px;

					@include color('color', 'background');
				}

				p {
					line-height: 16px;
					@media (max-width: $media-breakpoint-md) {
						-webkit-hyphens: auto;
						-ms-hyphens: auto;
						hyphens: auto;
					}

					@include poly-fluid-sizing(
						'margin-top',
						(
							320px: 5px,
							768px: 0px
						)
					);
				}
			}
		}
	}
}
