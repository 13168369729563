/* font, weight, size, line-height, not color */
@mixin font($font-family, $font-size, $line-height: '', $font-weight: '') {
	font-family: $font-family;
	font-size: $font-size;

	@if ($line-height != '') {
		line-height: $line-height;
	}

	@if ($font-weight != '') {
		font-weight: $font-weight;
	}
	@at-root html[lang='ja'] & {
		font-family: $font-family, $font-japanese;
	}
	@at-root html[lang='ar'] & {
		font-family: $font-family, $font-arabic-normal;
	}
	@at-root html[lang='zh-hans'] & {
		font-family: $font-family, $font-mandarin;
	}
}

@mixin localeFont($font-family, $font-weight: '') {
	@at-root html[lang='ja'] & {
		font-family: $font-family, $font-japanese;
		@if ($font-weight != '') {
			font-weight: $font-weight;
		}
	}
	@at-root html[lang='ar'] & {
		font-family: $font-family, $font-arabic-normal;
		@if ($font-weight != '') {
			font-weight: $font-weight;
		}
	}
	@at-root html[lang='zh-hans'] & {
		font-family: $font-family, $font-mandarin;
		@if ($font-weight != '') {
			font-weight: $font-weight;
		}
	}
}

.body-text {
	font-family: 'Source Sans Pro';
	font-size: 16px;
	line-height: 22px;

	@include localeFont('Source Sans Pro');
	@media (min-width: $media-breakpoint-xl) {
		font-size: 18px;
		line-height: 24px;
	}
}

.text-style-h1 {
	font-family: 'SesameWkshp-Regular';
	font-weight: normal;
	text-align: center;

	@include poly-fluid-sizing(
		'font-size',
		(
			320px: 32px,
			576px: 48px,
			768px: 60px
		)
	);

	@include poly-fluid-sizing(
		'line-height',
		(
			320px: 36px,
			576px: 52px,
			768px: 72px
		)
	);
	@include localeFont('SesameWkshp-Regular', normal);
}

.text-style-h2 {
	font-family: 'SesameWkshp-Regular';
	font-weight: normal;
	font-size: 26px;
	line-height: 32px;
	@include localeFont('SesameWkshp-Regular', normal);
	@include poly-fluid-sizing(
		'font-size',
		(
			768px: 31px,
			992px: 32px,
			1200px: 39.5px,
			1440px: 48px
		)
	);

	@include poly-fluid-sizing(
		'line-height',
		(
			768px: 42px,
			992px: 42px,
			1200px: 45px,
			1440px: 52px
		)
	);
}

//VAG Rounded Std
.rounded-12-light {
	@include font('SesameWkshp-Regular', 12px, '', normal);
}

.rounded-16-light {
	@include font('SesameWkshp-Regular', 16px, '', normal);
}

.rounded-18-light {
	@include font('SesameWkshp-Regular', 18px, 21px, normal);
}

.rounded-20-light {
	@include font('SesameWkshp-Regular', 20px, 23px, normal);

	@media (min-width: $media-breakpoint-md) {
		font-size: 20px;
		line-height: 23px;
	}
}

.rounded-22-light {
	@include font('SesameWkshp-Regular', 22px, 28px, normal);
}

.rounded-24-light {
	@include font('SesameWkshp-Regular', 24px, 30px, normal);
}

.rounded-24-light-adaptive {
	@include font('SesameWkshp-Regular', 18px, 24px, normal);
	@media (min-width: $media-breakpoint-md) {
		font-size: 24px;
		line-height: 32px;
	}
}

.rounded-28-light {
	@include font('SesameWkshp-Regular', 28px, 34px, normal);
}

.rounded-32-light {
	@include font('SesameWkshp-Regular', 32px, 38px, normal);
}

.rounded-36-light {
	@include font('SesameWkshp-Regular', 28px, 32px, normal);

	@media (min-width: $media-breakpoint-sm) {
		font-size: 33px;
		line-height: 37px;
	}

	@media (min-width: $media-breakpoint-lg) {
		font-size: 36px;
		line-height: 40px;
	}
}

.rounded-38-light {
	@include font('SesameWkshp-Regular', 28px, 32px, normal);

	@media (min-width: $media-breakpoint-md) {
		font-size: 38px;
		line-height: 44px;
	}
}

.rounded-38-light-percentage-bars {
	font-family: 'SesameWkshp-Regular';
	font-weight: normal;
	@include localeFont('SesameWkshp-Regular');

	@include poly-fluid-sizing(
		'font-size',
		(
			320px: 16px,
			768px: 38px
		)
	);

	@include poly-fluid-sizing(
		'line-height',
		(
			320px: 16px,
			768px: 38px
		)
	);
}

//this is the general subheader text.
.rounded-42-light {
	@include font('SesameWkshp-Regular', 32px, 38px, normal);
	@media (min-width: $media-breakpoint-sm) {
		font-size: 38px;
		line-height: 46px;
	}

	@media (min-width: $media-breakpoint-lg) {
		font-size: 42px;
		line-height: 48px;
	}

	@media (min-width: $media-breakpoint-xl) {
		font-size: 48px;
		line-height: 53px;
	}
}

.rounded-48-light {
	@include font('SesameWkshp-Regular', 48px, 55px, normal);
}

.rounded-large-number {
	@include font('SesameWkshp-Regular', 48px, 48px, normal);

	@media (min-width: $media-breakpoint-xl) {
		font-size: 80px;
		line-height: 80px;
	}
}

//Source Sans Pro
.source-12-regular {
	@include font('Source Sans Pro', 12px, 15px);
}

.source-12-bold {
	@include font('Source Sans Pro', 12px, 15px, 700);
	footer & {
		@include font('SesameWkshp-Regular', 12px, 15px, normal);
	}
}

.source-14-regular {
	@include font('Source Sans Pro', 14px, 20px);
}

.source-14-semibold {
	@include font('Source Sans Pro', 14px, 14px, 600);
}

.source-15-regular {
	@include font('Source Sans Pro', 15px, 20px, 500);
	header & {
		@include font('Source Sans Pro', 14px, 20px, 500);
	}
}

.source-16-light {
	@include font('Source Sans Pro', 16px, '', 300);
}

.source-16-regular {
	@include font('Source Sans Pro', 16px, 22px, 500);
}

.source-16-semibold {
	@include font('Source Sans Pro', 16px, 19px, 600);
}

.source-18-regular {
	@include font('Source Sans Pro', 18px, 26px, 500);
}

.source-20-regular {
	@include font('Source Sans Pro', 20px, 28px, 500);
}

.source-20-light {
	@include font('Source Sans Pro', 16px, 20px, 300);

	@media (min-width: $media-breakpoint-md) {
		font-size: 20px;
		line-height: 25px;
	}
}

.source-24-light {
	@include font('Source Sans Pro', 18px, 22px, 300);

	@media (min-width: $media-breakpoint-sm) {
		font-size: 20px;
		line-height: 26px;
	}

	@media (min-width: $media-breakpoint-lg) {
		font-size: 22px;
		line-height: 30px;
	}

	@media (min-width: $media-breakpoint-xl) {
		font-size: 24px;
		line-height: 34px;
	}

	strong {
		font-weight: 500;
	}
}

.source-variable-body {
	@include font('Source Sans Pro', 14px, 18px, 500);

	@media (min-width: $media-breakpoint-xl) {
		font-size: 16px;
		line-height: 22px;
	}
}

//Adelle
.adelle-12 {
	@include font('Adelle', 12px);
}

.adelle-20-medium {
	@include font('Adelle', 16px, 25px, 500);

	@media (min-width: $media-breakpoint-md) {
		font-size: 20px;
		line-height: 30px;
	}
}

//Text Module specific
.article {
	h1 {
		font-family: 'SesameWkshp-Regular';
		font-weight: normal;
		@include localeFont('VAG Rounded Std');
	}

	.header {
		@include font('SesameWkshp-Regular', 32px, 38px, normal);

		@media (min-width: $media-breakpoint-sm) {
			font-size: 38px;
			line-height: 46px;
		}

		@media (min-width: $media-breakpoint-lg) {
			font-size: 42px;
			line-height: 48px;
		}

		@media (min-width: $media-breakpoint-xl) {
			font-size: 48px;
			line-height: 53px;
		}
	}

	.subheader {
		@include font('SesameWkshp-Regular', 28px, 32px, normal);
	}

	h4 {
		@include font('Adelle', 24px, 32px, 300);

		@media (min-width: $media-breakpoint-sm) {
			font-size: 28px;
			line-height: 38px;
		}
	}

	blockquote {
		@include font('SesameWkshp-Regular', 48px, 55px, normal);
	}

	cite {
		@include font('Source Sans Pro', 20px, 30px);

		font-size: 12px;
		line-height: 15px;
	}

	> p {
		@include font('Source Sans Pro', 20px, 30px);

		&.quoted {
			font-size: 12px;
			line-height: 15px;
		}

		strong {
			@include font('SesameWkshp-Regular', 28px, 34px, normal);
		}
	}

	ul,
	ol {
		@include font('Source Sans Pro', 20px, 28px);
	}
}

//Text Module specific end

//main-content CKEditor styling, the color syntax is intentional :)
.main-content {
	.colored {
		@include color('color', 'links');
	}

	p.large {
		@include font('SesameWkshp-Regular', 20px, 23px, normal);

		a {
			&:link,
			&:visited {
				text-decoration: none;
			}

			&:hover {
				text-decoration: underline;
			}

			&:active {
				text-decoration: underline;
			}
		}
	}

	a {
		@include color('color', 'links');

		&:link,
		&:visited {
			text-decoration: underline;
		}

		&:hover {
			text-decoration: none;
		}

		&:active {
			text-decoration: underline;
		}
	}
}

//main-content CKEditor styling,

.text-style-h2-large {
	font-family: 'SesameWkshp-Regular';
	font-weight: normal;
	font-size: 40px;
	line-height: 46px;
	@include localeFont('SesameWkshp-Regular', normal);
	@include poly-fluid-sizing(
		so 'font-size',
		(
			768px: 38px,
			1200px: 48px
		)
	);

	@include poly-fluid-sizing(
		'line-height',
		(
			768px: 44px,
			1200px: 53px
		)
	);
}

.text-style-nav-link {
	font-family: 'SesameWkshp-Regular';
	font-size: 20px;
	font-weight: normal;
	@include localeFont('SesameWkshp-Regular');
	@media (min-width: $burger-menu-breakpoint) {
		font-size: 26px;
	}
}

.text-style-nav-main-link {
	font-family: 'Source Sans Pro';
	font-size: 22px;
	@include localeFont('Source Sans Pro');

	@media (min-width: $burger-menu-breakpoint) {
		font-size: 15px;
		header & {
			font-size: 14px;
		}
	}
}

.text-style-contact-link {
	font-family: 'Adelle';
	font-size: 14px;
	font-weight: bold;
	@include localeFont('Adelle');
	@media (min-width: $burger-menu-breakpoint) {
		font-size: 14px;
		font-weight: 400;
		opacity: 1;
	}
}

.input-text {
	font-family: 'Source Sans Pro';
	font-size: 16px;
	@include localeFont('Source Sans Pro');
	&::placeholder {
		font-style: italic;
	}
}

.input-btn-text {
	font-family: 'Source Sans Pro';
	font-size: 18px;
	font-weight: 600;
	@include localeFont('Source Sans Pro');
	label {
		span {
			font-size: 16px;
		}
	}
}
