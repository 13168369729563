.TimelineModule {
	position: relative;
	overflow-x: hidden;
	padding-bottom: 40px;

	&.no-scrubber {
		padding-bottom: 0px;
		border-bottom: 1px solid $color-light-grey;
	}

	.header-container {
		display: flex;
		justify-content: space-between;
		width: 100%;

		.subheader {
			padding-right: 0px;
			width: 100%;
			@include arabic() {
				padding-left: 0;
				@media (min-width: $text-inset-breakpoint) {
					padding-right: getColWidth(1, 12, 1);
				}

				@media (min-width: $burger-menu-breakpoint) {
					padding-right: getColWidth(2, 12, 1);
				}
			}
		}

		.carousel-next-prev-buttons {
			align-self: flex-end;
			margin-bottom: 30px;
			flex-grow: 0;
			flex-shrink: 0;
			margin-left: 20px;
			@include arabic() {
				margin-left: 0px;
				margin-right: 20px;
			}
		}
	}

	.timeline-container {
		position: relative;
		height: 100%;

		&.variable-height {
			height: auto;

			.scrolled-area > div {
				height: auto;
			}
		}

		.scrolled-area {
			position: relative;
			display: inline-flex;
			height: 100%;

			> .TimelineModule--item {
				padding: 0px 30px 30px;

				position: relative;
				border-left: 1px solid $color-light-grey;
				width: 320px;
				flex-shrink: 0;

				color: $color-brand;
				@include arabic() {
					border-left: 0;
					border-right: 1px solid $color-light-grey;
				}

				@media (min-width: $media-breakpoint-sm) {
					width: 376px;
				}

				&::before {
					content: '';
					position: absolute;
					left: -4px;
					top: 0px;
					width: 7px;
					height: 7px;
					border-radius: 7px;

					@include arabic() {
						left: auto;
						right: -4px;
					}
					@include color('background-color', 'background');
				}

				.wrapper {
					position: relative;
					margin-top: -2px;

					.year {
						@include color('color', 'background');
						opacity: 0.8;

						margin-bottom: 20px;
					}
				}

				&.year-section {
					width: 166px;
					@include color('color', 'background');

					.wrapper {
						margin-top: -5px;
					}

					@media (min-width: $media-breakpoint-sm) {
						width: 262px; //will be overridden by js, this is fallback
					}
				}

				&.image-section {
					.image-container {
						width: 100%;
						//height: auto;

						& > .aspect-content {
							position: relative;
							background: $color-grey;
							display: block;
							content: '';
							width: 100%;
							padding-top: (270 / 320) * 100%;

							> img {
								position: absolute;
								width: 100%;
								top: 0;
								left: 0;
								right: 0;
								bottom: 0;
							}
						}
					}

					h3 {
						margin-top: 17px;
					}

					p {
						margin-top: 7px;
					}
				}

				&.text-section {
					p {
						margin-top: 10px;
					}
				}
			}
		}
	}

	.mobile-timeline-slider {
		position: relative;
		border-top: 1px solid $color-light-grey;
		border-bottom: 1px solid $color-light-grey;
		padding: 15px 0px 10px;

		color: $color-darker-grey;

		@media (min-width: $media-breakpoint-lg) {
			display: none;
		}

		.years {
			user-select: none;
			position: relative;
			display: flex;

			left: calc(50% - 30px);

			.year {
				padding-right: 20px;

				&.active,
				&:hover {
					@include color('color', 'background');
				}

				&:hover {
					cursor: pointer;
				}
			}
		}
	}

	.desktop-timeline-slider {
		display: none;
		position: relative;
		width: 100%;

		align-items: center;
		align-self: center;
		flex-flow: column;

		border-top: 1px solid $color-light-grey;

		@media (min-width: $media-breakpoint-lg) {
			display: flex;
		}

		.container {
			width: 100%;
			max-width: $max-page-width;
		}

		.dragger-container {
			position: absolute;
			width: 0px;
			height: 0px;

			.dragger {
				position: relative;
				@include color('background-color', 'background');
				left: -26px;
				top: -15px;
				width: 52px;
				height: 30px;
				border-radius: 30px;

				display: flex;
				justify-content: space-between;

				padding: 1px 10px;

				transition: 0.2s width, 0.2s left;
				@include arabic() {
					left: auto;
					right: -26px;
				}

				.left-arrow {
					transition: 0.2s transform;
				}

				.right-arrow {
					transition: 0.2s transform;
				}

				&:hover {
					cursor: grab;

					.left-arrow {
						transform: translateX(5px);
					}

					.right-arrow {
						transform: translateX(-5px);
					}
				}

				&:active {
					width: 70px;
					left: -35px;
					cursor: grabbing;

					.left-arrow {
						transform: translateX(5px);
					}

					.right-arrow {
						transform: translateX(-5px);
					}
				}
			}
		}

		.dots {
			display: flex;
			justify-content: flex-start;
			margin-top: -10px;
			height: 10px;
			width: 100%;
			position: relative;

			.dot-container {
				position: absolute;
				width: 0px;
				height: 0px;

				&.passed {
					.dot {
						@include color('background-color', 'background');
					}
				}

				.segment-dot {
					position: relative;
					width: 5px;
					height: 5px;
					border-radius: 5px;

					top: 7px;

					background-color: $color-light-grey;
				}

				.dot-hitarea {
					width: 20px;
					height: 20px;
					margin-left: -7px;
					margin-right: -7px;
					position: relative;

					&:hover {
						cursor: pointer;

						> .year {
							// display: block;
							opacity: 1;
							transform: translateY(0px);
						}

						.hover-dot {
							transform: scale(1);
						}

						> .dot {
							background-color: $color-white;
						}
					}

					> .dot {
						position: relative;
						left: 4px;
						top: 6px;

						width: 7px;
						height: 7px;
						border: 1px solid;
						border-radius: 20px;
						@include color('border-color', 'background');
						background-color: $color-white;
						transition: 0.2s background-color;
						@include arabic() {
							left: auto;
							right: 4px;
						}
					}

					.hover-dot {
						transform: scale(0);
						position: absolute;
						left: -3px;
						top: -1px;
						width: 21px;
						height: 21px;
						border-radius: 21px;
						@include color('background-color', 'background');
						transition: 0.2s transform;
						@include arabic() {
							left: auto;
							right: -3px;
						}
					}

					> .year {
						display: block;
						position: relative;
						text-align: center;
						top: -32px;
						left: -2px;
						@include color('color', 'links');

						transform: translateY(10px);
						opacity: 0;
						transition: 0.2s transform, 0.2s opacity;
						@include arabic() {
							left: auto;
							right: -2px;
						}
						&:before {
							content: '';
							margin-left: -100%;
						}

						&:after {
							content: '';
							margin-right: -100%;
						}
					}
				}
			}
		}
	}
}
