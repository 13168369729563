// When the player is absurdly tiny, display nothing but:
// - Play button
// - Fullscreen Button
.video-js.vjs-layout-tiny:not(.vjs-fullscreen) {
	.vjs-custom-control-spacer {
		@include flex(auto);
		display: block;
	}

	&.vjs-no-flex .vjs-custom-control-spacer {
		width: auto;
	}

	.vjs-current-time,
	.vjs-time-divider,
	.vjs-duration,
	.vjs-remaining-time,
	.vjs-playback-rate,
	.vjs-progress-control,
	.vjs-mute-control,
	.vjs-volume-control,
	.vjs-volume-panel,
	.vjs-chapters-button,
	.vjs-descriptions-button,
	.vjs-captions-button,
	.vjs-subtitles-button,
	.vjs-subs-caps-button,
	.vjs-audio-button {
		display: none;
	}
}

// When the player is x-small, display nothing but:
// - Play button
// - Progress bar
// - Fullscreen Button
.video-js.vjs-layout-x-small:not(.vjs-fullscreen) {
	.vjs-current-time,
	.vjs-time-divider,
	.vjs-duration,
	.vjs-remaining-time,
	.vjs-playback-rate,
	.vjs-mute-control,
	.vjs-volume-control,
	.vjs-volume-panel,
	.vjs-chapters-button,
	.vjs-descriptions-button,
	.vjs-captions-button,
	.vjs-subtitles-button,
	.vjs-subs-caps-button,
	.vjs-audio-button {
		display: none;
	}
}

// When the player is small, display nothing but:
// - Play button
// - Progress bar
// - Volume menu button
// - Subs-Caps Button
// - Fullscreen button
.video-js.vjs-layout-small:not(.vjs-fullscreen) {
	.vjs-current-time,
	.vjs-time-divider,
	.vjs-duration,
	.vjs-remaining-time,
	.vjs-playback-rate,
	.vjs-mute-control,
	.vjs-volume-control,
	.vjs-volume-panel,
	.vjs-chapters-button,
	.vjs-descriptions-button,
	.vjs-captions-button,
	.vjs-subtitles-button,
	.vjs-audio-button {
		display: none;
	}
}
