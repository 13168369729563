.MapModule {
	.container {
		position: relative;
		display: flex;
		flex-flow: row;
		justify-content: space-between;
		z-index: 0;

		.sidebar {
			position: relative;
			z-index: 2;

			display: flex;
			flex-direction: column;
			min-height: 200px;
			min-width: 220px;
			width: auto;

			a {
				display: block;
				color: $color-brand;
				/*@include font('Source Sans Pro', 18px, 34px, 600);*/
				font-family: 'Source Sans Pro';
				font-size: 20px;
				font-weight: 700;
				line-height: 34px;
				@include localeFont('Source Sans Pro');
				transition: color 0.2s;
				&.active {
					transition: color 0.3s;
					@include color('color', 'text');
				}
			}
		}

		.map {
			display: none;
			position: relative;
			height: 100%;
			width: 100%;

			/*padding-left: getColWidth(2.5, 12);

			@media (min-width: $text-inset-breakpoint) {
				padding-left: getColWidth(1, 12);
			}*/

			/*@media (min-width: $burger-menu-breakpoint) {
				padding-left: getColWidth(2, 12);
			}*/

			@media (min-width: $media-breakpoint-md) {
				display: block;
				top: -45px;
			}

			@media (min-width: $media-breakpoint-lg) {
				top: 0;
			}

			.mapContainer {
				@include aspect-ratio(1048, 546);
			}

			svg {
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
				//visibility: hidden;
				width: 100%;
				overflow: visible;
				.str0 {
					stroke: #838383;
				}
			}

			#svgHovers {
				& > g {
					cursor: pointer;
					opacity: 0;
					transition: opacity 0.2s;

					&.active {
						transition: opacity 0.4s;
						opacity: 1;
					}
				}
			}
		}
	}
	padding-bottom: 0;

	@media (min-width: $media-breakpoint-md) {
		padding-bottom: calc((100% + 20px) / 12 * 1.5 + -20px);
	}

	@media (min-width: $media-breakpoint-lg) {
		padding-bottom: calc((100% + 20px) / 12 * 2 + -20px);
	}
}
