.ImageTabsModule {
	position: relative;

	.image-tabs {
		position: relative;
		display: flex;
		width: 100%;
		height: 300px;

		.tab {
			position: relative;
			width: 100%;

			img {
				width: 100%;
				height: 100%;

				object-fit: cover;
				font-family: 'object-fit: cover';
			}

			> div {
				position: absolute;
				background-color: rgba($color: $color-black, $alpha: 0.5);
				bottom: 20px;
				left: 20px;
				right: 20px;
				padding: 20px;

				display: flex;
				justify-content: space-between;
				align-items: flex-start;
				min-height: 113px;

				transition: bottom 0.2s, min-height 0.2s;

				p {
					color: $color-white;
					line-height: 20px;
				}

				.btn-expand {
					margin-left: 20px;
					width: 40px;
					height: 40px;
					border-radius: 40px;
					flex-grow: 0;
					flex-shrink: 0;
					border: 2px solid $color-white;

					background-color: initial;
				}
			}

			&:hover,
			&.active {
				> div {
					bottom: -20px;
					@include color('background-color', 'background');
					min-height: 153px;

					.btn-expand {
						background-color: $color-white;

						svg {
							* {
								@include color('stroke', 'background');
							}
						}
					}
				}
			}

			&:hover {
				cursor: pointer;
			}

			&.active {
				&:hover {
					cursor: initial;
				}

				.btn-expand {
					svg {
						transform: rotate(180deg) translateY(1px);
					}
				}
			}
		}
	}

	.TextModule {
		margin-top: 100px;

		.article {
			display: none;

			&.active {
				display: block;
			}
		}
	}
}
