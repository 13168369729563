.LearnMoreBreakerSliderModule {
	position: relative;
	.carousel-container {
		overflow: hidden;
	}
	.LearnMoreBreakerSliderModule__slides-container {
		display: flex;
		flex-flow: row;
		flex-wrap: nowrap;
		& > .LearnMoreBreakerModule {
			flex-shrink: 0;
			width: 100%;
		}
	}

	.dots-container {
		margin-top: 25px;
		display: flex;
		align-self: center;
		justify-content: center;
		@media (min-width: $media-breakpoint-md) {
			margin-top: 0;
			position: absolute;
			bottom: 0px;
			left: 50%;
			padding-left: 45px;
			padding-bottom: 30px;
		}
		.dot {
			width: 8px;
			height: 8px;
			border-radius: 8px;
			background-color: $color-dark-grey;
			opacity: 0.3;
			margin-right: 10px;
			@media (min-width: $media-breakpoint-md) {
				background-color: $color-white;
			}
			transition: 0.2s transform;
			cursor: pointer;

			&:hover {
				transform: scale(1.3);
			}

			&.active {
				opacity: 1;
			}

			&:last-child {
				margin-right: 0px;
			}
		}
	}
}
