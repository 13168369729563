.Popup__bg-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #ffffff;
	z-index: 1000;
}

.Popup {
	position: absolute;
	top: 70px;
	left: 0;
	width: 100%;
	z-index: 1001 !important;
	display: flex;
	flex-flow: row;
	justify-content: center;
	max-width: 608px;
	pointer-events: none;
	@media (min-width: 608px) {
		left: calc(50% - 304px);
	}
	@media (min-width: $media-breakpoint-md) {
		left: 0;
		padding: 20px;
		max-width: 1140px;
	}
	@media (min-width: 1140px) {
		left: calc(50% - 570px);
	}

	&.no-image {
		.Popup__image-wrapper {
			display: none;
		}

		.Popup__right {
			.Popup__title {
				margin-top: 0;
			}

			@media (min-width: 1100px) {
				padding: 40px 50px;
			}
		}
	}

	.Popup__inner {
		pointer-events: all;
		min-height: 100px;
		box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
		background: #ffffff;
		display: flex;
		flex-flow: column;
		@media (min-width: $media-breakpoint-md) {
			flex-flow: row;
			justify-content: center;
		}
	}

	.Popup__right {
		max-width: 608px;
		width: 100%;
		//height: 100%;
		padding: 20px;
		display: flex;
		flex-flow: column;
		overflow: auto;
		@media (min-width: $media-breakpoint-md) {
			padding: 60px;
		}
		@media (min-width: 1100px) {
			padding: 75px 50px 75px 130px;
		}
	}

	.Popup__download-button {
		height: 40px;
		background: map-get($color-yellow-map, 'background');
		padding-top: 0;
		display: inline-flex;
		align-items: center;
		line-height: 16px;
		justify-content: center;
		margin-top: 20px;
		justify-self: flex-end;
		align-self: flex-start;
		&.hidden {
			display: none;
		}

		svg {
			margin-left: 18px;
			//margin-top: 4px;
		}
	}

	.Popup__title {
		margin-top: 38px;
		color: map_get($color-orange-map, 'text');
	}

	.Popup__subheader {
		margin-top: 10px;
	}

	.Popup__image-wrapper {
		width: 100%;
		align-self: flex-start;
		@include aspect-ratio(428, 590);

		&:before {
			background: #f9f9f9;
		}

		@media (min-width: $media-breakpoint-md) {
			min-width: 428px;
		}

		img {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			left: 0;
			width: 100%;
		}
	}

	&.PartnersPopup {
		.Popup__image-wrapper {
			align-self: stretch;
			background: #f9f9f9;
			img {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				left: 20px;
				width: calc(100% - 40px);
			}
		}
	}

	.Popup__description {
		margin-top: 40px;
		color: #333333;
		margin-bottom: auto;
	}

	.Popup__controls {
		position: absolute;
		z-index: 1;
		display: flex;
		flex-flow: row;
		justify-content: flex-end;
		width: 100%;
		padding: 20px;
		@media (min-width: $media-breakpoint-md) {
			position: relative;
			justify-content: flex-start;
			width: 64px;
			padding: 20px 0;
			flex-shrink: 0;
			align-items: center;
			flex-flow: column;
			order: 3;
			border-left: 1px solid #efefef;
		}

		.Popup__control {
			cursor: pointer;
			background: map-get($color-yellow-map, 'background');
			border-radius: 50%;
			width: 40px;
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
}
