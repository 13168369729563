.video-js button {
	background: none;
	border: none;
	color: inherit;
	display: inline-block;

	font-size: inherit; // IE in general. WTF.
	line-height: inherit;
	text-transform: none;
	text-decoration: none;
	transition: none;

	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;

	&.vjs-play-control,
	&.vjs-fullscreen-control {
		color: #191919;
		background: $color-white;
		border-radius: 50%;
		width: 40px;
		height: 40px;
	}
}

.vjs-control .vjs-button {
	width: 100%;
	height: 100%;
}
