.covid-care {
	// To prevent confliction with bootstrap css on covid landing page.
	.container {
		max-width: 100%;
	}
	.video-wrapper,
	.grid-wrapper,
	.drive-wrapper,
	.soundcloud-wrapper {
		display: flex;
		flex-wrap: wrap;
		//justify-content: space-between;
		margin-top: 50px;
	}
	.youtube_video_section,
	.gdrive_section {
		width: 49%;
		margin-bottom: 2%;
		@media (max-width: $media-maxwidth-breakpoint) {
			width: 100%;
			margin-bottom: 25px;
		}
		iframe {
			&.lazyloaded {
				width: 100%;
			}
		}
		&:last-of-type {
			@media (max-width: $media-maxwidth-breakpoint) {
				margin-bottom: 0;
			}
		}
		&:nth-child(2n + 2) {
			@media (min-width: $media-breakpoint-md) {
				margin-left: 2%;
			}
		}
	}
	.gdrive_section {
		.more_button {
			/* margin-top: 50px; */
		}
	}
	.download_button {
		text-align: center;
		margin-top: 50px;
		@media (max-width: $media-maxwidth-breakpoint) {
			margin-top: 25px;
		}
	}
	.ImageLinkGridModule,
	.iframeGridModule {
		margin-top: 120px;
		@media (max-width: $media-maxwidth-breakpoint) {
			margin-top: 80px;
		}
	}
	.BreadCrumbModule + .iframeGridModule {
		margin-top: 56px;
	}
	.TextModule + .iframeGridModule {
		margin-top: 120px;
	}
	.picture-wrapper {
		/* 	width: 49%;
		margin-bottom: 2%; */
		@media (max-width: $media-maxwidth-breakpoint) {
			width: 100%;
			margin-bottom: 25px;
		}
		img {
			&.lazyloaded {
				width: 100%;
			}
		}
		&:last-of-type {
			@media (max-width: $media-maxwidth-breakpoint) {
				margin-bottom: 0;
			}
		}
		&:nth-child(2n + 2) {
			@media (min-width: $media-breakpoint-md) {
				margin-left: 2%;
			}
		}
	}
	.view_large_button {
		text-align: center;
		margin-top: 50px;
		@media (max-width: $media-maxwidth-breakpoint) {
			margin-top: 25px;
		}
	}
	.soundcloud_audio_section {
		width: 23%;
		margin-bottom: 2%;
		@media (max-width: $media-maxwidth-breakpoint) {
			width: 100%;
			margin-bottom: 25px;
		}
		&:last-of-type {
			@media (max-width: $media-maxwidth-breakpoint) {
				margin-bottom: 0;
			}
		}
		&:nth-child(4n + 2) {
			@media (min-width: $media-breakpoint-md) {
				margin-left: 2%;
				margin-right: 2%;
			}
		}
		&:nth-child(4n + 3) {
			@media (min-width: $media-breakpoint-md) {
				margin-right: 2%;
			}
		}
	}
	.more_button {
		/* margin-top: 25px; */
		text-align: center;
	}
	.video-wrapper {
		.download_button {
			display: none;
		}
	}

	.HeroModule {
		.background-image {
			background-color: transparent;
			&.cover-fit {
				img {
					height: auto;
				}
			}
		}
		&:not(.fullscreen) {
			.background-image {
				&:before {
					padding-top: 39%;

					@media (min-width: $media-breakpoint-sm) {
						padding-top: 35%;
					}

					@media (min-width: $media-breakpoint-md) {
						padding-top: 34.10417%;
					}

					@media (min-width: $media-breakpoint-lg) {
						padding-top: 32.66667%;
					}
				}
			}
		}
	}
}

.covid-video-kits,
.covid-audio-kit {
	// To prevent confliction with bootstrap css on covid landing page.
	.container {
		max-width: 100%;
	}
	.video-wrapper,
	.soundcloud-wrapper {
		display: flex;
		flex-wrap: wrap;
		// justify-content: space-between;
	}
	.youtube_video_section,
	.soundcloud_audio_section {
		width: 32%;
		margin-bottom: 2%;
		@media (max-width: $media-maxwidth-breakpoint) {
			width: 100%;
			margin-bottom: 25px;
		}
		&:last-of-type {
			@media (max-width: $media-maxwidth-breakpoint) {
				margin-bottom: 0;
			}
		}
		&:nth-child(3n + 2) {
			@media (min-width: $media-breakpoint-md) {
				margin-left: 2%;
				margin-right: 2%;
			}
		}
		h2,
		.soundcloud_title {
			margin-top: 20px;
			@include font('SesameWkshp-Regular', 28px, 34px, normal);
		}
		p {
			margin-top: 8px;
			color: $color-brand;
			@include font('Source Sans Pro', 20px, 28px, 500);
		}
		iframe {
			&.lazyloaded {
				width: 100%;
			}
		}
	}
	.soundcloud_media_section {
		margin-top: 20px;
	}
	.download_button {
		margin-top: 20px;
	}
}

.covid-ebooks {
	// To prevent confliction with bootstrap css on covid landing page.
	.container {
		max-width: 100%;
	}
	.drive-wrapper {
		display: flex;
		flex-wrap: wrap;
		//justify-content: space-between;
	}
	.gdrive_section {
		width: 49%;
		margin-bottom: 2%;
		@media (max-width: $media-maxwidth-breakpoint) {
			width: 100%;
			margin-bottom: 25px;
		}
		&:last-of-type {
			@media (max-width: $media-maxwidth-breakpoint) {
				margin-bottom: 0;
			}
		}
		&:nth-child(2n + 2) {
			@media (min-width: $media-breakpoint-md) {
				margin-left: 2%;
			}
		}
		iframe {
			&.lazyloaded {
				width: 100%;
			}
		}
	}
	.TextModule {
		.article {
			p {
				&:nth-child(1) {
					font-size: 24px;
					color: #00ab4d;
				}
				strong {
					font-size: 20px;
					font-weight: bold;
				}
			}
		}
	}
	.download_button {
		text-align: center;
		margin-top: 50px;
		@media (max-width: $media-maxwidth-breakpoint) {
			margin-top: 25px;
		}
	}
	.grid-container {
		@media (min-width: $media-breakpoint-md) {
			padding-left: calc(((100% + 20px) / 12) * 1 + 20px * 0);
			padding-right: calc(((100% + 20px) / 12) * 1 + 20px * 0);
		}

		@media (min-width: $media-breakpoint-lg) {
			padding-left: calc(((100% + 20px) / 12) * 2 + 20px * 0);
			padding-right: calc(((100% + 20px) / 12) * 2 + 20px * 0);
		}
	}
}

.covid-care,
.covid-video-kits,
.covid-ebooks,
.covid-audio-kit {
	&.white-page {
		.HeroModule {
			.text-container {
				background-color: $color-green;
			}
			.text-style-h1,
			.text-style-subheader {
				color: $color-white;
			}
		}
		.btn-pill {
			&.filled {
				&:hover {
					border-color: $color-green;
					background-color: transparent;
					color: $color-green;
				}
			}
		}
	}
}

.iframeGridModule .modal {
	.modal-dialog {
		max-width: 765px;
		.modal-body {
			p.close-wrap {
				display: inline-block;
				width: 100%;
				text-align: right;
			}
			.youtube_video_section {
				width: 100%;
				margin-left: 0;
				.iframe-wrapper {
					position: relative;
					width: 100%;
					height: 0;
					padding-bottom: 56.25%;
					iframe {
						position: absolute;
						left: 0;
						top: 0;
						width: 100%;
						height: 100%;
						display: inline-block;
					}
				}
			}
			.gdrive_section {
				margin-left: 0;
				width: 100%;
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				> div {
					flex: 0 0 49%;
					@media (max-width: 567px) {
						flex: 0 0 100%;
					}
					&.iframe-wrapper {
						height: 0;
						padding-bottom: 56.25%;
						position: relative;
						> iframe {
							position: absolute;
							width: 100%;
							height: 100%;
							left: 0;
							top: 0;
							display: inline-block;
						}
					}
					.download_button {
						text-align: left;
					}
				}
			}
			.picture-wrapper {
				width: 100%;
				display: flex;
				flex-wrap: wrap;
				margin-left: 0;
				justify-content: space-between;
				> div {
					flex: 0 0 49%;
					@media (max-width: 567px) {
						flex: 0 0 100%;
					}
					.view_large_button {
						text-align: left;
					}
				}
			}
			.soundcloud_audio_section {
				width: 100%;
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				.soundcloud_media_section {
					margin-top: 20px;
				}
				> div {
					flex: 0 0 49%;
					@media (max-width: 567px) {
						flex: 0 0 100%;
					}
					&.soundcloud_audio_ifm {
						height: 0;
						padding-bottom: 26.25%;
						@media (max-width: 567px) {
							padding-bottom: 56.25%;
						}
						position: relative;
						> iframe {
							position: absolute;
							width: 100%;
							height: 100%;
							left: 0;
							top: 0;
							display: inline-block;
						}
					}
				}
			}
		}
	}
}
