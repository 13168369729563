.NewsletterFullSignupFormModule {
	position: relative;
	justify-content: center;

	align-items: center;

	.centering {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		max-width: $max-page-width;

		@include poly-margin-fluid-property('padding-left');
		@include poly-margin-fluid-property('padding-right');
	}

	.wrapper {
		@media (max-width: $media-maxwidth-breakpoint) {
			width: 100%;
		}
	}

	.form-item {
		margin-top: 15px;
		display: flex;
		flex-flow: column;
		&.zipWrapper {
			width: 60px;
		}
		&.emaiLWrapper {
			width: calc(100% - 80px);
		}
	}

	.input-area {
		width: 100%;
		display: flex;
		flex-direction: column;

		@media (min-width: $media-breakpoint-md) {
			min-width: 420px;

			width: getColWidth(4, 8);
			// margin-top: 0px;
			// margin-right: 20px;
			margin: 0 auto;
		}

		@media (min-width: $media-breakpoint-xl) {
			width: getColWidth(4, 8);
			margin: 0 auto;
		}

		.form-row {
			display: flex;
			flex-flow: row;
			justify-content: space-between;
		}

		label {
			font-size: 12px;
			font-family: 'Source Sans Pro';
			@include localeFont('Source Sans Pro');
			margin-bottom: 8px;
		}

		.source-12-regular {
			opacity: 0.8;
			max-width: 250px;
		}

		.input-text {
			width: 100%;
			border: 1px solid $color-light-grey;
			padding: 0px 10px;
			border-radius: 2px;
			height: 40px;
		}

		.submitWrapper {
			display: flex;
			flex-flow: column;
			margin-top: 40px;
		}

		.btn-pill {
			align-self: center;
			margin-top: 23px;
			width: 150px;

			padding-top: 0px;
			padding-bottom: 0px;

			&:focus {
				outline: none;
			}

			@media (min-width: $media-breakpoint-md) {
				margin-top: 0px;
			}
		}
	}

	input {
		&.error {
			border-color: red !important;
		}

		&::placeholder {
			font-size: 14px;
			//line-height: 40px;
		}
	}

	.NewsletterFullSignupFormModule__Result {
		color: red !important;
		.response {
			font-size: 30px;
			margin-bottom: 30px;
			color: #00ab4d;
			text-align: center;
			line-height: 1.1;
			a {
				color: #00ab4d;
				display: block;
				text-decoration: underline;
			}

			@media (max-width: $media-maxwidth-breakpoint) {
				font-size: 24px;
			}
		}
	}
}
