.CircularProgressBarModule {
	//THIS SHOULD BE GENERIC
	p {
		color: $color-brand;
	}

	.text {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		.source {
			margin-top: 14px;
			opacity: 0.5;
		}

		@media (min-width: $text-inset-breakpoint) {
			p:first-child {
				max-width: getColWidth(6, 8);
			}

			padding-left: getColWidth(1, 12, 1);
			padding-right: getColWidth(1, 12, 1);
		}

		@media (min-width: $burger-menu-breakpoint) {
			p:first-child {
				max-width: getColWidth(4, 8);
			}

			padding-left: getColWidth(2, 12, 1);
			padding-right: getColWidth(2, 12, 1);
		}
	}
	//THIS SHOULD STOP BEING GENERIC

	.ring-segment {
		margin-top: 55px;

		display: flex;
		width: 100%;
		justify-content: space-between;
		align-items: center;
		flex-direction: column;

		@media (min-width: $text-inset-breakpoint) {
			flex-direction: row;
		}

		@media (min-width: $burger-menu-breakpoint) {
			padding-left: getColWidth(1, 12, 1);
			padding-right: getColWidth(1, 12, 1);
		}

		@media (min-width: $media-breakpoint-xl) {
			padding-left: getColWidth(2, 12, 1);
			padding-right: getColWidth(2, 12, 1);
		}

		.left,
		.right {
			text-align: center;

			p {
				max-width: 180px;
			}

			&.hidden {
				transform: translate3d(0, 50px, 0);
				opacity: 0;
			}
		}

		.left {
			margin-bottom: $gutter-size;

			@media (min-width: $text-inset-breakpoint) {
				margin-right: $gutter-size;
				margin-bottom: 0px;

				// text-align: left;

				h4 {
					margin-left: -6px;
				}
			}
		}

		.right {
			margin-top: $gutter-size;

			@media (min-width: $text-inset-breakpoint) {
				margin-left: $gutter-size;
				// text-align: right;
				margin-top: 0px;

				h4 {
					margin-right: -6px;
				}
			}
		}

		.ring {
			position: relative;
			z-index: 0;

			width: 316px;
			height: 316px;
			flex-shrink: 0;

			> * {
				position: absolute;
				top: 0;
				left: 0;
			}

			.center-image {
				width: calc(100% - 20px);
				height: calc(100% - 20px);
				overflow: hidden;

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
					font-family: 'object-fit: cover';

					&.hidden {
						transform: translate3d(0px, 100%, 0px);
					}
				}

				top: 9px;
				left: 8px;
				border-radius: 315px;
			}
		}
	}

	.progress-ring {
		&.hidden {
			display: none;
		}

		&.grey {
			svg * {
				stroke: $color-grey-light-background;
			}
		}
	}

	.big {
		@include color('color', 'background');

		&.progress-ring {
			z-index: 0;
		}

		svg * {
			@include color('stroke', 'background');
		}
	}

	.small {
		@include color('color', 'links');

		&.progress-ring {
			z-index: 1;
		}

		svg * {
			@include color('stroke', 'links');
		}
	}
}
