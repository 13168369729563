.FundLinkList {

  @media (min-width: $media-breakpoint-md) {
	 margin-top: 140px;
  }

	.list {
    &.three {
      margin-top: 50px;
      .list-item {
        padding-bottom: 0;
        border-top: none;
      }
    }
	}
	.list-item {
    margin-bottom: 30px;
   	@include font('SesameWkshp-Regular', 24px, 30px, normal);
   	a {
   		color: $color-black;
   		text-decoration: none;
   		&:hover {
   			color: $color-teal;
   			transition: .3s ease-out;
   		}
   	}
  }
  ~ .FundLinkList {
    margin: 100px 0 0;
    
    @media (min-width: $media-breakpoint-md) {
		  margin: 140px 0 90px;
    }
	}
}
