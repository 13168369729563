html,
body {
	margin: 0;
	padding: 0;
}

* {
	box-sizing: border-box;
}

.slider {
	width: 100%;
	margin: 56px auto 0;
}
.slick-track {
	display: flex;
	align-items: flex-start;
}
.slick-slide {
	margin: 0px 20px;
}

.slick-slide img {
	width: 100%;
	cursor: pointer;
}
.slick-track {
	margin-left: 0;
	margin-right: 0;
}

.slick-prev:before,
.slick-next:before {
	color: rgb(0, 255, 13);
}

button {
	&.slick-arrow {
		width: 30px;
		height: 45px;
		background: #8d8b8b;
		&:hover,
		&:focus {
			background: #8d8b8b;
		}
		&:before {
			content: '';
			width: 20px;
			height: 20px;
			transform: rotate(45deg);
			display: inline-block;
			position: relative;
		}
		&.slick-prev {
			&:before {
				border-left: 2px solid #fff;
				border-bottom: 2px solid #fff;
				left: 5px;
			}
		}
		&.slick-next {
			&:before {
				border-right: 2px solid #fff;
				border-top: 2px solid #fff;
				right: 5px;
			}
		}
	}
}
.slick-initialized {
  .slick-slide {
    &.more_button {
      display: flex;
      flex-wrap: wrap;
      align-self: center;
    }
  }
}
.slick-slide {
	transition: all ease-in-out 0.3s;
	&.slick-active {
		&:focus {
			outline: none;
		}
	}
	.caption {
		p {
			@extend .source-16-regular;
			margin-top: 5px;
			margin-bottom: 5px;
		}
		h4 {
			margin-top: 5px;
			margin-bottom: 5px;
			@extend .source-16-semibold;
		}
	}
}

.slick-active {
}

.slick-current {
}
