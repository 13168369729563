div[data-title='Who We Are'] {
	section.ImageLinkGridModule {
		&.spacing.two {
			.subheader {
				.source-24-light {
					font-weight: normal;
				}
			}
			.ImageGridItem {
				.content {
					p {
						@media (min-width: $media-breakpoint-md) {
							width: 70%;
						}
					}
					.source-20-regular {
						font-size: 16px;
						line-height: 22px;
					}
				}
			}
		}
	}
	.MiniDonateBlock {
		form {
			@media (min-width: $media-breakpoint-xl) {
				width: calc(60% + 10px);
			}
		}
	}
}
