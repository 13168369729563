.video-js .vjs-menu-button-inline {
	@include transition(all 0.4s);
	overflow: hidden;
}

.video-js .vjs-menu-button-inline:before {
	// Icon pseudoelement has a different base font size (1.8em), so we need to
	// account for that in the width. 4em (standard button width) divided by 1.8
	// to get the same button width as normal.
	width: 2.222222222em;
}

// Hover state
.video-js .vjs-menu-button-inline:hover,
.video-js .vjs-menu-button-inline:focus,
.video-js .vjs-menu-button-inline.vjs-slider-active,
.video-js.vjs-no-flex .vjs-menu-button-inline {
	// This width is currently specific to the inline volume bar.
	width: 12em;
}

.vjs-menu-button-inline .vjs-menu {
	opacity: 0;
	height: 100%;
	width: auto;

	position: absolute;
	left: 4em;
	top: 0;

	padding: 0;
	margin: 0;

	@include transition(all 0.4s);
}

.vjs-menu-button-inline:hover .vjs-menu,
.vjs-menu-button-inline:focus .vjs-menu,
.vjs-menu-button-inline.vjs-slider-active .vjs-menu {
	display: block;
	opacity: 1;
}

.vjs-no-flex .vjs-menu-button-inline .vjs-menu {
	display: block;
	opacity: 1;
	position: relative;
	width: auto;
}

.vjs-no-flex .vjs-menu-button-inline:hover .vjs-menu,
.vjs-no-flex .vjs-menu-button-inline:focus .vjs-menu,
.vjs-no-flex .vjs-menu-button-inline.vjs-slider-active .vjs-menu {
	width: auto;
}

.vjs-menu-button-inline .vjs-menu-content {
	width: auto;
	height: 100%;
	margin: 0;
	overflow: hidden;
}
