@import 'mixins/poly_fluid_sizing';
@import 'mixins/ui';

.disableSelection {
	user-select: none;
	-webkit-user-drag: none;
	-webkit-touch-callout: none;
}

@mixin arabic() {
	@at-root html[lang='ar'] #{&} {
		@content;
	}
}

@mixin breakWord() {
	/* These are technically the same, but use both */
	overflow-wrap: break-word;
	word-wrap: break-word;

	-ms-word-break: break-all;
	/* This is the dangerous one in WebKit, as it breaks things wherever */
	word-break: break-all;
	/* Instead use this non-standard one: */
	word-break: break-word;

	/* Adds a hyphen where the word breaks, if supported (No Blink) */
	-ms-hyphens: auto;
	-moz-hyphens: auto;
	-webkit-hyphens: auto;
	hyphens: auto;
}
