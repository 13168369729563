.DonationFrontpageTemplate {
  .bottom-contact-info {
    .content {
      background-color: red;
      display: flex;
      width: 100%;

      .left {
        padding-right: $gutter-size;
      }
    }
  }
  .ImageLinkGridModule {
    @media(min-width: $media-breakpoint-xl) {
      margin: 150px 0 128px;
    }
  }
}