.PressKitMediaDownloadListModule {
	.PressKitMediaDownloadListImageItem {
		a {
			display: block;
			.aspect-content {
				transition: none;
				transform: none;
				will-change: unset;
			}
			.aspect-content img {
				transition: transform 1s, opacity 0.3s;
				transform: scale3d(1, 1, 1);
				will-change: transform;
			}

			&:hover {
				.aspect-content {
					transform: none;
				}
				.aspect-content img {
					transform: scale3d(1.05, 1.05, 1.05);
				}
			}
			.img-wrapper .aspect-content {
				&:before {
					content: '';
					position: absolute;
					z-index: 1;
					bottom: 10px;
					right: 10px;
					width: 32px;
					height: 32px;

					background-color: #ffb500;
					border-radius: 50%;

					display: flex;
					align-items: center;
					justify-content: space-around;
					/*

                    svg {
                        width: 12px;
                        height: 17px;
                        @media (min-width: $media-breakpoint-lg) {
                            width: 17px;
                            height: 24px;
                        }
                    }
                    */

					@media (min-width: $media-breakpoint-lg) {
						bottom: 11px;
						right: 11px;
						width: 44px;
						height: 44px;
					}
				}
				&:after {
					content: '';
					position: absolute;
					z-index: 1;
					bottom: 10px;
					right: 10px;
					width: 32px;
					height: 32px;
					background: url('/assets/svg/embedded/download_white.svg') no-repeat center center;
					background-size: 12px 17px;
					@media (min-width: $media-breakpoint-lg) {
						background-size: 17px 24px;
						bottom: 11px;
						right: 11px;
						width: 44px;
						height: 44px;
					}
				}
			}
		}
	}
}
