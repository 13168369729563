.DonationThankYouTemplate {
	.text-style-subheader {
		a {
			color: $color-white;
			text-decoration: underline;
		}
	}
	.ImageTextBlocksModule {
		margin-bottom: 100px;
		@media (max-width: $media-maxwidth-breakpoint-lg) {
			margin-bottom: 0;
		}
		.blocks {
	    display: flex;
	    justify-content: space-between;
	    flex-wrap: wrap;
	    img {
	    	width: 100%;
	    }
		}
		.ImageTextBlock {
	    width: 49%;
	    position: relative;
	    &:nth-child(2) {
	    	.btn-pill {
	    		color: #ffb500;
	    	}
	    }
	    @media (max-width: $media-maxwidth-breakpoint) {
	    	width: 100%;
	    	margin-bottom: 30px;
	    }
		}
		.content {
	    position: absolute;
	    left: 6%;
	    top: 22%;
	    h3 {
	    	color: $color-white;
	    	@media (max-width: $media-maxwidth-breakpoint-lg) {
	    		font-size: 28px;
	    		line-height: 35px;
	    	}
	    }
		}
		.btn-pill {
			margin-top: 25px;
			color: $color-teal;
			@media (max-width: $media-maxwidth-breakpoint-lg) {
    		margin-top: 10px;
    	}
		}
	}
	.btn-round {
		&.scroll {
			display: none;
		}
	}
}