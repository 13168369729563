.TabbedStoriesModule {
	overflow-x: hidden;

	.tabs {
		flex-wrap: nowrap;
		display: inline-flex;
		position: relative;
		z-index: 0;

		@media (min-width: $burger-menu-breakpoint) {
			width: 100%;
		}

		.section {
			width: getColWidth(5, 12);
			flex-shrink: 0;
			position: relative;

			@media (min-width: $burger-menu-breakpoint) {
				width: 100%;
				flex-shrink: 1;
			}

			margin-right: $gutter-size;
			padding-bottom: 10px;
			border-bottom: 1px solid $color-light-grey;
			color: $color-darker-grey;
			display: flex;
			align-content: flex-end;
			align-items: flex-end;
			transition: 0.2s color;

			.line-selected {
				width: 0%;
				position: absolute;
				left: 0;
				height: 2px;
				bottom: -1px;
				display: block;
				@include color('background-color', 'background');

				transition: 0.3s width;
			}

			&.active,
			&:hover {
				color: $color-brand;

				.line-selected {
					width: 100%;
				}
			}

			&:hover {
				cursor: pointer;
			}

			&:last-child {
				margin-right: 0px;
			}
		}
	}

	.stories {
		z-index: 0;
		overflow: hidden;

		.story-container {
			position: relative;
			float: left; //Avoid collapsing margins
			width: 100%; //Avoid collapsing margins
			&:not(:first-child) {
				display: none;
				opacity: 0;
			}

			& > section {
				margin-top: 30px;

				@media (min-width: $burger-menu-breakpoint) {
					margin-top: 50px;
				}
			}

			.StoryModule {
				top: 0px;
				margin-top: 30px;

				@media (min-width: $burger-menu-breakpoint) {
					margin-top: 50px;
				}
			}
		}
	}
}
