@mixin basicInputStyling() {
	border: solid 1px rgba(255, 255, 255, 0.2);
	border-radius: 2px;

	// transition: 0.2s border;
}

@mixin basicHoverInputStyling() {
	border-color: $color-white;
}

@mixin basicCheckedInputStyling() {
	background-color: $color-white;
	border-color: $color-white;
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
	-webkit-appearance: none;
}
form {
	input:not([type='submit']):not([type='text']):not([type='email']):not([type='password']):not([type='search']) {
		width: 0;
		height: 0;
		position: absolute;
		left: -9999px;
		@include arabic() {
			left: auto;
			right: -9999px;
		}
	}

	input {
		-webkit-appearance: none;

		& + label {
			cursor: pointer;
		}
	}

	input[type='radio'] {
		& + label {
			@include basicInputStyling();

			padding: 8px;
			position: relative;
			margin-top: $gutter-size;
			display: inline-block;
			text-align: center;
			height: 40px;
			user-select: none;
		}

		&:hover + label {
			@include basicHoverInputStyling();
		}

		&:checked + label {
			@include basicCheckedInputStyling();
			color: map-get($color-green-map, 'links');

			z-index: 1;
		}
	}

	$checkbox-size: 16px;

	.text-input {
		display: flex;

		label {
			border-left: 1px solid rgba(255, 255, 255, 0.2);
			border-top: 1px solid rgba(255, 255, 255, 0.2);
			border-bottom: 1px solid rgba(255, 255, 255, 0.2);

			padding: 8px 15px;

			border-radius: 2px 0px 0px 2px;

			@include arabic() {
				border-right: 1px solid rgba(255, 255, 255, 0.2);
				border-left: none;
				border-top: 1px solid rgba(255, 255, 255, 0.2);
				border-bottom: 1px solid rgba(255, 255, 255, 0.2);
				border-radius: 0px 2px 2px 0px;
			}
			display: block;
		}

		input[type='text'] {
			border-radius: 0px 2px 2px 0px;
			outline: none;
			@include arabic() {
				border-radius: 2px 0px 0px 2px;
			}
			&:focus {
				&::placeholder {
					opacity: 0.3;
				}
			}
		}
	}

	input[type='text'] {
		@include basicInputStyling();
		height: 40px;
		background-color: transparent;
	}

	input[type='checkbox'] {
		& + label {
			position: relative;
			display: block;
			padding: 6px 9px 6px (21px + 10);
			cursor: pointer;
			@include arabic() {
				padding: 6px (21px + 10) 6px 9px;
			}

			// Checkbox setup
			&:before {
				@include basicInputStyling();

				display: flex;
				justify-content: center;
				content: '';
				position: absolute;
				left: 0;
				top: 50%;
				text-align: center;

				margin: ((-$checkbox-size/2) - 1px) 0 0;
				width: $checkbox-size;
				height: $checkbox-size;
				@include arabic() {
					left: auto;
					right: 0;
				}
			}

			.checkbox-dot {
				width: 8px;
				height: 8px;
				position: absolute;
				display: block;
				left: 4px;
				top: 4px;
				@include arabic() {
					left: auto;
					right: 4px;
				}
				svg {
					transform: scale(0);
					transition: 0.1s transform;
					transform-origin: center center;
				}
			}
		}

		&:hover + label {
			&:before {
				@include basicHoverInputStyling();
			}
		}

		&:checked + label {
			.checkbox-dot {
				svg {
					transform: scale(1);
				}
			}
		}
	}
}
