.PressReleaseTemplate {
    .TextModule {
        .article>.header {
            color: $color-dark-grey;
            margin-bottom: 0px;
        }
    }

    .press-release-info {
        margin-top: 10px;
        position: relative;

        @media (min-width: $text-inset-breakpoint) {
            margin-bottom: getMarginValue(-0.2);        

            padding-left: getColWidth(1, 12, 1);
            padding-right: getColWidth(1, 12, 1);
        }

        @media (min-width: $burger-menu-breakpoint) {
            margin-bottom: getMarginValue(-0.75);        

            padding-left: getColWidth(2, 12, 1);
            padding-right: getColWidth(2, 12, 1);
        }

        color: rgba($color: $color-dark-grey, $alpha: 0.8);
    }
}