.NewsletterSignupModule {
	position: relative;
	justify-content: center;

	align-items: center;

	.centering {
		display: flex;
		align-items: center;
		justify-content: space-around;
		width: 100%;
		max-width: $max-page-width;

		@include poly-margin-fluid-property('padding-left');
		@include poly-margin-fluid-property('padding-right');
	}

	.wrapper {
		position: relative;
		display: flex;
		flex-direction: column;
		width: 100%;

		@media (min-width: $text-inset-breakpoint) {
			width: getColWidth(10);
		}

		@media (min-width: $burger-menu-breakpoint) {
			width: getColWidth(8, $gutter: 10px);
		}
	}

	.title {
		@include color('color', 'background');

		br {
			display: none;

			@media (min-width: $media-breakpoint-md) {
				display: block;
			}
		}
	}

	.illustration-form {
		margin-top: 20px;

		display: flex;
		justify-content: space-between;
		flex-direction: column;

		@media (min-width: $media-breakpoint-md) {
			flex-direction: row;
			margin-top: 40px;
		}

		.input-area {
			margin-top: 25px;
			width: 100%;
			display: flex;
			flex-direction: column;

			@media (min-width: $media-breakpoint-md) {
				min-width: 420px;

				width: getColWidth(4, 8);
				margin-top: 0px;
				margin-right: 20px;
			}

			@media (min-width: $media-breakpoint-xl) {
				width: getColWidth(4, 8);
			}

			.source-12-regular {
				opacity: 0.8;

				max-width: 250px;
			}

			.input-text {
				width: 100%;
				border: 0px;
				border-bottom: 1px solid $color-light-grey;
				padding-bottom: 6px;
			}

			> div {
				@include poly-fluid-sizing(
					'margin-top',
					(
						576px: 20px,
						768px: 24px,
						1024px: 45px
					)
				);

				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				align-items: center;

				@media (min-width: $media-breakpoint-md) {
					flex-direction: row;
					justify-content: space-between;
				}

				.btn-pill {
					margin-top: 23px;
					width: 150px;

					padding-top: 0px;
					padding-bottom: 0px;

					&:focus {
						outline: none;
					}

					@media (min-width: $media-breakpoint-md) {
						margin-top: 0px;
					}
				}
				.response {
					width: 100%;
					display: flex;
					justify-content: flex-start;
				}
				.signup-btn {
					width: 100%;
					display: flex;
					justify-content: flex-end;
				}
			}
		}

		svg {
			align-self: center;
			// margin-bottom: 40px;
			// margin-top: 5px;

			width: 70%;

			order: -1;

			@media (min-width: $media-breakpoint-md) {
				order: 0;
				width: auto;
				align-self: flex-end;
			}

			path {
				@include color('fill', 'illustration');
			}
		}
	}
}
