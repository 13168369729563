.TextSegmentListModule {
	$spacing: 40px;
	margin-bottom: -$spacing;

	.list {
		width: 100%;
		display: flex;
		flex-wrap: wrap;

		margin-top: $gutter-size * 2;

		&:only-child {
			margin-top: 0px;
		}

		.list-item {
			width: 100%;

			@media (min-width: $media-breakpoint-sm) {
				margin-right: $gutter-size;
				width: getColWidth(6, 12);
				@include arabic() {
					margin-left: $gutter-size;
					margin-right: 0;
				}
			}

			padding-top: 24px;
			padding-bottom: $spacing;

			border-top: 1px solid $color-light-grey;

			h3 {
				color: $color-brand;
			}

			.body {
				display: flex;
				flex-wrap: wrap;
				min-height: 46px;

				margin-top: 12px;

				.column {
					width: 100%;
					margin-top: 24px;

					&:first-child {
						margin-top: 0px;
					}

					@media (min-width: $media-breakpoint-sm) {
						width: getColWidth(3, 6);
						margin-right: $gutter-size;
						margin-top: 0px;
						@include arabic() {
							margin-left: $gutter-size;
							margin-right: 0;
						}
						&:last-child {
							margin-right: 0px;
							@include arabic() {
								margin-left: 0;
							}
						}
					}

					&:only-child {
						width: 100%;
					}

					p {
						color: $color-darker-grey;
						margin-top: 24px;
						width: 100%;

						&:first-child {
							margin-top: 0px;
						}

						strong,
						b {
							font-weight: 600;
						}
					}

					.large {
						&:first-child {
							margin-top: -10px;
						}
					}
				}
			}

			&:nth-child(2n) {
				margin-right: 0px;
			}
		}

		a.list-item {
			h3 {
				transition: color 0.2s;
			}

			&:hover {
				h3 {
					@include color(color, links);
				}
			}
		}

		@media (min-width: $media-breakpoint-sm) {
			&.three {
				.list-item {
					width: getColWidth(4, 12);

					&:nth-child(2n) {
						margin-right: $gutter-size;
						@include arabic() {
							margin-left: $gutter-size;
							margin-right: 0;
						}
					}

					&:nth-child(3n) {
						margin-right: 0px;
						@include arabic() {
							margin-left: 0;
						}
					}
				}
			}

			&.four {
				.list-item {
					width: getColWidth(6, 12);

					@media (min-width: $media-breakpoint-lg) {
						width: getColWidth(3, 12);

						&:nth-child(2n) {
							margin-right: $gutter-size;
							@include arabic() {
								margin-left: $gutter-size;
								margin-right: 0;
							}
						}

						&:nth-child(4n) {
							margin-right: 0px;
							@include arabic() {
								margin-left: 0;
							}
						}
					}
				}
			}

			&.five {
				.list-item {
					width: getColWidth(6, 12);

					@media (min-width: $media-breakpoint-lg) {
						width: getColWidth(2, 12, 1);

						&:nth-child(n) {
							margin-right: $gutter-size;
							@include arabic() {
								margin-left: $gutter-size;
								margin-right: 0;
							}
						}

						&:nth-child(5n) {
							margin-right: 0px;
							@include arabic() {
								margin-left: 0;
							}
						}
					}
				}
			}
		}
	}
}
