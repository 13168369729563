.MajorGiftBreakerModule {
	padding-bottom: 70px;
	border-bottom: 1px solid $color-light-grey;

	.content {
		display: flex;
		width: 100%;
		flex-direction: column;

		@media (min-width: $text-inset-breakpoint) {
			flex-direction: row;
			padding-left: getColWidth(1, 12, 1);
			padding-right: getColWidth(1, 12, 1);
		}

		@media (min-width: $burger-menu-breakpoint) {
			padding-left: getColWidth(2, 12, 1);
			padding-right: getColWidth(2, 12, 1);
		}

		.left,
		.right {
			width: 100%;
		}

		.left {
			margin-right: $gutter-size;

			.title {
				color: $color-dark-grey;
			}
		}

		.right {
			margin-top: 30px;

			@media (min-width: $text-inset-breakpoint) {
				margin-top: 0px;
			}
		}

		.donation-amount {
			margin-bottom: 8px;
		}

		.DonateInputs {
			* {
				border-color: $color-light-grey;
				color: $color-dark-grey;
			}

			::placeholder {
				color: $color-dark-grey;
			}

			.donate-choices {
				justify-content: space-between;
				width: 100%;
				flex-wrap: nowrap;

				input[type='radio'] + label {
					margin-right: $gutter-size;
					align-self: stretch;
					width: 100%;
				}

				input[type='radio']:checked + label {
					background-color: #00ab4d;
					border-color: #00ab4d;
					color: #ffffff;
				}

				input[type='radio'] + label:last-child {
					margin-right: 0px;
				}
			}
		}

		.btn-donate {
			margin-top: 10px;
			width: auto;
		}
	}
}
