.PartnersModule {
	.content-container {
		display: flex;
		flex-wrap: wrap;
		width: 100%;

		.ImageGridItem {
			width: 100%;

			@media (min-width: $text-inset-breakpoint) {
				width: getColWidth(6, 12);
				margin-right: $gutter-size;

				&:nth-child(2n) {
					margin-right: 0px;
				}
			}

			a {
				height: 100%;
			}

			.img-wrapper {
				flex-shrink: 0;
			}

			.logo {
				position: absolute;
				bottom: $gutter-size;
				left: $gutter-size;
				width: 110px;
				height: 60px;
				background-color: $color-white;
				border-radius: 8px;
				display: flex;
				justify-content: space-around;
				align-items: center;
				padding: 5px 10px;

				img {
					max-width: 100%;
					height: auto;
					width: auto;
				}
			}

			.content {
				display: flex;
				flex-direction: column;
				height: 100%;

				/*				p:not(.mini-link) {
					flex-grow: 100;
				}*/
			}
		}
	}
}
