.donate-sub-menu {
	position: fixed;
	bottom: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	z-index: $menu-z-index-start + 5;
	background-color: $color-white;

	display: flex;
	flex-direction: column;

	transform: translateY(0vh);

	&.hide {
		display: none;
		transform: translateY(100vh);
	}

	.donate-header {
		width: 100%;
		border-bottom: 1px solid $color-light-grey;
		height: 60px;
		flex-shrink: 0;

		@media (min-width: $burger-menu-breakpoint) {
			display: none;
		}

		a {
			position: relative;
			left: 20px;
			top: 15px;
			@include arabic() {
				left: auto;
				right: 20px;
			}
		}
	}

	@media (min-width: $burger-menu-breakpoint) {
		transform: none;
		height: auto;
		width: auto;
		background-color: inherit;
		z-index: 0;
		position: absolute;
		text-align: right;
		bottom: auto;
		padding: inherit;
		left: auto;

		/*		transform: initial;
		height: initial;
		width: initial;
		background-color: initial;
		z-index: initial;
		position: absolute;
		text-align: right;
		bottom: initial;
		padding: inherit;
		left: initial;*/
	}

	ul {
		padding: 0px 23px;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
	}

	li {
		&:first-child {
			padding: 28px 0px 23px;

			@media (min-width: $burger-menu-breakpoint) {
				padding: 0;
			}
		}

		border-bottom: 1px solid $color-light-grey;
		padding: 17px 0px 23px;

		@media (min-width: $burger-menu-breakpoint) {
			border-bottom: none;
			padding: 0;
		}
	}

	.btn-close-donate {
		background-color: map-get($color-green-map, 'illustration');

		position: absolute;
		right: 13px;
		top: 9px;

		@include arabic() {
			left: 13px;
			right: auto;
		}

		svg * {
			fill: $color-white;
			stroke: $color-white;
		}

		@media (min-width: $burger-menu-breakpoint) {
			display: none;
		}
	}
}
