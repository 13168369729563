.FAQListItem {
	color: $color-dark-grey;
	.padding {
		.item-content {
			padding: 25px 0px 27px !important;

			@media (min-width: $burger-menu-breakpoint) {
				padding: 38px 0px 37px !important;
			}

			flex-direction: column;

			@media (min-width: $burger-menu-breakpoint) {
				flex-direction: row;
			}

			.answer,
			.question {
				flex-shrink: 0;
				flex-grow: 0;
				width: 100%;

				@media (min-width: $burger-menu-breakpoint) {
					width: getColWidth(6, 12);
				}
			}

			.answer {
				position: relative;

				@media (min-width: $burger-menu-breakpoint) {
					margin-top: -4px;
				}
			}

			.question {
				margin-right: $gutter-size;
				margin-top: 5px;

				@media (min-width: $burger-menu-breakpoint) {
					margin-top: 0px;
					flex-direction: row;
					margin-right: 0px;
				}
			}
		}
	}
}
