.subheader {
	color: $color-dark-grey;
	margin-bottom: 30px;

	@media (min-width: $text-inset-breakpoint) {
		padding-left: getColWidth(1, 12, 1);
		padding-right: getColWidth(1, 12, 1);
	}

	@media (min-width: $burger-menu-breakpoint) {
		padding-left: getColWidth(2, 12, 1);
		padding-right: getColWidth(2, 12, 1);
	}

	p {
		margin-top: 10px;
		color: $color-dark-grey;
	}

	.header {
		display: flex;
		width: 100%;
		flex-direction: column;
		align-items: flex-start;

		@media (min-width: $burger-menu-breakpoint) {
			flex-direction: row;
			justify-content: space-between;
			align-items: flex-end;
		}

		h2 {
			margin-right: $gutter-size;
			@include arabic() {
				margin-right: 0;
				margin-left: $gutter-size;
			}
		}

		.btn-pill {
			position: relative;
			margin-top: 10px;
			flex-shrink: 0;

			@media (min-width: $burger-menu-breakpoint) {
				margin-bottom: 14px;
			}

			&.svg-download {
				padding-left: 20px;
				padding-right: 40px;
			}

			svg {
				position: absolute;
				right: 13px;
				top: 9px;

				@media (min-width: $burger-menu-breakpoint) {
					top: 7px;
				}
			}
		}
	}
}

.img-wrapper {
	background-color: $color-grey-light-background;
}

/* fade image in after load */
.lazyload,
.lazyloading {
	opacity: 0;
	//transform: translate3d(0, 10%, 0);
}

.lazyloaded {
	opacity: 1;
	//transform: translate3d(0, 0%, 0);
	//transition: transform 100ms, opacity 200ms;
	transition: opacity 200ms;
	display: block;
}

.mini-link {
	color: inherit;
	opacity: 0.8;
	text-decoration: underline;

	&:link,
	&:visited {
		text-decoration: underline;
	}

	&:hover {
		opacity: 1;
		@include color('color', 'background');
	}

	&:active {
		text-decoration: underline;
	}
}

.cover-fit {
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		font-family: 'object-fit: cover';
	}
}

input[type='text'] {
	&::-ms-clear {
		display: none;
	}
}

a:not(.no-scale) {
	.aspect-content {
		transition: transform 1s;
		transform: scale3d(1, 1, 1);
		will-change: transform;
	}

	img {
		display: block;
	}

	.img-wrapper {
		overflow: hidden;
	}

	&:hover {
		.aspect-content {
			transform: scale3d(1.05, 1.05, 1.05);
		}
	}
}
