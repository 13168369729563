.DonateFormTemplatePage {
	.menu {
		.donate-mobile-top-link {
			opacity: 0;
			pointer-events: none;
		}
	}
}

.DonationFormTemplate {

	footer {
		margin-top: 0;
	}

	#rbEmbedded {

		min-height: 100% !important;
		@media (max-width: 1023px) {
			iframe {
				width: 100vw !important;
			}
		}
	}

	.DonationFormWrapper {
		width: 100%;
		@media (min-width: 1024px) {
			position: relative;
			flex-flow: row;
			display: flex;
			min-height: 100%;
		}

		.DonationFormWrapperInner {
			display: flex;
			flex-flow: column;
			min-height: 100%;
			width: 100%;
			@media (min-width: 1024px) {
				width: 50%;
			}

			.DonationIntroduction {
				padding-left: 20px;
				padding-right: 20px;
				font-family: 'VAG Rounded Std';
				@include localeFont('VAG Rounded Std');
				padding-top: 60px;
				background: #02B03F;
				color: #FFFFFF;
				letter-spacing: 0;
				font-size: 18px;
				line-height: 24px;
				@media (min-width: 384px) {
					padding-left: 50px;
					padding-right: 50px;
				}
				@media (min-width: 768px) {

				}
				@media (min-width: 768px) {
					font-size: 24px;
					line-height: 32px;

				}
				@media (min-width: 720px) and (max-width: 1023px) {

					padding-left: 120px;
					padding-right: 170px;
					padding-top: 60px;
				}
				@media (min-width: 1440px) {

					padding-left: 120px;
					padding-right: 170px;
					padding-top: 60px;
				}
			}
		}

		picture {
			display: none;
			@media (min-width: $burger-menu-breakpoint) {
				height: 100vh;
			}
			@media (min-width: 1024px) {
				pointer-events: none;
				position: sticky;
				left: 0;
				top: 0;
				display: block;
				background: $color-grey;
				width: 50%;
				img {
					width: 100%;
					object-fit: cover;
					font-family: "object-fit: cover";
					height: 100%;
				}
			}
		}
	}
}
