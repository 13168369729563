.NumberStatsModule {
	> div {
		display: flex;
		flex-direction: column;
		// position: relative;

		// gap: $gutter-size;
		// grid-template-columns: 1fr;

		@media (min-width: $media-breakpoint-md) {
			flex-direction: row;

			> div {
				margin-right: $gutter-size;
				@include arabic() {
					margin-right: 0;
					margin-left: $gutter-size;
				}
				margin-bottom: 0px;
			}

			> div:nth-child(3) {
				margin-right: 0px;
				@include arabic() {
					margin-left: 0px;
				}
			}
		}

		// grid-auto-rows: minmax(getColWidth(4, 12), auto);

		color: white;

		> div {
			position: relative;
			@include color('background-color', 'background');
			margin-bottom: $gutter-size;

			width: 100%;
			@include aspect-ratio(280, 240);

			min-height: 230px;
			max-height: 300px;

			@media (min-width: $media-breakpoint-md) {
				width: getColWidth(4, 12);
				@include aspect-ratio(216, 250);
				max-height: auto;
				margin-bottom: 0px;
			}

			@media (min-width: $media-breakpoint-lg) {
				@include aspect-ratio(288, 203);
			}

			@media (min-width: $media-breakpoint-xl) {
				@include aspect-ratio(426, 300);
			}

			.graphic {
				flex-grow: 0;
				position: absolute;
				top: 0px;
				right: 0px;
				@include arabic() {
					right: auto;
					left: 0;
					& svg {
						transform: scaleX(-1);
					}
				}

				transform-origin: top right;
				transform: scale(0.7);

				@media (min-width: $media-breakpoint-xl) {
					transform: scale(1);
				}

				opacity: 0.5;

				fill: white;
			}

			> .aspect-content {
				display: flex;
				flex-direction: column;
				justify-content: space-between;

				padding: 32px 25px 32px 25px;
			}
		}
	}
}
