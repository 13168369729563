.container {
	display: flex;
	flex-flow: column;
	align-items: center;
	align-self: center;

	& > :not(.w-100) {
		width: 100%;
		max-width: $max-page-width;
	}
}

.spacing {
	@include top-margin();
}
.spacing-padding {
	@include top-padding();
}

.half-spacing {
	@include top-margin(0.5);
}

.margin {
	@include poly-margin-fluid-property('margin-left');
	@include poly-margin-fluid-property('margin-right');
}

.padding {
	position: relative;

	@include poly-margin-fluid-property('padding-left');
	@include poly-margin-fluid-property('padding-right');
}

@function getColWidth($colCount, $maxColCount: 12, $addGutters: 0, $additionalPixels: 0, $gutter: $gutter-size) {
	$widthPlusCol: 100% + #{$gutter};

	$amountToRemove: -$gutter;

	@if $addGutters != 0 {
		$amountToRemove: $gutter * #{$addGutters - 1};
	}

	@return calc(((#{$widthPlusCol}) / #{$maxColCount}) * #{$colCount} + #{$amountToRemove});
}

@function getRandomDebugColor() {
	@return rgba(random(255), random(255), random(255), 0.5);
}

.default-content-inset {
	position: relative;

	@media (min-width: $text-inset-breakpoint) {
		margin-left: getColWidth(1, 12, 1);
		margin-right: getColWidth(1, 12, 1);
	}

	@media (min-width: $burger-menu-breakpoint) {
		margin-left: getColWidth(2, 12, 1);
		margin-right: getColWidth(2, 12, 1);
	}
}
