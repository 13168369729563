.FAQModule {
	.jobs-list {
		width: 100%;
	}
	.show-more {
		width: 100%;
		display: flex;

		margin: 20px 0px 0px;
		justify-content: space-around;
	}
}
