@mixin hollowCircle($color, $size, $opacity, $borderWidth: 1px) {
	//pointer-events: none;
	border: $borderWidth solid $color;
	border-radius: 50%;
	opacity: $opacity;
	width: $size - $borderWidth;
	height: $size - $borderWidth;
	background-color: transparent;
}

@mixin aspect-ratio($width, $height) {
	position: relative;

	&:before {
		display: block;
		content: '';
		width: 100%;
		padding-top: ($height / $width) * 100%;
	}

	> .aspect-content {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
}

// {{ set value = drupalImage(faefae) }}
// <div class="header-image">
//     <div class="before-aspect" style="padding-top: {{ (value.height/value.width)*100 }}%")></div>
//     <div class="aspect-content">
//         <img>
//     </div>
// </div>

// .header-image {
//      @include aspect-ratio(200px, 300px);
//      width: 50vw;
// }

// lazy-sizes til billeder, https://github.com/aFarkas/lazysizes, look into intersection observer
