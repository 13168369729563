.PresskitOverviewTemplate {
    h3 {
        color: $color-brand;
    }
    
    .show-details {
        .grid {
            margin-top: 10px;
            display:flex;
            width: 100%;
            flex-wrap: wrap;

            .ImageGridItem {
                width: 100%;

                @media(min-width: $media-breakpoint-sm) {
                    width: getColWidth(6,12);                
                    margin-right: $gutter-size;

                    &:nth-child(2n) {
                        margin-right: 0px;
                    }
                }

                @media(min-width: $text-inset-breakpoint) {
                    width: getColWidth(4,12);

                    &:nth-child(2n) {
                        margin-right: $gutter-size;
                    }

                    &:nth-child(3n) {
                        margin-right: 0px;
                    }
                }

                @media(min-width: $burger-menu-breakpoint) {
                    width: getColWidth(3,12);

                    &:nth-child(3n) {
                        margin-right: $gutter-size;
                    }

                    &:nth-child(4n) {
                        margin-right: 0px;
                    }
                }

                margin-bottom: 33px;

                h5 {
                    margin-top: 3px;
                }

                p {
                    margin-top: 0px;
                    color: map-get($color-orange-map, 'links');
                }

                .box-wrapper {
                  &.img-wrapper {
                    background-color: transparent;
                    margin-bottom: 0;
                    &:before {
                      content: none;
                    }
                  }
                }
            }
        }
    }
}