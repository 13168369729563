div[data-title='Our Leadership'] {
	section.HeroModule {
		&.w-100 .text-container {
			@extend .container;
			.text {
				@extend .padding;
			}
			.btn-round {
				max-width: 38px;
				@media (min-width: $media-breakpoint-md) {
					max-width: 40px;
				}
			}
		}
	}
	section.TextSegmentListModule {
		.list {
			.list-item {
				border-top: none;
				h3.rounded-20-light {
					@extend .rounded-24-light;
				}
				&.body-text {
					@extend .rounded-16-light;
				}
			}
		}
	}
	section.ImageLinkGridModule {
		.ImageGridItem {
			.content {
				.rounded-28-light {
					@extend .rounded-24-light;
				}
				.source-20-regular {
					@extend .rounded-16-light;
				}
			}
		}
	}
	.TextSegmentListModule {
		@media (min-width: $media-breakpoint-md) {
			margin-top: 127px;
		}
		~ .TextSegmentListModule {
			@media (min-width: $media-breakpoint-md) {
				margin: 140px 0 110px;
			}
		}
	}
}
