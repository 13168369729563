.AlertMessage {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	background: #d31888;
	color: $color-white;
	min-height: 40px;
	z-index: 1000000;
	display: flex;
	transform: translate3d(0, -100%, 0);

	.AlertMessage__message {
		a {
			color: $color-white;
			font-weight: 600;
			text-decoration: underline;
		}

		position: relative;
		display: flex;
		flex-flow: column;
		justify-content: center;
		width: 100%;
		max-width: 1680px;
		margin: 0 auto;
		font-family: 'Source Sans Pro';
		font-size: 16px;
		@include localeFont('Source Sans Pro');
		p {
			padding-right: 20px;
		}

		a.AlertMessage__close {
			cursor: pointer;
			position: absolute;
			top: 0px;
			right: 0px;
			line-height: 12px;
			padding: 14px;
		}
	}
}
