@import 'burgerButton';
@import 'donateSection';

.menu {
	top: 0px;
	width: 100%;
	display: flex;
	z-index: 2;

	@media (min-width: $burger-menu-breakpoint) {
		position: fixed;
	}

	&.expanded {
		position: fixed;
	}

	.blackout {
		background-color: rgba($color: #000000, $alpha: 0);
		position: fixed;

		top: 0px;
		bottom: 0px;
		left: 0px;
		right: 0px;

		width: 100%;
		height: 100%;
		z-index: 0;

		pointer-events: none;

		&.visible {
			background-color: rgba($color: #000000, $alpha: 0.3);
		}
	}

	.menu-wrapper {
		height: $menu-mobile-height;
		width: 100%;
		position: relative;
		background-color: $color-white;
		display: flex;
		align-items: center;
		flex-direction: column;
		z-index: 2;
		overflow-y: hidden;

		box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);

		&.shadow {
			box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);
		}

		&.hidden {
			@media (min-width: $burger-menu-breakpoint) {
				// top: -70px;
				height: 0 !important;
				transition: height .5s ease;
			}
		}

		@media (min-width: $burger-menu-breakpoint) {
			height: $menu-desktop-height;
		}

		.menu-content {
			width: 100%;
			height: 100%;
			position: relative;
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			@media (min-width: $burger-menu-breakpoint) {
				max-width: $max-page-width;
				padding: 0px $desktop-side-margin;

				justify-content: flex-start;
			}

			> .logo {
				position: absolute;
				top: 6px;
				z-index: $menu-z-index-start + 4;
				left: calc(50% - 70px);
			}

			&.fixed-header {
				height: calc(100vh - 70px); /* Fallback for browsers that do not support Custom Properties */
				height: calc(var(--vh, 1vh) * 100 - 60px);

				.main-menu {
					overflow-y: scroll;
					-webkit-overflow-scrolling: touch;
				}

				.main-menu::before {
					position: fixed;
				}

				.main-menu > .logo {
					position: fixed;
				}

				.search-icon {
					position: fixed;
				}

				.Search {
					position: fixed;
				}
			}

			&::before {
				content: '';
				background-color: $color-white;
				width: 100%;
				height: $menu-mobile-height;
				position: absolute;
				z-index: $menu-z-index-start + 1;
				top: 0px;
				border-bottom: 1px solid $color-light-grey;

				@media (min-width: $burger-menu-breakpoint) {
					display: none;
				}
			}

			.main-menu {
				padding-top: $menu-mobile-height;
				overflow-y: hidden;
				width: 100%;
				position: relative;

				@media (min-width: $burger-menu-breakpoint) {
					padding-top: 0px;

					> div:nth-child(3),
					> div:nth-child(2) {
						order: 1;
					}

					> div:nth-child(4),
					> div:nth-child(5) {
						order: 3;
					}

					align-content: center;
					display: flex;
					align-self: center;

					flex-direction: row;
					justify-content: space-between;
					width: 100%;

					top: auto;
					min-height: $menu-desktop-height;

					padding-left: getColWidth(2, 12, 1);
					padding-right: getColWidth(2, 12, 1);

					max-width: $max-page-width;
				}

				.logo {
					z-index: $menu-z-index-start + 1;
					position: absolute;
					top: 10px;
					left: calc(50% - 67px);

					@media (min-width: $burger-menu-breakpoint) {
						position: static;
						order: 2;
						margin-top: 12px;

						svg {
							margin-right: 22px;
						}
					}
				}

				> div {
					padding: 0px 3px;
				}

				.menu-section {
					border-bottom: 1px solid $color-light-grey;
					margin: 0px 20px;
					overflow-y: hidden;
					position: relative;
					flex-shrink: 0;

					@media (min-width: $burger-menu-breakpoint) {
						border-bottom: 0px;
						margin: 0px;

						> .sub-menu {
							display: none;
						}
					}

					.menu-item-wrapper {
						display: flex;
					}

					.top-link {
						padding-top: 25px;
						padding-bottom: 25px;
						display: block;
						flex-grow: 0;

						a {
							white-space: nowrap;
							transition: color 0.2s ease-in-out 0s;
						}

						@media (min-width: $burger-menu-breakpoint) {
							width: auto;
							padding-top: 19px;
							padding-bottom: 20px;
							position: relative;

							> a:before {
								content: '';
								position: absolute;
								width: 100%;
								height: 1px;
								bottom: 20px;
								left: 0;
								visibility: hidden;
								transform-origin: 0% 50%;
								transform: scaleX(0);
								transition: all 0.2s ease-in-out 0s;
							}

							> a:hover:before,
							> a.active:before,
							> a.hovered:before {
								visibility: visible;
								transform: scaleX(1);
							}
						}
					}

					> ul {
						@media (min-width: $burger-menu-breakpoint) {
							display: none;
						}
					}

					> li {
						padding-top: 25px;
						padding-bottom: 25px;

						font-size: 22px;
						font-weight: 600;
					}

					li:last-child {
						padding-bottom: 20px;
					}

					.mobile-expand-toggle {
						display: flex;
						align-content: center;
						align-items: center;
						justify-content: flex-end;
						flex-grow: 1;
						margin-top: 6px;

						cursor: pointer;

						@media (min-width: $burger-menu-breakpoint) {
							display: none;
						}
					}
				}

				// used to contract the menu items in the accordion on mobile
				.menu-section.contracted {
					height: 80px;

					@media (min-width: $burger-menu-breakpoint) {
						height: auto;
					}
				}
			}

			.donate-top-link {
				display: none;

				@media (min-width: $burger-menu-breakpoint) {
					position: absolute;
					display: block;

					right: $desktop-side-margin;
					@include arabic() {
						left: $desktop-side-margin;
						right: auto;
					}
					top: 16px;

					bottom: inherit;
				}
			}

			.desktop-submenu-containers {
				display: none;
				width: 100%;
				margin: 30px 0 0 0;

				padding-left: getColWidth(2, 12, 1);
				@include arabic() {
					padding-left: 0;
					padding-right: getColWidth(2, 12, 1);
				}

				@media (min-width: $burger-menu-breakpoint) {
					display: block;
				}

				div {
					width: 100%;
				}
			}

			.search-icon {
				position: absolute;
				cursor: pointer;
				top: 0;
				left: 0;
				z-index: $menu-z-index-start + 6;
				width: 60px;
				display: flex;
				align-items: center;
				justify-content: center;
				background: none;
				border: none;
				appearance: none;
				// pointer-events: all;
				@include arabic() {
					left: auto;
					right: 0;
				}
				height: $menu-mobile-height;
				@media (min-width: $burger-menu-breakpoint) {
					height: $menu-desktop-height;
				}
				@media (min-width: $media-breakpoint-md) {
					padding-left: 20px;
					@include arabic() {
						padding-left: 0;
						padding-right: 20px;
					}
				}
				@media (min-width: $media-breakpoint-lg) {
					margin-left: 28px;
					@include arabic() {
						margin-left: 0;
						margin-right: 28px;
					}
				}
			}

			.desktop-submenu-container {
				position: relative;
				display: flex;

				> div.sub-menu {
					display: flex;
					flex-direction: row;

					ul {
						width: getColWidth(3, 10, 1);
					}

					.sub-menu-secondary {
						margin-top: -2px;
					}
				}

				a[href='/']:not(.language) {
					opacity: 0.3 !important;
					pointer-events: none;
					cursor: default;
				}
				#MenuSubMenu_what-we-do {
					.sub-menu-secondary {
						&:nth-child(2) {
							width: 70%;
							.list-wrapper {
								column-count: 2;
								column-gap: 30px;
							}
						}
					}
				}
			}

			.desktop-submenu-container > .sub-menu,
			.desktop-donate-submenu-container > .sub-menu {
				&.hide {
					display: none;
				}

				position: relative;
				padding-bottom: 60px;
			}

			.desktop-donate-submenu-container {
				right: 0px;

				ul {
					padding: 0px;
				}

				a[href='/'] {
					opacity: 0.3 !important;
					pointer-events: none;
					cursor: default;
				}
			}
		}
	}

	a.language {
		color: $color-brand !important;

		&:hover {
			color: #00953b !important;
		}

		&[href='/ar'] {
			font-family: 'Geeza Pro';

			@media (min-width: $burger-menu-breakpoint) {
				max-height: 26px !important;
				display: block;
			}
		}

		&[href='/ja'] {
			font-family: 'PingFang SC';

			@media (min-width: $burger-menu-breakpoint) {
				max-height: 26px !important;
				display: block;
			}
		}

		&[href='/zh-hans'] {
			font-family: 'PingFang SC';

			@media (min-width: $burger-menu-breakpoint) {
				max-height: 26px !important;
				display: block;
			}
		}
	}

	.menu-section.language {
		@media (min-width: $burger-menu-breakpoint) {
			position: absolute !important;
			left: 40px;
			@include arabic() {
				left: auto;
				right: 40px;
			}
		}

		a {
			& svg {
				path {
					transition: 0.2s ease-in-out 0s;
				}
			}

			&:hover {
				color: #00953b !important;

				& svg {
					path {
						stroke: #00953b;
					}
				}
			}
		}
	}

	#MenuItem_language {
		font-size: 15px;
		display: flex;
		flex-flow: row;
		justify-content: center;
		margin-top: 5px;

		svg {
			margin-right: 10px;
			width: 19px;
			height: 19px;
			@include arabic() {
				margin-right: 0px;
				margin-left: 10px;
			}
		}
	}

	.contact-points {
		display: none;

		@media (min-width: $burger-menu-breakpoint) {
			position: absolute;
			display: flex;
			flex-direction: column;
			left: $desktop-side-margin;
			top: 105px;
			@include arabic() {
				left: auto;
				right: $desktop-side-margin;
			}

			a {
				color: $color-dark-grey;
				margin-top: 3px;
				opacity: 0.5;

				&:link,
				&:visited {
					text-decoration: none;
				}

				&:hover {
					text-decoration: underline;
					opacity: 0.7;
				}

				&:active {
					text-decoration: underline;
				}
			}
		}
	}

	.btn-round {
		width: 40px;
		height: 40px;
	}

	@mixin menuItemColor($class, $colorMap) {
		#{$class},
		#{$class}:visited {
			color: $color-brand;
		}

		#{$class}:hover,
		#{$class}:active,
		#{$class}.active,
		#{$class}.hovered {
			color: map-get($colorMap, 'background');
		}

		#{$class}:before,
		> #{$class}.hovered:before {
			background-color: map-get($colorMap, 'background');
		}
	}

	@include menuItemColor('.who-we-are', $color-teal-map);
	@include menuItemColor('.where-we-work', $color-blue-map);
	@include menuItemColor('.what-we-do', $color-purple-map);
	@include menuItemColor('.press-room', $color-orange-map);
	@include menuItemColor('.donate', $color-green-map);

	.adelle-12 {
		margin-top: 10px;
		margin-bottom: 18px;
	}

	.text-style-nav-link {
		margin-bottom: 10px;
	}

	ul {
		list-style-type: none;
	}

	.donate-mobile-top-link {
		position: fixed;
		right: 10px;
		bottom: 15px;

		z-index: $menu-z-index-start + 4;
		transition: opacity 0.2s;

		@include arabic() {
			right: auto;
			left: 10px;
		}

		@media (min-width: $burger-menu-breakpoint) {
			display: none;
		}
	}

	.mobile-contact-points {
		position: fixed;
		bottom: 0px;
		width: 100%;
		background-color: $color-white;

		transform: translate3d(0, 100px, 0);
		transition: 0.3s transform;

		z-index: $menu-z-index-start + 3;
		box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.06);

		display: flex;
		flex-direction: column;

		padding: 15px 20px;

		&.visible {
			transform: translate3d(0, 0px, 0);
		}

		a {
			margin-top: 3px;
			opacity: 0.5;
			color: inherit;
		}

		@media (min-width: $burger-menu-breakpoint) {
			display: none;
		}
	}
}
