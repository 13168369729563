.BigDonateModule {
	.padding {
		.content {
			// background-color: $color-grey-light-background;
			// padding: getColWidth(1, 12, 1);
			// color: $color-dark-grey;

			> div {
				border-bottom: 1px solid $color-light-grey;
				padding: $gutter-size * 2 0px;

				@media (min-width: $media-breakpoint-lg) {
					padding: $gutter-size * 2 getColWidth(1, 10, 1);
				}

				&.feathers {
					position: absolute;
					pointer-events: none;

					@include poly-margin-fluid-property('left');
					@include poly-margin-fluid-property('right');
					top: 0px;
					overflow: hidden;
					bottom: 0px;

					user-select: none;

					.left-feather,
					.right-feather {
						position: absolute;
						display: none;

						@media (min-width: $media-breakpoint-lg) {
							display: block;
						}
					}

					.left-feather {
						@keyframes left-animation {
							0%,
							100% {
								transform: translateY(5px) translateX(20px) rotate(117deg);
							}
							50% {
								transform: translateY(0px) translateX(0px) rotate(123deg);
							}
						}

						animation: left-animation 13s infinite;
						animation-timing-function: ease-in-out;

						top: -10px;

						@media (min-width: $media-breakpoint-lg) {
							left: -180px;
						}

						@media (min-width: $media-breakpoint-xl) {
							left: -130px;
						}
					}

					.right-feather {
						@keyframes right-animation {
							0%,
							100% {
								transform: translateY(5px) translateX(20px) rotate(27deg) scale(0.5);
							}
							50% {
								transform: translateY(0px) translateX(0px) rotate(43deg) scale(0.5);
							}
						}

						animation: right-animation 10s infinite;
						animation-timing-function: ease-in-out;

						right: -120px;
						top: -20px;

						// transform: rotate(40deg) scale(0.5);
					}

					border: 0px;
				}

				&.introduction {
					position: relative;

					padding-top: 0px;
					padding-bottom: getColWidth(1, 10, 1);

					svg {
						max-width: 100%;
					}

					display: flex;

					flex-direction: column;
					align-items: center;

					p {
						margin-top: 50px;
					}
				}

				&.give-section {
					display: flex;
					justify-content: space-between;
					//align-items:stretch;
					// align-content: stretch;

					flex-direction: column;

					@media (min-width: $media-breakpoint-lg) {
						flex-direction: row;
					}

					> div {
						@media (min-width: $media-breakpoint-md) {
							width: getColWidth(6, 10);
						}

						@media (min-width: $media-breakpoint-lg) {
							width: getColWidth(3, 8);
							margin-left: 50px;
						}
					}

					.left-single-donation {
						background-color: $color-grey-light-background;
						//padding: getColWidth(1, 12, 1);
						padding: 40px;
						color: $color-dark-grey;
						//padding-right: $gutter-size;

						@media (min-width: $media-breakpoint-md) {
							margin-right: $gutter-size;
						}
						.donation-top {
							h4 {
								@include color('color', 'text');
							}

							p {
								@media (min-width: $media-breakpoint-lg) {
									width: 85%;
								}
							}
						}
						.donation-bottom {
							form {
								width: 100%;

								margin-top: $gutter-size;

								@media (min-width: $media-breakpoint-lg) {
									margin-top: 0px;
								}

								.donation-amount {
									margin-bottom: 8px;
								}

								.text-input {
									label {
										border-color: #d8d8d8;
									}
									margin-top: $gutter-size;
									width: 80%;
								}

								input {
									width: 100%;
									border-color: #d8d8d8;
								}

								input[type='text'] {
									text-align: center;
									color: $color-dark-grey;
								}

								.donate-choices {
									input[type='radio'] {
										&:checked + label {
											background-color: map-get($color-green-map, 'links');
											border-color: map-get($color-green-map, 'links');

											color: $color-white;
										}
									}

									input + label {
										// width: getColWidth(1, 3, 2);
										width: getColWidth(2, 5);

										@media (min-width: $media-breakpoint-lg) {
											width: getColWidth(2, 5);
										}

										margin-right: $gutter-size;

										border-color: #d8d8d8;
										color: $color-dark-grey;
									}

									input + label:nth-child(4n) {
										margin-right: 0px;
									}

									@media (min-width: $media-breakpoint-md) {
										input + label:nth-child(4n) {
											margin-right: 0px; //$gutter-size;
										}

										input + label:nth-child(8n) {
											margin-right: 0px;
										}
									}

									input + label:nth-child(8n) {
										margin-right: 20px;
									}
								}

								.btn-donate {
									@media (min-width: $media-breakpoint-md) {
										margin-top: $gutter-size;
									}
									padding: 0px 30px;
									width: auto;
									border-color: map-get($color-green-map, 'links');
									@media (min-width: $media-breakpoint-lg) {
										padding: 0px 20px;
									}
								}
							}

							.monthly-price {
								margin-top: $gutter-size;
								border: 1px solid $color-light-grey;
								border-radius: 2px;
								padding: $gutter-size;

								display: none;

								&.active-price {
									display: flex;
								}

								flex-direction: column;

								@media (min-width: $media-breakpoint-lg) {
									flex-direction: row;
								}

								justify-content: space-between;

								.left {
									display: flex;
									align-items: center;
									justify-content: space-around;
									margin-bottom: 20px;
									padding-right: 0px;

									@media (min-width: $media-breakpoint-lg) {
										flex-direction: row;
										margin-bottom: 0px;
									}

									img {
										width: 100%;

										max-width: 150px;
									}
								}

								div {
									width: 100%;

									@media (min-width: $media-breakpoint-lg) {
										width: getColWidth(2, 4);
									}
								}

								.right {
									p {
										margin-top: $gutter-size * 0.5;
									}
								}
							}
						}
					}

					.right-monthly-donation {
						background-color: $color-grey-light-background;
						//padding: getColWidth(1, 12, 1);
						padding: 40px;
						color: $color-dark-grey;

						@media (min-width: $media-breakpoint-md) {
							width: getColWidth(6, 10);
						}

						@media (min-width: $media-breakpoint-lg) {
							width: getColWidth(4, 8);
						}
						.donation-top {
							h4 {
								@include color('color', 'text');
							}

							svg {
								max-width: 100%;
							}

							p {
								@media (min-width: $media-breakpoint-lg) {
									width: 90%;
								}
							}
						}
						.donation-bottom {
							form {
								width: 100%;

								margin-top: $gutter-size;

								@media (min-width: $media-breakpoint-md) {
									margin-top: 0px;
								}

								.donation-amount {
									margin-bottom: 8px;
								}

								.text-input {
									label {
										border-color: #d8d8d8;
									}
								}

								input {
									width: 100%;
									border-color: #d8d8d8;
								}

								input[type='text'] {
									text-align: center;
									color: $color-dark-grey;
								}

								.donate-choices {
									input[type='radio'] {
										&:checked + label {
											background-color: map-get($color-green-map, 'links');
											border-color: map-get($color-green-map, 'links');

											color: $color-white;
										}
									}

									input + label {
										width: getColWidth(2, 4);

										@media (min-width: $media-breakpoint-lg) {
											width: getColWidth(1, 4);
										}

										margin-right: $gutter-size;

										border-color: #d8d8d8;
										color: $color-dark-grey;
									}

									input + label:nth-child(4n) {
										margin-right: 0px;
									}

									@media (min-width: $media-breakpoint-lg) {
										input + label:nth-child(4n) {
											margin-right: $gutter-size;
										}

										input + label:nth-child(8n) {
											margin-right: 0px;
										}
									}

									input + label:nth-child(8n) {
										margin-right: 0px;
									}
								}

								.btn-donate {
									margin-top: $gutter-size;
									padding: 0px 30px;
									width: auto;
									border-color: map-get($color-green-map, 'links');
								}
							}

							.monthly-price {
								margin-top: $gutter-size;
								border: 1px solid $color-light-grey;
								border-radius: 2px;
								padding: $gutter-size;

								display: none;

								&.active-price {
									display: flex;
								}

								flex-direction: column;

								@media (min-width: $media-breakpoint-lg) {
									flex-direction: row;
								}

								justify-content: space-between;

								.left {
									display: flex;
									align-items: center;
									justify-content: space-around;
									margin-bottom: 20px;
									padding-right: 0px;

									@media (min-width: $media-breakpoint-lg) {
										flex-direction: row;
										margin-bottom: 0px;
									}

									img {
										width: 100%;

										max-width: 150px;
									}
								}

								div {
									width: 100%;

									@media (min-width: $media-breakpoint-lg) {
										width: getColWidth(2, 4);
									}
								}

								.right {
									p {
										margin-top: $gutter-size * 0.5;
									}
								}
							}
						}
					}
					&:last-child {
						border-bottom: none;
					}
				}
			}
		}
	}
}
