.btn-burger {
	position: fixed;

	right: 13px;
	top: 9px;

	box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0);
	z-index: $menu-z-index-start + 2;

	@include arabic() {
		right: auto;
		left: 13px;
	}
	> * {
		pointer-events: none;
	}
	.burger {
		margin-top: -3px;
	}

	> div {
		display: flex;
		vertical-align: middle;
	}

	.close-icon {
		display: none;
	}

	@media (min-width: $burger-menu-breakpoint) {
		display: none;
	}

	&.green-button {
		svg * {
			fill: $color-white;
			stroke: $color-white;
		}
	}

	&.close {
		.close-icon {
			display: block;
		}

		.burger-icon {
			display: none;
		}
	}

	&.scrolled {
		box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
	}
}
html.Search__visible {
	.btn-burger {
		pointer-events: none;
		opacity: 0;
		visibility: hidden !important;
	}
}
