a {
	border: 0px;
	cursor: pointer;
}

.btn-round {
	width: 38px;
	height: 38px;
	border-radius: 38px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: $color-white;
	cursor: pointer;
	position: relative;

	transition: 0.2s transform;
	will-change: transform;

	svg {
		will-change: transform;
		transition: 0.2s transform;
		height: 17px;
		width: 20px;
	}

	&.disabled {
		background-color: $color-light-grey;

		cursor: initial;
	}

	&:hover:not(.disabled) {
		transform: scale(1.1);

		svg {
			transform: scale(0.8);
		}
	}

	@media (min-width: $media-breakpoint-md) {
		width: 40px;
		height: 40px;
		border-radius: 40px;
	}
}

input.btn-pill {
	padding-bottom: 7px;
}

button.btn-pill {
	padding: 0px 30px;
}

.btn-pill {
	text-align: center;

	cursor: pointer;

	display: inline-block;
	min-height: 40px;

	@media (min-width: $media-breakpoint-md) {
		min-height: 36px;
		padding: 6px 30px 0px;
	}

	border-radius: 40px;
	padding: 8px 30px 0px;

	font-weight: 600;

	border: 2px solid;

	&.white {
		background-color: $color-white;
		border-color: $color-white;

		&:hover {
			background-color: transparent;
			color: $color-white !important;
		}
	}

	&.filled {
		@include color('background-color', 'links');
		@include color('border-color', 'links');

		color: $color-white;

		svg {
			* {
				stroke: $color-white;
			}
		}

		&:hover {
			@include color('background-color', 'hover');
			@include color('border-color', 'hover');
		}

		&.disabled {
			background-color: $color-light-grey;
			border-color: $color-light-grey;

			cursor: initial;

			&:hover {
				background-color: $color-light-grey;
				border-color: $color-light-grey;
			}
		}
	}
}

.btn-donate {
	background-color: map-get($color-green-map, 'links');
	color: $color-white;
	border: 2px solid map-get($color-green-map, 'links');
}

.btn-donate:hover {
	background-color: inherit;
	color: map-get($color-green-map, 'links');
	border: 2px solid map-get($color-green-map, 'links');
}

.btn-nav {
	color: $color-brand;
}
