.SupportersModule {
	.supporter-container {
		&:first-child {
			.subheader {
				padding-top: 0px;
			}
		}

		.subheader {
			padding-top: 40px;
		}
	}

	.padding {
		h4 {
			@media (min-width: $text-inset-breakpoint) {
				padding-left: getColWidth(1, 12, 1);
				padding-right: getColWidth(1, 12, 1);
			}

			@media (min-width: $media-breakpoint-lg) {
				padding-left: getColWidth(2, 12, 1);
				padding-right: getColWidth(2, 12, 1);
			}

			color: $color-dark-grey;
		}

		.supporters.one > a,
		.supporters.one > div {
			width: 100%;
		}

		.supporters.two > a,
		.supporters.two > div {
			width: 100%;

			@media (min-width: $media-breakpoint-sm) {
				width: getColWidth(3, 6);
				height: 190px;
			}

			height: 190px;
		}

		// .supporters.two > a:not(:first-child), .supporters.two > div:not(:first-child) {
		// 	@include top-margin(0.65);
		// }

		> div {
			width: 100%;

			.subheader + .supporters {
				margin-top: -40px;
			}

			.supporters {
				display: flex;
				width: 100%;

				@media (min-width: $text-inset-breakpoint) {
					margin-left: getColWidth(1, 12, 1);
					width: getColWidth(8, 12);
				}

				@media (min-width: $media-breakpoint-lg) {
					margin-left: getColWidth(2, 12, 1);
					width: getColWidth(6, 12);
				}

				flex-wrap: wrap;
				justify-content: space-between;

				a,
				div {
					display: flex;
					height: 190px;
					border: 1px solid $color-light-grey;
					margin-top: $gutter-size;
					padding: $gutter-size * 2;

					text-align: center;
					justify-content: space-around;
					align-items: center;

					color: $color-dark-grey;

					img,
					svg {
						//min-width: 170px;
						max-height: 100%;
						max-width: 100%;
						//width: 100%;
						height: auto;
					}
				}

				a:hover,
				a:active,
				a.active {
					@include color('color', 'text');
					border: 1px solid rgba($color-brand, 0.5);
				}
			}
		}
	}
}
