.DonateModule {
	$wide-text-only-step: $media-breakpoint-md;
	$image-step: $media-breakpoint-lg;

	+ footer {
		margin-top: 0px !important;
	}

	background-color: map-get($color-green-map, 'background');
	color: white;

	display: flex;
	align-items: stretch;

	position: relative;

	.text-style-h2-large {
		margin-bottom: 20px;
	}

	.text-input {
		width: 100%;
		// position: relative;

		@media (min-width: $wide-text-only-step) {
			width: getColWidth(4, 5);
		}
		@media (min-width: $media-breakpoint-xl) {
			width: getColWidth(4, 5, $gutter: 10px);
		}
		.input-btn-text {
			color: $color-white;

			&::placeholder {
				color: $color-white;
			}
		}
		// .postInput {
		// 	position: absolute;
		// 	right: 10px;
		// 	top: 8px;
		// }
	}

	.image-container,
	.image-placeholder {
		display: none;
		position: relative;
		overflow: hidden;

		background-color: map-get($color-green-map, 'illustration');

		@media (min-width: $image-step) {
			width: 50%;
			height: 100%;
			display: block;
			flex: 0 0 auto;
		}
	}

	.image-container {
		position: absolute;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			font-family: 'object-fit: cover';
		}
	}

	.content-background {
		position: relative;

		margin-top: 45px;
		margin-bottom: 40px;

		@include poly-margin-fluid-property('margin-left');
		@include poly-margin-fluid-property('margin-right');

		@media (min-width: $wide-text-only-step) {
			margin-top: 65px;
			margin-bottom: 57px;
		}

		@media (min-width: $image-step) {
			width: 50%;
			max-width: #{$max-page-width * 0.5};

			padding-left: #{$gutter-size * 0.5};
			@include poly-margin-fluid-property('padding-right');

			margin-left: 0px;
			margin-right: 0px;
		}

		@media (min-width: $media-breakpoint-xl) {
			margin-top: 95px;
			margin-bottom: 57px;
		}

		.content-container {
			position: relative;

			padding-top: 40px;
			padding-bottom: 40px;

			margin: 0px;
			padding: 0px;

			> p.body-text {
				@include font('SesameWkshp-Regular', 24px, 30px, normal);
				a {
					color: $color-white;
					position: relative;
					display: inline-block;
					&:after {
						content: '';
				    position: absolute;
				    background: #fff;
				    height: 2px;
				    width: 91px;
				    bottom: 0;
				    left: 3px;
					}
				}
			}

			@media (min-width: $wide-text-only-step) {
				padding-left: getColWidth(1, 12, 1);
				padding-right: getColWidth(1, 12, 1);

				> p.body-text {
					width: getColWidth(8, 10);
				}
			}

			@media (min-width: $image-step) {
				padding-left: calc(((100% + 20px) / 7) * 1 + 20px * 0);
				padding-right: 0px;
				@include arabic() {
					padding-right: getColWidth(1, 6, 1);
					padding-left: 0px;
				}
			}

			form {
				display: flex;
				flex-direction: column;
				align-items: flex-start;

				margin-top: 20px;

				@media (min-width: $wide-text-only-step) {
					margin-top: 50px;
				}

				@media (min-width: $media-breakpoint-lg) {
					margin-top: 25px;
				}
			}

			.donate-choices {
				margin-top: 10px;
				@media (min-width: $wide-text-only-step) {
					justify-content: flex-start;
				}

				input {
					width: 200px;
				}

				input + label {
					width: getColWidth(2, 4);

					@media (min-width: $wide-text-only-step) {
						width: getColWidth(2, 10);
						height: 42px;
						padding: 9px;

						margin-right: $gutter-size;
						@include arabic() {
							margin-left: $gutter-size;
							margin-right: 0;
						}
					}

					@media (min-width: $image-step) {
						width: getColWidth(2, 5);
						height: 56px;
						padding: 16px;
					}

					@media (min-width: $media-breakpoint-xl) {
						width: getColWidth(1, 5, $gutter: 10px);
						margin-right: $gutter-size/2;
						height: 42px;
						padding: 9px;
					}
				}
				@include font('Source Sans Pro', 16px, 19px, 600);
			}

			.donate-choices, .text-input {
				.error {
					max-width: 200px;
					bottom: -51px;
					left: 0;
					position: absolute;
					background-color: #b40000;
					color: $color-white;
					z-index: 1;
					padding: 8px 8px 5px;
					text-align: left;
					box-shadow: 1px 1px 5px 1px rgba(112,112,112,0.3);
					border-radius: 3px;
					font-size: 13px;
					font-family: 'SesameWkshp-Regular';
					&:before {
						position: absolute;
						content: "";
						border-left: 6px solid transparent;
						border-right: 6px solid transparent;
						border-bottom: 6px solid #b40000;
						top: -5px;
					}
				}
			}

			svg {
				polyline {
					stroke: $color-white;
				}
			}

			.btn-pill {
				background-color: map-get($color-green-map, 'white') !important;
				color: map-get($color-green-map, 'background');
				border-color: map-get($color-green-map, 'white') !important;
			}

			.source-12-regular {
				margin-top: 27px;

				@media (min-width: $wide-text-only-step) {
					margin-right: 10px;
				}
			}

			.submit-and-info {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				width: 100%;

				margin-top: 40px;

				a {
					color: inherit;
					font-weight: bold;
					&:hover {
						text-decoration: underline;
					}
				}

				@media (min-width: $wide-text-only-step) {
					flex-direction: row;
					align-items: flex-end;
					justify-content: space-between;

					.source-12-regular {
						max-width: 395px;
					}

					.btn-pill {
						order: 1;
					}
				}

				@media (min-width: $media-breakpoint-lg) {
					margin-top: 60px;
				}
			}
		}
	}
	form input[type='checkbox'] + label.input-text {
		margin-top: 30px;
	}
}
.DonationPaymentFormModule {
	margin-top: 0;
	&.DonateModule {
		background-color: $color-white;
		margin-bottom: 50px;
		.image-container, .image-placeholder {
			@media (min-width: $media-breakpoint-lg) {
				height: 100%;
			}
			img {
				position: fixed;
				top: 0;
				bottom: 0;
				width: 50%;
			}
		}
	}
	.content-background {

		@media (min-width: $media-breakpoint-lg) {
			width: 100%;
		}
		.content-container {
			> p.body-text {
				@media (min-width: $media-breakpoint-md) {
					width: calc(((100% + 20px) / 10) * 9 + -20px);
				}
			}
			.donate-choices {
				margin-top: 20px;
				position: relative;

				input + label {
					@media (min-width: $media-breakpoint-md) {
						margin-right: 20px;
						width: calc((100% + 10px)/5.8*1 + -10px);
					}

					@media (min-width: $media-breakpoint-lg) {
					  width: calc(((100% + 20px) / 5) * 2 + -20px);
					}

					@media (min-width: $media-breakpoint-xl) {
						width: calc((100% + 10px)/5.3*1 + -10px);
						margin-right: 10px;
					}
				}
				.inr {
					display: flex;
					height: 42px;
					align-items: center;
					background-color: #008c3f;
					margin-top: 20px;
					padding: 9px 5px;
					justify-content: space-between;
					width: calc(((100% + 20px) / 1) * 2 + -20px);

					@media (min-width: $media-breakpoint-md) {
						width: calc((100% + 10px)/5*1 + -10px);
						margin-right: 20px;
					}

					@media (min-width: $media-breakpoint-lg) {
						width: calc(((100% + 20px) / 2.5) * 2 + -20px);
						margin-right: 20px;
					}
					@media (min-width: $media-breakpoint-xl) {
						width: calc((100% + 10px)/4.5*1 + -10px);
						margin-right: 10px;
					}
					.rupees {
						padding: 0;
						border: none;
						span {
							@include font('Source Sans Pro', 16px, 19px, 600);
						}
						
						@media (min-width: $media-breakpoint-xxl) {
							padding-right: 5px;
						}
					}
					input {
						border: none;
						width: 100%;
						color: $color-white;
						@include font('Source Sans Pro', 16px, 19px, 600);
						padding: 0 4px;
						text-align: right;

						&::placeholder {
							color: $color-white;
						}

						&:focus {
							outline: none;
						}
			
					}
					.postInput {
						position: relative;
						right: 0;
						top: 0;

						@media (min-width: $media-breakpoint-xxl) {
    					right: 0;
						}
					}
				}
			}
		}
	}
	.DonatePaymentForm {
		.DonateFieldGroup {
			padding-bottom: 40px;
			span {
				@include font('SesameWkshp-Regular', 24px, 30px, normal);
				color: $color-black;
				&:nth-child(1) {
					color: #00ab4d;
					margin-right: 15px;

					@media (min-width: $media-breakpoint-md) {
						position: absolute;
						left: 10px;
					}
				}
			}
		}
		.input-field-wrapper {
			display: flex;
			flex-wrap: wrap;
			margin-bottom: 20px;
			position: relative;
			label + input {
				width: 100%;
			}
			label {
				margin-bottom: 10px;
				display: inline-block;
				span {
					@include font('SesameWkshp-Regular', 12px, '', normal);
					color: $color-black;
				}
			}
		}
		.mobile-no-wrap {
			display: flex;
			width: 100%;
			input[type='text'] {
				width: 100%;
				// &:nth-child(1) {
				// 	width: 60px;
				// 	margin-right: 10px;
				// }
			}
		}
		input[type='text'],
		input[type='email'] {
			@include font('SesameWkshp-Regular', 20px, 23px, normal);
			color: $color-black;
			padding: 9px;
			border: 1px solid #ccc;
			background: transparent;
			height: 45px;
			&::placeholder {
				color: #ccc;
				font-style: italic;
			}
		}
		.input-wrapper {
			display: flex;
			width: 100%;
			justify-content: space-between;
			position: relative;
			.state-info {
				width: 32%;
				display: flex;
			}
			input {
				width: 100%;
				&:nth-child(2) {
					margin: 0 10px;
				}
			}
		}
		.error {
			position: absolute;
			background-color: #b40000;
			color: $color-white;
			z-index: 1;
			padding: 8px 8px 5px;
			max-width: 150px;
			text-align: left;
			box-shadow: 1px 1px 5px 1px rgba(112,112,112,0.3);
			border-radius: 3px;
			font-size: 13px;
			bottom: -45px;
			font-family: 'SesameWkshp-Regular';
			&:before {
				position: absolute;
				content: "";
				border-left: 6px solid transparent;
				border-right: 6px solid transparent;
				border-bottom: 6px solid #b40000;
				top: -5px;
			}
		}
	}
	.gtag-donate-body-button {
		background: #00ab4d;
		border: none;
		color: $color-white;
		&:hover {
			background-color: transparent;
			color: #00ab4d !important;
			border: 1px solid #00ab4d;
		}
	}
	.content-background-first {
		padding: 50px 20px;
		background-color: #00ab4d;
		max-width: 100%;
		margin-top: 0;
		margin-left: 0;
		margin-right: 0;

		@media (min-width: $media-breakpoint-sm) and (max-width: $media-maxwidth-breakpoint-lg) {
			padding-left: calc(9.61538vw - 35.38462px);
    	padding-right: calc(9.61538vw - 35.38462px);
		}

		@media(min-width: $media-breakpoint-xl) {
			padding: 95px 20px;
		}
	}
	.content-background-second {
		background-color: $color-white;
		margin-bottom: 0;
		margin-top: 20px;
	}
	.DonatePaymentInformation {
		margin-top: 0;
	}
	.submit-and-info {
		background: $color-white;
		padding-bottom: 20px;
		padding-top: 40px;
		padding-left: calc(((100% + 20px) / 12) * 0.5 + 20px * 0);
		padding-right: calc(((100% + 20px) / 12) * 0.5 + 20px * 0);

		@media (min-width: $media-breakpoint-md) {
			padding-left: calc(((100% + 20px) / 12) * 1.5 + 20px * 0);
			padding-right: calc(((100% + 20px) / 12) * 1.5 + 20px * 0);
		}

		@media (min-width: $media-breakpoint-lg) {
			padding-left: calc(((100% + 20px) / 7) * 1 + 20px * 0);
			padding-right: calc(((100% + 20px) / 6) * 1 + 20px * 0);
		}
		input {
			&:focus {
				outline: none;
			}
		}
	}
	form {
		background-color: $color-white;
		width: 100%;
	}
}