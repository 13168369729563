@import 'eases';
@import 'sesameColors';

$color-black: #333333; // generic color
$color-white: #ffffff; // generic color
$color-dark-grey: #333333; // used for some titles and footer inks
$color-brand: #666666 !default; // used for some sporadic elements here and there, should probably be more used?
$color-darker-grey: #aaaaaa; // used for text color a few places, should probably be used more
$color-light-grey: #d8d8d8; // used for borders and disabled buttons
$color-grey: #eeeeee; // used for content backgrounds, footer and the caption under the media elements
$color-grey-light-background: #f8f8f8; //used as temporary background color for loading images
$color-teal: #009999;
$color-green: #00ab4d;

// Animation
$default-ease: $ease-in-out-quad;

// Font stack
$font-family-serif: Georgia, 'Times New Roman', Times, serif !default;
$font-japanese: heisei-maru-gothic-std;
$font-arabic-normal: 'WF-000483-000099-000120';
$font-arabic-bold: 'WF-000483-000099-000121';
$font-mandarin: 'AR YuantiGB W05 Bold';

// Media breakpoints
// (NOTE: Always use @media (min-width) when using these variables.)
$media-breakpoint-xs: 0 !default;
$media-breakpoint-sm: 576px !default;
$media-breakpoint-md: 768px !default;
$media-breakpoint-lg: 992px !default;
$media-breakpoint-xl: 1200px !default;
$media-breakpoint-xxl: 1480px !default;

//(NOTE: Always use @media (max-width) when using this variable.)
$media-maxwidth-breakpoint: 767px;
$media-maxwidth-breakpoint-lg: 992px;
$media-maxwidth-breakpoint-sm: 575px;

$burger-menu-breakpoint: $media-breakpoint-lg;
$text-inset-breakpoint: $media-breakpoint-md;

$max-page-width: 1680px;
$gutter-size: 20px;

$desktop-side-margin: 60px;
$mobile-side-margin: 20px;

// Elements
$menu-desktop-height: 70px;
$menu-mobile-height: 60px;

// Spacings
$section-top-spacing: 40px;

// z-indexes
$menu-z-index-start: 1;

@mixin poly-margin-fluid-property($property, $multiplier: 1) {
	@include poly-fluid-sizing(
		#{$property},
		(
			$media-breakpoint-sm: $mobile-side-margin * $multiplier,
			$media-breakpoint-lg: $desktop-side-margin * $multiplier
		)
	);
}

@function getMarginValue($multiplier) {
	@return $section-top-spacing * $multiplier;
}

@mixin top-margin($multiplier: 1) {
	margin-top: getMarginValue(1.5 * $multiplier);

	@media (min-width: $media-breakpoint-lg) {
		margin-top: getMarginValue(1.4 * $multiplier);
	}
}
@mixin top-padding($multiplier: 1) {
	padding-top: getMarginValue(1.5 * $multiplier);

	@media (min-width: $media-breakpoint-lg) {
		padding-top: getMarginValue(2 * $multiplier);
	}
}
