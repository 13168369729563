.RelatedContactsModule {
	position: relative;

	width: 100%;

	.default-content-inset {
		@media (min-width: $text-inset-breakpoint) {
			width: getColWidth(8, 12);
		}

		@media (min-width: $burger-menu-breakpoint) {
			width: getColWidth(6, 12);
		}
	}

	h3 {
		position: relative;
		padding-top: 20px;
		border-top: 1px solid $color-light-grey;

		width: 100%;

		color: $color-brand;
	}

	.press-contacts {
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
		align-content: flex-start;
		flex-direction: column;
		flex-wrap: wrap;

		@media (min-width: $media-breakpoint-sm) {
			flex-direction: row;
		}

		.press-contact {
			margin-top: 20px;
			width: 100%;

			@media (min-width: $media-breakpoint-sm) {
				margin-right: $gutter-size;

				&:nth-child(2n) {
					margin-right: 0px;
				}

				width: getColWidth(3, 6);
			}

			.name {
				color: $color-brand;
			}

			.info {
				p {
					color: rgba($color: $color-brand, $alpha: 0.8);

					a,
					a:visited,
					a:active {
						color: rgba($color: $color-brand, $alpha: 0.8);
					}

					a:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}
}
