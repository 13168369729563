.ImageCarouselModule {
	overflow-x: hidden;

	.padding {
		display: flex;
		flex-direction: column;

		.carousel-next-prev-buttons {
			align-self: flex-end;

			@media (min-width: $media-breakpoint-md) {
				margin-right: getColWidth(1, 12, 1);
				@include arabic() {
					margin-right: 0;
					margin-left: getColWidth(2, 12, 1);
				}
			}

			@media (min-width: $media-breakpoint-lg) {
				margin-right: getColWidth(2, 12, 1);
				@include arabic() {
					margin-right: 0;
					margin-left: getColWidth(2, 12, 1);
				}
			}
		}
	}

	//images
	.carousel-container {
		position: relative;
		margin-top: 20px;

		@media (min-width: $media-breakpoint-md) {
			margin-left: getColWidth(1, 12, 1);
			margin-right: getColWidth(1, 12, 1);
		}

		@media (min-width: $media-breakpoint-lg) {
			margin-left: getColWidth(2, 12, 1);
			margin-right: getColWidth(2, 12, 1);
		}

		.dragged-area {
			display: flex;
			height: 100%;

			.carousel-item {
				display: block;
				position: relative;
				flex-shrink: 0;
				width: 100%;

				@include poly-margin-fluid-property('margin-right');
				@include arabic() {
					@include poly-margin-fluid-property('margin-left');
					margin-right: 0;
				}
				will-change: transform;

				&:not(:first-child) {
					transform: scale(0.8);
					transform-origin: left center;
				}

				@media (min-width: $media-breakpoint-md) {
					transform-origin: center;
				}

				.img-wrapper {
					position: relative;
					width: 100%;
					overflow: hidden;
					@include aspect-ratio(875, 532);

					picture {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
					}
				}

				&.active {
					.info {
						opacity: 1;
						transition: opacity 0.2s 0.2s, transform 0.4s 0s;
						transform: translateY(0px);
					}

					@media (min-width: $media-breakpoint-md) {
						transform: scale(1);
						&:hover {
							.info {
								transition: opacity 0.2s 0s, transform 0.2s 0s;
								transform: translateY(40px);

								@include color('background-color', 'background');

								.source-12-regular {
									text-decoration: underline;
								}
							}

							img {
								transform: translateY(-5px) scale(1.05);
							}
						}
					}
				}

				.info {
					padding-top: 20px;
					color: $color-dark-grey;

					.source-12-regular {
						text-decoration: underline;
					}

					@media (min-width: $media-breakpoint-md) {
						transform: translateY(20px);
						transition: opacity 0.2s 0s, transform 0.2s 0s;

						opacity: 0;
						padding: 32px 30px 35px;
						color: $color-white;
						position: absolute;
						bottom: 20px;
						left: 20px;
						right: 20px;
						display: block;
						background-color: rgba(
							$color: (
								$color-black
							),
							$alpha: 0.5
						);
					}

					> .rounded-24-light-adaptive {
						@media (min-width: $media-breakpoint-md) {
							font-size: 18px;
							line-height: 24px;
						}
						@media (min-width: $media-breakpoint-lg) {
							font-size: 22px;
							line-height: 30px;
						}
						@media (min-width: $media-breakpoint-xl) {
							font-size: 24px;
							line-height: 32px;
						}
					}

					> .body-text {
						margin-top: 3px;
						margin-bottom: 5px;
						font-size: 14px;
						line-height: 18px;
						@media (min-width: $media-breakpoint-md) {
							margin-bottom: 19px;
							font-size: 14px;
							line-height: 18px;
						}
						@media (min-width: $media-breakpoint-lg) {
							font-size: 17px;
							line-height: 20px;
						}
						@media (min-width: $media-breakpoint-xl) {
							font-size: 18px;
						}
					}

					p {
						opacity: 0.8;
					}
				}

				img {
					position: absolute;
					object-fit: cover;
					font-family: 'object-fit: cover';
					height: 100%;
					width: 100%;
					will-change: transform;
					transition: all 0.6s;
				}
			}
		}
	}

	//dots
	.dots-container {
		margin-top: 25px;
		display: flex;

		align-self: center;
		margin-bottom: 4px;

		.dot {
			width: 8px;
			height: 8px;
			border-radius: 8px;
			background-color: $color-dark-grey;
			opacity: 0.3;
			margin-right: 5px;
			margin-left: 5px;

			transition: 0.2s transform;
			cursor: pointer;

			&:hover {
				transform: scale(1.3);
			}

			&.active {
				opacity: 1;
			}

			//&:last-child {
			//	margin-right: 0px;
			//}
		}
	}
}
