.StartAFundraiserModule {
	position: relative;

	.wrapper {
		background-color: map-get($color-green-map, 'background');
		display: flex;
		height: auto;
		position: relative;
		overflow: hidden;
		align-items: stretch;
		justify-content: space-between;

		flex-direction: column;

		@media (min-width: $burger-menu-breakpoint) {
			flex-direction: row;
		}

		color: $color-white;

		padding: #{getColWidth(1, 12, 1)};
		padding-top: 40px;
		padding-bottom: 65px;
		min-height: 400px;

		@media (min-width: $burger-menu-breakpoint) {
			padding-top: 80px;
		}

		.text {
			display: flex;
			flex-direction: column;
			justify-content: space-around;

			width: 100%;

			@media (min-width: $text-inset-breakpoint) {
				width: getColWidth(8, 10);
			}

			@media (min-width: $burger-menu-breakpoint) {
				width: getColWidth(4, 10);
			}

			margin-right: 20px;

			p {
				margin-top: 13px;
			}

			.btn-pill {
				margin-top: 33px;
				align-self: flex-start;

				color: map-get($color-green-map, 'background');
			}
		}

		.char-container {
			display: flex;
			justify-content: center;
			align-self: flex-end;
			width: 100%;
			margin-top: 15%;

			@media (min-width: $text-inset-breakpoint) {
				width: getColWidth(7, 10);
				align-items: flex-end;
				margin-top: 0%;
			}

			@media (min-width: $burger-menu-breakpoint) {
				width: getColWidth(5, 10);
			}

			.character {
				width: 100%;
				position: relative;
				max-width: 447px;
				max-height: 254px;

				&.hidden {
					transform: translateY(400px);
				}

				.big-bird {
					position: relative;
					display: block;
					bottom: -67px;
					width: 100%;
					height: auto;

					animation: character-animation 5s infinite;

					@keyframes character-animation {
						0%,
						100% {
							transform: translateY(5px);
						}
						50% {
							transform: translateY(0px);
						}
					}
				}

				.feathers {
					display: block;
					position: absolute;
					bottom: 0;
					right: 0;
					width: 100%;
					height: calc(100% - 67px);

					.feather-1 {
						width: 10%;
					}

					.feather-2 {
						width: 12%;
					}

					.feather-3 {
						width: 15%;
					}

					@mixin feather-animation($start-rotation, $start-translate, $id) {
						animation: f-#{$id}-animation #{2 + random(3)}s infinite;
						animation-timing-function: ease-in-out;

						@keyframes f-#{$id}-animation {
							0%,
							100% {
								transform: rotate($start-rotation) translateY($start-translate);
							}
							50% {
								transform: rotate($start-rotation + 3) translateY($start-translate + 3);
							}
						}
					}

					#f-1 {
						left: 6%;
						top: 0%;
						@include feather-animation(0deg, 5px, 1);
					}

					#f-2 {
						left: 40%;
						top: -25%;

						@include feather-animation(220deg, 5px, 2);
					}

					#f-3 {
						left: 80%;
						top: 20%;

						@include feather-animation(0deg, 5px, 3);
					}

					#f-4 {
						left: 50%;
						top: -60%;

						@include feather-animation(10deg, 5px, 4);
					}

					#f-5 {
						left: 70%;
						top: -20%;

						@include feather-animation(60deg, 5px, 5);
					}

					#f-6 {
						left: 30%;
						top: -40%;
						transform: rotate(270deg);

						@include feather-animation(270deg, 5px, 6);
					}

					#f-7 {
						left: 60%;
						top: -75%;

						@include feather-animation(0deg, 5px, 7);
					}

					#f-8 {
						left: 18%;
						top: -55%;

						@include feather-animation(0deg, 5px, 8);
					}

					#f-9 {
						left: -0%;
						top: -60%;

						@include feather-animation(80deg, 5px, 9);
					}

					img {
						position: absolute;
						bottom: 0px;
					}
				}
			}
		}
	}
}
