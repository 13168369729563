.PresskitSeasonOverviewTemplate {
	section {
		.summary {
			color: rgba($color-dark-grey, 0.8);
		}

		.mini-link {
			display: block;
			margin-top: 30px;
		}
	}
}
