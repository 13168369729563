.TitleSubheaderLinkBlock {
	@include color('background-color', 'background');

	display: flex;
	justify-content: space-between;
	flex-direction: column;
	align-items: flex-start;

	.content {
		width: 70%;

		p {
			margin-top: 10px;
		}
	}

	.btn-pill {
		@include color('color', 'background');
		margin-top: 20px;

		&:hover {
			color: $color-white;
		}
	}
}
