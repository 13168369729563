.ErrorTemplate {
	display: flex;
	align-content: center;
	justify-content: center;
	.HeroModule {
		.text-style-h1 {
			color: $color-dark-grey !important;
		}
	}

	.error-logo {
		position: absolute;
		top: 12px;
		left: 50%;
		transform: translateX(-50%);
		text-align: center;
	}
	.container.padding {
		align-self: center;
		justify-self: center;
	}
	.ErrorTemplate__message {
		margin-top: -70px;
		max-width: 800px;
		align-self: center;
		text-align: center;
		.btn-pill {
			color: $color-white;
			height: 40px;
			padding: 0 30px;
			display: inline-flex;
			font-size: 15px;
			font-weight: 600;
			min-width: 150px;
			margin-top: 30px;
			align-items: center;
			justify-content: center;
			background: map-get($color-blue-map, 'illustration');
		}
		img {
			width: 100%;
			max-width: 480px;
		}
		p {
			margin-top: 10px;
			color: #333333;
			@include font('Adelle', 20px, 28px, normal);
		}
		h1 {
			margin-bottom: 20px;
		}
		a {
			color: map-get($color-green-map, 'links');
		}
	}
}
