.PressKitMediaRelatedMenuModule {
	ul {
		list-style: none;
		@media (min-width: $text-inset-breakpoint) {
			padding-left: getColWidth(1, 12, 1);
			padding-right: getColWidth(1, 12, 1);
		}

		@media (min-width: $burger-menu-breakpoint) {
			padding-left: getColWidth(2, 12, 1);
			padding-right: getColWidth(2, 12, 1);
		}
		li {
			padding: 0;
			margin: 0;
			position: relative;
			@media (min-width: $text-inset-breakpoint) {
				&:before {
					position: absolute;
					content: '';
					background: url("data:image/svg+xml,%3Csvg width='17' height='14' xmlns='http://www.w3.org/2000/svg'%3E%3Cg transform='translate(0 1)' fill='none' fill-rule='evenodd'%3E%3Cpath stroke='%23FFB600' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' d='M10 0l6 6-6 6'/%3E%3Crect fill='%23FFB600' y='5' width='16' height='2' rx='1'/%3E%3C/g%3E%3C/svg%3E")
						no-repeat;
					display: block;
					width: 17px;
					height: 14px;
					left: -35px;
					top: calc(50% - 7px);
					transition: opacity 0.15s, transform 0.3s;
					opacity: 0;
					transform: translate3d(-5px, 0, 0);
				}
			}
			&:hover {
				a {
					color: $color-black;
				}
			}
			a {
				@include font('Source Sans Pro', 20px, 36px, 600);
				color: #a7a8aa;
				transition: color 0.15s;
			}
			&.active {
				&:before {
					opacity: 1;
					transform: translate3d(0, 0, 0);
				}
				a {
					color: $color-black;
				}
			}
		}
	}
}
