.template {
	height: 100%;
   width: 100%;
	flex: 1 0 auto;
    // padding-top:60px;

    // @media(min-width: $burger-menu-breakpoint) {
    //     padding-top: 70px;
    // }
	

	.LearnMoreBreakerFullWidthModule {
	  .full-width-block {
	  	
	  	@media(min-width: $media-breakpoint-md) {
	  		padding-left: calc((100% + 20px)/12*1);
	    	padding-right: calc((100% + 20px)/12*1);
	  	}

	  	@media(min-width: $media-breakpoint-lg) {
	    	padding-left: calc((100% + 20px)/12*2);
	    	padding-right: calc((100% + 20px)/12*2);
	    }

	    .color-block {
	      width: 100%;
	      min-height: 0;
	      .content {
	        width: 100%;
	      }
	      .btn-pill {
	      	margin-bottom: 11px;
	      }
	    }
	    p {
	    	margin-top: -5px;
	    }
	  }
	}
	.btn-pill {
	  @extend .source-16-semibold;
	}

	.TextSectionFullWidthModule {
		&.LearnMoreBreakerFullWidthModule {
			margin-bottom: 63px;
			.full-width-block {

				@media(min-width: $media-breakpoint-md) {
	  			padding-left: calc((100% + 20px)/12*1);
	    		padding-right: calc((100% + 20px)/12*1);
	  		}
	  		@media(min-width: $media-breakpoint-lg) {
					padding-left: calc((100% + 20px)/12*1.7);
		    	padding-right: calc((100% + 20px)/12*1.7);
		    }

				h4 {
					@include font('SesameWkshp-Regular', 28px, 32px, normal);
				}
				p {
					@include font('Source Sans Pro', 20px, 30px);
					margin: 18px 0 6px 0;
				}
				br {
					display: none;
				}
			}
		}
	}
	.HeaderAndSubheaderModule {
		.subheader {
			.main-content {
				@include font('SesameWkshp-Regular', 16px, '', normal);
				p {
					&.large {
						@include font('SesameWkshp-Regular', 24px, 30px, normal);
					}
				}
			}
		}
	}
	&.teal-page {
		.HeaderAndSubheaderModule {
			.subheader {
				.main-content {
					@include font('Source Sans Pro', 20px, 28px, 500);
				}
			}
		}
	}
}
