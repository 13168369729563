.color-block {
	width: 100%;
	min-height: 350px;
	padding: 40px 20px 20px;
	position: relative;

	color: $color-white;

	@include color('background-color', 'background');

	margin-top: $gutter-size;

	&:first-child {
		margin-top: 0px;
	}

	@media (min-width: $burger-menu-breakpoint) {
		width: getColWidth(6, 12);

		padding: 40px 30px 30px;

		margin-right: $gutter-size;
		margin-top: 0px;
		@include arabic() {
			margin-right: 0;
			margin-left: $gutter-size;
		}
		&:nth-child(2n) {
			margin-right: 0px;
			@include arabic() {
				margin-left: 0;
			}
		}
	}
}
