.ImageGridItem {
	margin-bottom: 60px;
	width: 100%;
	display: block;

	a {
		display: flex;
		flex-direction: column;
		width: 100%;
		margin-bottom: 0px;
	}

	&.logos {
		.img-wrapper {
			border: 1px solid $color-light-grey;
			height: 236px;
			width: 100%;
			display: flex;
			justify-content: space-around;
			align-items: center;
			padding: $gutter-size;

			background-color: transparent;

			&::before {
				display: none;
			}

			.aspect-content {
				position: relative;
			}
		}

		img {
			max-width: 100%;
			width: auto;
			height: auto;
		}

		a:hover,
		a:active,
		a.active {
			.img-wrapper {
				border: 1px solid rgba($color-brand, 0.5);
			}
		}

		.content {
			h5 {
				margin-top: 10px;
			}
		}
	}

	.content {
		width: 100%;
		padding-right: $gutter-size;
		color: rgba($color-brand, 0.8); //$color-medium-grey;

		@include arabic() {
			padding-left: $gutter-size;
		}
		h5 {
			margin-top: 5px;
			color: $color-brand;
		}

		p {
			margin-top: 5px;
		}

		p.mini-link {
			margin-top: 10px;
		}

		a:hover {
			@include color('color', 'text');
		}
	}

	img {
		width: 100%;
		//transform: translateZ(0); //remove initial blur jump on image hover
	}

	.img-wrapper {
		margin-bottom: 12px;

		@include aspect-ratio(426px, 236px);
		width: 100%;
	}

	.two & {
		position: relative;
		margin-bottom: $gutter-size;

		.img-wrapper {
			margin-bottom: 0px;

			@include aspect-ratio(650px, 450px);
		}

		.content {
			position: absolute;
			bottom: $gutter-size;
			left: $gutter-size;
			right: $gutter-size;
			width: auto;
			background-color: rgba($color: #000000, $alpha: 0.6);
			padding: $gutter-size;
			color: $color-white;

			h5 {
				margin-top: 0px;
				color: $color-white;
			}

			p {
				opacity: 0.8;

				width: 100%;

				@media (min-width: $media-breakpoint-md) {
					width: 60%;
				}

				&.mini-link {
					opacity: 0.5;

					&:hover {
						opacity: 1 !important;
					}
				}
			}
		}
	}
	&.no-image {
		.img-wrapper {
			display: none;
		}
		h5 {
			color: map-get($color-orange-map, 'text');
		}
		.source-16-regular {
			color: #333333;
		}
	}
}