.ImageLinkGridModule {
	.padding {
		float: left; //Avoid collapsing margins
		width: 100%;

		.grid-container {
			display: flex;
			width: 100%;
			justify-content: flex-start;
			flex-flow: row wrap;
			margin-bottom: -60px;
		}
	}

	.ImageGridItem {
		@media (min-width: $media-breakpoint-md) {
			width: getColWidth(6, 12);
			margin-right: $gutter-size;
			@include arabic() {
				margin-right: 0;
				margin-left: $gutter-size;
			}

			&:nth-child(2n) {
				margin-right: 0px;
				@include arabic() {
					margin-left: 0px;
				}
			}
		}
		.box-wrapper {
		  &.img-wrapper{
		    background-color: transparent;
		    margin-bottom: 0;
		    &:before {
		      content: none;
		    }
		  }
		}
	}

	&.two {
		.ImageGridItem {
			@media (min-width: $media-breakpoint-md) {
				width: 100%;
				margin-right: 0px;
				@include arabic() {
					margin-left: 0px;
				}
			}

			@media (min-width: $media-breakpoint-lg) {
				width: getColWidth(6, 12);
				margin-right: $gutter-size;
				@include arabic() {
					margin-right: 0;
					margin-left: $gutter-size;
				}

				&:nth-child(2n) {
					margin-right: 0px;
					@include arabic() {
						margin-left: 0px;
					}
				}
			}
		}
	}

	&.two_no_overlay {
		.ImageGridItem {
			@media (min-width: $media-breakpoint-lg) {
				width: getColWidth(6, 12);
				margin-right: $gutter-size;
				@include arabic() {
					margin-right: 0;
					margin-left: $gutter-size;
				}
				&:nth-child(2n) {
					margin-right: 0px;
					@include arabic() {
						margin-left: 0px;
					}
				}
			}
		}
	}

	&.three {
		.ImageGridItem {
			@media (min-width: $media-breakpoint-lg) {
				width: getColWidth(4, 12);

				&:nth-child(2n) {
					margin-right: $gutter-size;
					@include arabic() {
						margin-right: 0;
						margin-left: $gutter-size;
					}
				}

				&:nth-child(3n) {
					margin-right: 0px;
					@include arabic() {
						margin-left: 0;
					}
				}
			}
		}
	}

	&.four {
		.ImageGridItem {
			@media (min-width: $media-breakpoint-lg) {
				width: getColWidth(3, 12);

				&:nth-child(2n) {
					margin-right: $gutter-size;
					@include arabic() {
						margin-right: 0;
						margin-left: $gutter-size;
					}
				}

				&:nth-child(4n) {
					margin-right: 0px;
					@include arabic() {
						margin-left: 0;
					}
				}
			}
		}
	}

	&.five {
		.ImageGridItem {
			@media (min-width: $media-breakpoint-lg) {
				width: getColWidth(2, 12, 1);

				&:nth-child(n) {
					margin-right: $gutter-size;
					@include arabic() {
						margin-right: 0;
						margin-left: $gutter-size;
					}
				}

				&:nth-child(5n) {
					margin-right: 0px;
					@include arabic() {
						margin-left: 0;
					}
				}
			}
		}
	}
}

.ImageLinkGridModule + .ColorTextBlocksModule {
	margin-top: 60px;
}
