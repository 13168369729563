.SingleMediaBreakerModule {
	.padding {
		.wrapper {
			position: relative;
			z-index: 0;

			width: 100%;

			@media (min-width: $media-breakpoint-md) {
				width: getColWidth(11, 12);
			}

			@media (min-width: $media-breakpoint-lg) {
				width: getColWidth(10, 12);
			}

			.media-container {
				width: 100%;
				overflow: hidden;
				@include aspect-ratio(1280, 720);

				.play-btn {
					position: absolute;
					@include hollowCircle($color-white, 60px, 1, 2px);
					z-index: 1;
					top: calc(50% - 30px);
					left: calc(50% - 30px);
					transform-origin: center;

					display: flex;
					justify-content: space-around;
					align-items: center;
					padding-left: 2px;
				}

				.SingleMediaBreakerModule__video {
					position: absolute;
					width: 100%;
					height: 100%;
					top: 0;
					left: 0;
				}

				.SingleMediaBreakerModule__poster-wrapper {
					background: $color-grey;
					position: absolute;
					width: 100%;
					height: 100%;
					top: 0;
					left: 0;
				}

				img {
					width: 100%;
					display: block;
				}

				video {
					width: 100%;
					height: 100%;
				}
			}

			.caption-text {
				width: 100%;
				background-color: $color-grey;
				color: rgba($color: $color-dark-grey, $alpha: 0.5);
				padding: 15px 15px;

				@media (min-width: $media-breakpoint-md) {
					padding: 15px 40px;
				}
			}
		}
	}
}
