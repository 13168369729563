.PressReleasesSearchInputModule {
	position: relative;
	@include color('background-color', 'text');

	form {
		position: relative;

		padding-top: 30px;
		padding-bottom: 30px;

		@media (min-width: $media-breakpoint-xl) {
			margin-left: getColWidth(2, 12, 1);
			margin-right: getColWidth(2, 12, 1);
		}

		display: flex;
		flex-flow: row;
		-ms-flex-flow: row;
		flex-wrap: wrap;
		justify-content: space-between;

		select::-ms-expand {
			display: none;
		}
		input,
		select {
			border: 0px;
			border-radius: 2px;
			padding: 5px 15px;
			padding-right: 40px;
			height: 40px;
			background-color: $color-white;

			appearance: none;
			width: 100%;
			font-family: 'Source Sans Pro', serif;
			@include localeFont('Source Sans Pro');
			color: #a7a8aa;
		}

		.PressReleasesSearchInputModule__input-wrapper {
			position: relative;
			width: 100%;
			margin-bottom: 20px;

			@media (min-width: $media-breakpoint-md) {
				margin-right: $gutter-size;
				margin-bottom: 0px;
				width: getColWidth(4, 8);
			}

			input {
				padding-right: 50px;
				background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M0 0h38a2 2 0 0 1 2 2v36a2 2 0 0 1-2 2H0V0z' fill='%23FF9800'/%3E%3Cg transform='translate(11 10)' stroke='%23FFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'%3E%3Ccircle transform='rotate(-23.03 8.8 8.8)' cx='8.8' cy='8.8' r='6.71'/%3E%3Cpath d='M13 14l5.2 5.2'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
				background-repeat: no-repeat;
				background-size: 40px 40px;
				background-position: right center;
				color: #333333;

				&::placeholder {
					color: #a7a8aa;
					font-style: italic;
				}
			}
		}

		.PressReleasesSearchInputModule__select-wrapper {
			position: relative;
			width: calc(50% - 10px);

			@media (min-width: $media-breakpoint-md) {
				margin-right: $gutter-size;
				&:last-child {
					margin-right: 0px;
				}
				width: getColWidth(2, 8);
			}

			select {
				background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M0 0h38a2 2 0 0 1 2 2v36a2 2 0 0 1-2 2H0V0z' fill='%23FF9800'/%3E%3Cpath fill='%23FFF' d='M23.5 18.5l-3.5 4-3.5-4z'/%3E%3C/g%3E%3C/svg%3E");
				background-repeat: no-repeat;
				background-size: 40px 40px;
				background-position: right center;
				font-style: italic;

				option {
					font-style: normal;
				}
			}
		}
	}
}
