.ArticleSubpageTemplate {
  .title {
    width: 100%;
    position: relative;
    margin-top: 50px;

    .header {
        @media(min-width: $text-inset-breakpoint) {
            padding-left: getColWidth(1, 12, 1);
            padding-right: getColWidth(1, 12, 1);
        }

        @media(min-width: $burger-menu-breakpoint) {
                padding-left: getColWidth(2, 12, 1);
                padding-right: getColWidth(2, 12, 1);
        }
    }

    .padding>h2.header {
        margin-bottom: -20px;
    }
  }
  &.gender-equity, &.education, &.wash-and-sanitation { /*new added */
    .tabs-drag-bounds {
      display: none;
    }
    .TabbedStoriesModule {
      margin-top: 0;
    }
  }
  &.wash-and-sanitation {
    .TextModule {
      &.spacing {
        .article {
          p {
            &:nth-child(4) {
              margin-bottom: 100px;
              margin-top: -60px;
              @extend .source-12-regular; 
            }
          }
        }
      }
    }
  }
  &.building-communities-of-change, &.icds-uttarakhand, &.learn-play-grow,
  &.play-connect-learn, &.play-every-day, &.sapnabachatudaan, &.raho-swachhjiyo-mast,
  &.play-n-learn, &.learn-to-read-read-to-learn, &.hello-tomorrow {
    .TextModule {
      &.spacing {
        margin-top: 30px;
      }
    }
  }

  .NewsletterSignupModule {
    .title {
      margin-top: 0;
    }
  }
}