.CareerOpportunitiesListItem {
	.padding {
		.item-content {
			.text-content {
				width: getColWidth(10, 12);
				display: flex;
				margin-right: $gutter-size;

				flex-shrink: 1;
				flex-direction: column;

				@media (min-width: $text-inset-breakpoint) {
					flex-direction: row;
					align-items: center;
				}

				.job-title,
				.department,
				.category {
					flex-shrink: 0;
					display: flex;

					flex-direction: column;

					color: $color-brand;

					width: 100%;
				}

				.department,
				.category {
					opacity: 0.8;

					@media (min-width: $text-inset-breakpoint) {
						/* padding: 5px 0px; */
					}
				}

				.job-title {
					margin-right: $gutter-size;

					@media (min-width: $text-inset-breakpoint) {
						/* padding: 9px 0px; */
						width: getColWidth(5, 10);
					}
				}

				.department {
					margin-right: $gutter-size;

					@media (min-width: $text-inset-breakpoint) {
						width: getColWidth(3, 10);
					}
				}

				.category {
					margin-right: $gutter-size;

					@media (min-width: $text-inset-breakpoint) {
						width: getColWidth(2, 10);
					}
				}
			}

			.button-area {
				flex-grow: 1;
				min-width: getColWidth(2, 12);
				justify-content: space-around;
				display: flex;
				align-items: center;
				margin-top: -5px;

				@media (min-width: $text-inset-breakpoint) {
					justify-content: flex-end;
				}
			}
		}
	}
}
