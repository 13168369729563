.carousel-next-prev-buttons {
	display: flex;
	width: 90px;
	justify-content: space-between;
	align-self: flex-end;
	position: relative;
	pointer-events: none;

	.btn-wrapper {
		display: flex;
		justify-content: space-around;
	}

	.btn-round {
		&:not(.disabled) {
			@include color('background-color', 'links');
		}

		display: flex;
		align-items: center;
		justify-content: space-around;

		transition: 0.2s background-color;

		pointer-events: all;
		border: none;
		appearance: none;
		@include arabic() {
			svg {
				transform: scaleX(-1);
			}
		}
		&:focus {
			outline: none;
		}
	}
}
