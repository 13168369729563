.MiniDonateBlock {
	background-color: map-get($color-green-map, 'background') !important;

	form {
		width: 100%;

		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 100%;

		@media (min-width: $media-breakpoint-sm) {
			width: calc(50% + 10px);
		}

		@media (min-width: $burger-menu-breakpoint) {
			width: 100%;
		}

		@media (min-width: $media-breakpoint-xl) {
			width: calc(50% + 10px);
		}

		.btn-pill {
			margin-top: 20px;
			align-self: flex-start;

			color: map-get($color-green-map, 'background');
		}

		.input-btn-text {
			color: $color-white;

			&::placeholder {
				color: $color-white;
			}
		}
	}

	.clapping-hands {
		display: none;
		justify-content: space-around;

		position: absolute;
		right: 0px;
		width: calc(50% - 10px);
		bottom: 0px;
		pointer-events: none;
		@include arabic() {
			right: auto;
			left: 0;
		}
		@media (min-width: $media-breakpoint-sm) {
			display: flex;
		}

		@media (min-width: $burger-menu-breakpoint) {
			display: none;
		}

		@media (min-width: $media-breakpoint-xl) {
			display: flex;
		}
	}

	h3 {
		margin-bottom: 13px;
	}

	svg {
		.filled * {
			fill: map-get($color-green-map, 'illustration');
		}

		.stroked * {
			stroke: map-get($color-green-map, 'illustration');
		}
	}
	.checkbox-dot {
		svg {
			polyline {
				stroke: $color-white;
			}
		}
	}
	.donate-choices {
		width: 100%;

		input + label {
			width: calc(50% - 10px);
			margin-right: 20px;

			&:nth-child(2n) {
				margin-right: 0px;
			}

			@media (min-width: $burger-menu-breakpoint) {
				width: calc(25% - 10px);
			}
		}
	}
}
