.PressSearch__header {
	color: #333333;
	display: block;
	width: 100%;
	@media (min-width: $media-breakpoint-md) {
		padding-left: getColWidth(1, 12, 1);
		padding-right: getColWidth(1, 12, 1);
	}

	@media (min-width: $media-breakpoint-lg) {
		padding-left: getColWidth(2, 12, 1);
		padding-right: getColWidth(2, 12, 1);
	}
	margin-bottom: 40px;

	@include font('VAG Rounded Std', 20px, 28px, 300);
	@media (min-width: $media-breakpoint-lg) {
		font-size: 48px;
		line-height: 55px;
	}

	span {
		color: map-get($color-orange-map, 'links');
	}
}

.PressSearch__hide-list {
	overflow: hidden;
}

.PressSearch__result_list {
	height: 0;
	overflow: hidden;
	.Search__search-suggestion__word {
		color: map-get($color-orange-map, 'links');
	}
}
.PressSearch__results-wrapper__results-list {
	a {
		padding-top: 23px;
		@media (min-width: $media-breakpoint-lg) {
			padding-top: 30px;
		}
		border-top: 1px solid #eaeaeb;
	}
}
