// Text, icons, hover states
$primary-foreground-color: #fff !default;

// Control backgrounds (control bar, big play, menus)
$primary-background-color: #fff !default;
$primary-background-transparency: 0 !default;

// Hover states, slider backgrounds
$secondary-background-color: lighten($primary-background-color, 33%) !default;
$secondary-background-transparency: 0 !default;

// Avoiding helvetica: issue #376
$text-font-family: Arial, Helvetica, sans-serif !default;

// Using the '--' naming for component-specific styles
$big-play-button--width: 40px !default;
$big-play-button--height: 40px !default;
$big-play-button--transparency: 1 !default;
