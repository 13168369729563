.AdditionalResourcesModule {
	h4 {
		@media (min-width: $text-inset-breakpoint) {
			padding-left: getColWidth(1, 12, 1);
			padding-right: getColWidth(1, 12, 1);
		}

		@media (min-width: $media-breakpoint-lg) {
			padding-left: getColWidth(2, 12, 1);
			padding-right: getColWidth(2, 12, 1);
		}

		margin-bottom: 12px;

		color: $color-dark-grey;
	}

	ul {
		list-style-type: none;

		width: getColWidth(12, 12);

		@media (min-width: $text-inset-breakpoint) {
			width: getColWidth(8, 12);
			margin-left: getColWidth(1, 12, 1);
		}

		@media (min-width: $media-breakpoint-lg) {
			margin-left: getColWidth(2, 12, 1);
			width: getColWidth(6, 12);
		}

		li {
			border-top: 1px solid $color-light-grey;
			padding: 15px 0px;

			&:last-child {
				border-bottom: 1px solid $color-light-grey;
			}

			a,
			a:visited {
				color: $color-dark-grey;
			}

			a:hover,
			a:active,
			a.active {
				@include color('color', 'links');
			}
		}
	}
}
