.LoginModule {
	.padding {
		margin: 0 auto;
		display: flex;
		align-items: center;
		justify-content: space-around;
	}

	color: white;

	display: flex;
	flex-flow: row;
	align-items: stretch;

	position: relative;

	.LoginModule__errors {
		margin-top: 10px;
		color: map-get($color-orange-map, 'text');
		font-family: 'Source Sans Pro', serif;
		font-weight: 600;
		font-size: 16px;
		@include localeFont('Source Sans Pro');
	}

	+ footer {
		margin-top: 0px !important;
	}

	.LoginModule__form-wrapper {
		padding-top: 45px;
		margin-bottom: 45px;
		display: flex;
		align-items: center;
		flex-flow: column;
		justify-content: center;

		@media (min-width: 1420px) {
			//width: 100%;
			justify-content: space-between;
			flex-flow: row;
			width: getColWidth(10);
		}

		@media (min-width: $burger-menu-breakpoint) {
			width: getColWidth(8);
		}

		svg {
			order: -1;
			//width: 20%;
			width: 60%;
			max-width: 289px;
			@media (min-width: 1420px) {
				order: 1;
				width: 100%;
				flex-grow: 1;
				margin-left: 40px;
				display: block;
			}
		}

		.LoginModule__form-wrapper__inner {
			margin-top: 40px;
			max-width: 588px;
			@media (min-width: 1420px) {
				margin-top: 0;
			}
		}

		form {
			display: flex;
			flex-flow: row;
			width: 100%;
			max-width: 496px;
			margin-top: 40px;
			@media (min-width: $media-breakpoint-lg) {
				margin-top: 60px;
			}
		}

		h2 {
			color: #666666;
		}

		input {
			position: relative;
			width: 100%;

			border: 0px;
			border-radius: 0;
			border-top-left-radius: 2px;
			border-bottom-left-radius: 2px;
			padding: 5px 15px;
			padding-right: 40px;
			height: 40px;
			background-color: #f0f0f0;

			appearance: none;
			display: block;
			font-family: 'Source Sans Pro', serif;
			@include localeFont('Source Sans Pro');
			color: #333333;
			font-size: 16px;

			&::placeholder {
				color: #a7a8aa;
				font-style: italic;
			}
		}

		button {
			cursor: pointer;
			min-width: 102px;
			appearance: none;
			display: block;
			border: 0px;
			border-top-right-radius: 2px;
			border-bottom-right-radius: 2px;
			padding: 5px 15px;
			height: 40px;
			background-color: map-get($color-orange-map, 'text');
			font-family: 'Source Sans Pro', serif;
			@include localeFont('Source Sans Pro');
			font-weight: 600;
			font-size: 16px;
			color: #ffffff;
		}

		.source-12-regular {
			margin-top: 40px;
			max-width: 496px;
			color: #a7a8aa;

			a {
				color: inherit;
				font-weight: bold;

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}

	&.w-100 {
		background-color: map-get($color-orange-map, 'background');

		.image-container {
			display: none;
			position: relative;
			overflow: hidden;
			background-color: map-get($color-orange-map, 'background');

			@media (min-width: $media-breakpoint-lg) {
				width: 50%;
				//height: 100%;
				height: 576px;
				display: block;
				flex: 0 0 auto;
			}

			picture {
				width: 100%;
				height: 100%;
				//max-height: 576px;
			}

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				font-family: 'object-fit: cover';
			}
		}

		.LoginModule__form-wrapper {
			flex-flow: column;
			justify-content: center;
			@include poly-margin-fluid-property('margin-left');
			@include poly-margin-fluid-property('margin-right');
			@media (min-width: $media-breakpoint-md) {
				//margin-top: 65px;
				//margin-bottom: 57px;
			}

			@media (min-width: $media-breakpoint-lg) {
				width: 50%;
				max-width: #{$max-page-width * 0.5};
				padding-left: #{$gutter-size * 0.5};
				@include poly-margin-fluid-property('padding-right');

				margin-left: 0px;
				margin-right: 0px;
			}

			@media (min-width: $media-breakpoint-xl) {
				//margin-top: 95px;
				//margin-bottom: 57px;
			}

			.LoginModule__form-wrapper__inner {
				max-width: none;
				@media (min-width: $media-breakpoint-md) {
					padding-left: getColWidth(1, 12, 1);
					padding-right: getColWidth(1, 12, 1);

					> p.body-text {
						width: getColWidth(8, 10);
					}
				}
				@media (min-width: $media-breakpoint-lg) {
					padding-left: getColWidth(1, 6, 1);
					padding-right: 0px;
				}
			}

			.source-12-regular {
				color: #ffffff;
			}

			h2 {
				color: #ffffff;
			}

			input {
				background-color: $color-white;
				color: #333333;

				&::placeholder {
					color: #a7a8aa;
				}
			}
		}
	}
}
