.video-js .vjs-slider {
	position: relative;
	cursor: pointer;
	padding: 0;
	margin: 0 0.45em 0 0.45em;
	border-radius: 2px;
	@include user-select(none);

	background-color: rgba(255, 255, 255, 0.4);
}

.video-js .vjs-slider.disabled {
	cursor: default;
}
