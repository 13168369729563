.CareerOpportunitiesListModule {
  .jobs-list {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .show-more {
    width: 100%;
    display: flex;

    margin: 20px 0px 0px;
    justify-content: space-around;
  }
}

.JobListingModule {
  margin-bottom: 80px;
}
.vertical { 
  width:100%;
  // ul {
  //   list-style:none;
  //   margin:0;
  //   padding:0; 
  // }   
  // li {
  //   margin-bottom: 15px;
  //   padding:0;
  // }
  // [type=radio], [type=checkbox] {
  //  display: none;
  //  &:checked ~ label {
  //    transition: all .2s ease-out;
  //  }
  // }
  .container {
    margin-bottom: 15px;
  }
  .accordion {
    display: flex;
    background: #e5e5e5;
    color: $color-dark-grey;
    cursor:pointer;
    padding-top: 25px;
    padding-bottom: 20px;
    height: 86px;
    transition: all .2s ease-out;
    cursor: pointer;
    position: relative;
    @include font('SesameWkshp-Regular', 20px, 23px, normal); 
    &:hover {
      background: $color-teal;
      transition: all .2s ease-out;
      p {
        color: $color-white;
        span {
          &:after {
            background: $color-white;
          }
        }
      }
      .cross {
        &:before, &:after {
          border-top: 2px solid $color-white;
        }
      }
    }
    .padding {
      display: flex;
      width: 100%;
    }
    .detail-wrapper {
      display: flex;
      width: 100%;
      align-self: center;
      flex-direction: row; 

      @media (max-width: $media-maxwidth-breakpoint-sm) {
        flex-direction: column;
      }  
    }   

    @media (max-width: $media-maxwidth-breakpoint-sm) {
      height: 120px;
    }  

    @media (max-width:  $media-maxwidth-breakpoint) {
      font-size: 15px;
    }
   
  }
  .panel {
    //overflow-y: scroll;
    // transition: all .5s ease-out;
    @include font('Source Sans Pro', 20px, 28px, 500);
    overflow: hidden;
    opacity: 0;
    max-height: 0;
    transition: all .3s ease-out;
    box-sizing: border-box;
    position: relative;
    h4 {
      font-weight: 700;
    }
    .subheader {
      padding-bottom: 40px;
      padding-top: 40px;
      margin-bottom: 0;
      p {
        a {
          color: $color-teal;
        }
      }
    }
   
    &::-webkit-scrollbar {
      width: 10px;
    }

  
    &::-webkit-scrollbar-track {
      background: #f1f1f1; 
    }
     
    
    &::-webkit-scrollbar-thumb {
      background: $color-teal; 
      border-radius: 10px;
    }

    
    &::-webkit-scrollbar-thumb:hover {
      background: $color-teal; 
    }
    &.show {
      opacity: 1;
      max-height: 500px;
      overflow-y: scroll;
      position: relative;
    }

    @media (max-width:  $media-maxwidth-breakpoint) {
      font-size: 15px;
    }
  }
  .accordion {
    p {
      color: $color-dark-grey;
      margin-right: 20px;
      width: 100%;
      span {
        font-weight: 700;
        position: relative;
        &:after {
          content: '';
          position: absolute;
          background: $color-dark-grey;
          height: 1px;
          width: 100%;
          left: 0;
          bottom: 0;
        }
      }
      &:nth-child(1) {
        @media (min-width: $media-breakpoint-md) {
          width: calc((100% + 20px)/10*5 + -20px);
        }
      }
      &:nth-child(2) {
        @media (min-width: $media-breakpoint-md) {
          width: calc((100% + 20px)/10*4 + -20px);
        }
      }
      &:nth-child(3) {
        @media (min-width: $media-breakpoint-md) {
          width: calc((100% + 20px)/10*6 + -20px);
        }
      }
    }
  }
}

.cross{
  margin-left:15px;
  margin-top:3px;
  &:before, &:after {
    content: '';
    border-top: 2px solid #3E474F;
    width: 15px;
    display: block;
    margin-top: 18px;
    transition: 0.3s;
  }
  &:after {
    transform: rotate(90deg);
    margin-top: -2px;
  }
}

.accordion {
  &.active {
    .cross {
      &:before {
        transform: rotate(180deg);
      }
      &:after {
        transform: rotate(0deg);
      }
    }
  }
}