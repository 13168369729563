.BreadCrumbModule {
	border-bottom: 1px solid $color-light-grey;

	&:first-child {
		margin-top: 0px;

		border-top: 1px solid $color-light-grey;
	}

	> div {
		padding-top: 20px;
		padding-bottom: 20px;

		ul {
			list-style-type: none;
			display: flex;

			@media (min-width: $media-breakpoint-md) {
				padding-left: getColWidth(1, 12, 1);
				padding-right: getColWidth(1, 12, 1);
			}

			@media (min-width: $media-breakpoint-lg) {
				padding-left: getColWidth(2, 12, 1);
				padding-right: getColWidth(2, 12, 1);
			}

			li {
				min-width: 0;
				// margin: 0px 5px;
				max-width: 300px;
				display: table-cell;
				flex-shrink: 1;
				&:last-child {
					text-decoration: underline;
				}
			}

			li.arrow {
				padding: 0px 5px;
				color: $color-brand;
			}

			li > * {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			li > a {
				opacity: 0.5;
				color: $color-brand;
				display: block;

				&.active {
					opacity: 1;
				}
			}

			li:last-child {
				@include color('color', 'links');

				&::before {
					text-decoration: none;
				}
			}

			a:hover {
				text-decoration: underline;
				@include color('color', 'text');
				opacity: 1;
			}
		}
	}
}
