.vjs-modal-dialog.vjs-text-track-settings {
	//background-color: $primary-background-color;
	background-color: rgba(#000, 0.75);
	color: $primary-foreground-color;
	height: 70%;
	.vjs-control.vjs-close-button {
		visibility: hidden;
	}
}

// Layout divs
.vjs-text-track-settings .vjs-modal-dialog-content {
	display: table;
}

.vjs-text-track-settings .vjs-track-settings-colors,
.vjs-text-track-settings .vjs-track-settings-font,
.vjs-text-track-settings .vjs-track-settings-controls {
	display: table-cell;
}

.vjs-text-track-settings .vjs-track-settings-controls {
	text-align: right;
	vertical-align: bottom;
}

// code that will only run if CSS Grid is supported by the browser
@supports (display: grid) {
	.vjs-text-track-settings .vjs-modal-dialog-content {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: 1fr auto;
	}

	.vjs-text-track-settings .vjs-track-settings-colors {
		display: block;
		grid-column: 1;
		grid-row: 1;
	}

	.vjs-text-track-settings .vjs-track-settings-font {
		grid-column: 2;
		grid-row: 1;
	}

	.vjs-text-track-settings .vjs-track-settings-controls {
		grid-column: 2;
		grid-row: 2;
	}
}

// Form elements
.vjs-track-setting > select {
	margin-right: 5px;
}

.vjs-text-track-settings fieldset {
	margin: 5px;
	padding: 3px;
	border: none;
}

.vjs-text-track-settings fieldset span {
	display: inline-block;
}

.vjs-text-track-settings legend {
	color: $primary-foreground-color;
	margin: 0 0 5px 0;
}

// Hide labels, so they are only for screen reader users
.vjs-text-track-settings .vjs-label {
	position: absolute;
	clip: rect(1px 1px 1px 1px); // for Internet Explorer
	clip: rect(1px, 1px, 1px, 1px);
	display: block;
	margin: 0 0 5px 0;
	padding: 0;
	border: 0;
	height: 1px;
	width: 1px;
	overflow: hidden;
}

.vjs-track-settings-controls button:focus,
.vjs-track-settings-controls button:active {
	outline-style: solid;
	outline-width: medium;
	background-image: linear-gradient(0deg, $primary-foreground-color 88%, $secondary-background-color 100%);
}

.vjs-track-settings-controls button:hover {
	color: rgba(#2b333f, 0.75);
}

.vjs-track-settings-controls button {
	background-color: $primary-foreground-color;
	background-image: linear-gradient(-180deg, $primary-foreground-color 88%, $secondary-background-color 100%);
	color: #2b333f;
	cursor: pointer;
	border-radius: 2px;
}

.vjs-track-settings-controls .vjs-default-button {
	margin-right: 1em;
}
