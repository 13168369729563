.IconGridModule {
	.grid {
		width: 100%;
		display: flex;
		flex-flow: row;
		flex-wrap: wrap;
		justify-content: space-between;
		margin-top: -40px;
		&:after {
			content: '';
			@media (min-width: $media-breakpoint-sm) {
				width: calc(50% - #{$gutter-size / 2});
			}
			@media (min-width: $media-breakpoint-lg) {
				width: calc(33.3333% - #{$gutter-size * 2 / 3});
			}
		}

		> div {
			width: 100%;
			margin-top: 40px;
			@media (min-width: $media-breakpoint-sm) {
				width: calc(50% - #{$gutter-size / 2});
			}
			@media (min-width: $media-breakpoint-lg) {
				width: calc(33.3333% - #{$gutter-size * 2 / 3});
			}
			display: flex;
			flex-direction: column;
			justify-content: flex-start;

			color: $color-brand;
			text-align: center;

			.svg-container {
				display: flex;
				align-items: center;
				flex-direction: column;
				justify-content: flex-end;
				height: 110px;

				margin-bottom: 20px;

				svg * {
					@include color('stroke', 'background');
				}
			}

			.text-container {
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
			}
		}
	}
}
