.RFPListModule {
	.list {
		margin-top: 20px !important;
	}
	.list-item {
		background: #fafafa;
		border: none !important;
		padding: 20px !important;
		margin-top: 20px;
		@media (min-width: $media-breakpoint-md) {
			padding: 30px !important;
		}
	}
	.download-link {
		color: $color-white !important;
		text-decoration: none !important;
		margin-top: 33px;
	}
	.date-range {
		display: block;
		color: map-get($color-blue-map, 'illustration');
	}
	h3 {
		margin-top: 16px;
	}
	.RFPListModule--list {
		display: flex;
		flex-flow: row;
		flex-wrap: wrap;
		& > div {
			width: 50%;
			display: flex;
			flex-flow: column;
			line-height: 20px;
			margin-top: 20px;
			.source-14-regular {
				color: #a7a8aa;
			}
			.source-14-semibold {
				color: #666666;
			}
		}
	}
	.RFPListModule--empty {
		background: #fafafa;
		width: 100%;
		text-align: center;
		padding: 60px 20px;
		@media (min-width: $media-breakpoint-md) {
			padding: 135px 20px;
		}
		color: #666666;
		h3 {
			@media (max-width: $media-breakpoint-md) {
				font-size: 20px;
				line-height: 23px;
			}
		}
	}
}
