.EmbedIssuuModule {
	position: relative;

	.padding {
		position: relative;

		> div {
			@media (min-width: $text-inset-breakpoint) {
				padding-left: getColWidth(1, 12, 1);
				padding-right: getColWidth(1, 12, 1);
			}

			@media (min-width: $burger-menu-breakpoint) {
				padding-left: getColWidth(2, 12, 1);
				padding-right: getColWidth(2, 12, 1);
			}

			color: $color-dark-grey;
			margin-bottom: 70px;
		}
	}
}
