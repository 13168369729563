footer {
	position: relative;
	background-color: $color-grey;
	flex-shrink: 0;

	display: flex;
	align-items: center;
	flex-direction: column;

	.footer-content {
		max-width: $max-page-width;
		width: 100%;

		@include poly-margin-fluid-property('padding-left');
		@include poly-margin-fluid-property('padding-right');

		padding-top: $desktop-side-margin;
		padding-bottom: $desktop-side-margin;

		/*padding-top: $section-top-spacing;*/ /*new added*/
		/*padding-bottom: $section-top-spacing;*/ /*new added*/

		display: flex;
		flex-direction: column;

		@media (min-width: $media-breakpoint-md) {
			/*flex-wrap: wrap;*/
			flex-direction: row;
			justify-content: space-between;
		}

		.links {
			display: flex;
			/*flex-direction: column;*/
			@media (max-width: $media-maxwidth-breakpoint) {
				flex-wrap: wrap;
			}

			@media (min-width: $media-breakpoint-md) {
				/*flex-wrap: wrap;*/
				/*height: 350px;*/
				height: auto;
				width: 100%;
			}

			@media (min-width: $media-breakpoint-lg) {
				align-content: flex-start;
				/*width: getColWidth(6);*/
			}

			div {
				@media (min-width: $media-breakpoint-md) {
					width: 50%;
				}

				@media (min-width: $media-breakpoint-lg) {
					width: getColWidth(2, 6, 1);
				}
			}

			ul {
				list-style-type: none;
				/*margin-bottom: 40px;*/

				@media (min-width: $media-breakpoint-md) {
					flex-wrap: wrap;
				}

				li {
					color: $color-dark-grey;
					margin-bottom: 4px;
				}
			}

			a {
				color: $color-dark-grey !important;
				transition: color 0.15s;

				&:hover {
					color: map-get($color-green-map, 'text') !important;
					text-decoration: underline;
				}
			}

			.socials {
				margin-top: 14px;
				display: flex;

				div {
					margin-right: 8px;
				}

				.btn-round {
					width: 30px;
					height: 30px;
					border-radius: 30px;
				}
			}
			> div {
				@media (max-width: $media-maxwidth-breakpoint) {
					width: 100%;
					margin-bottom: 30px;
				}
			}
		}

		.rounded-12-light {
			color: $color-dark-grey;
			opacity: 0.5;
			margin-bottom: 14px;
			&.terms-and-copyright {
				/* new added */
				opacity: 0.9;
				margin-bottom: 0;
			}
		}

		.bottom {
			display: flex;
			flex-direction: column;

			@media (min-width: $media-breakpoint-md) {
				padding-right: 50px;
				/*justify-content: space-between;*/
				height: auto;
			}

			@media (min-width: $media-breakpoint-lg) {
				/*flex-direction: row*/
				height: auto;
				width: 100%;
				padding-right: 0px;
			}

			.seals {
				margin-top: 40px;
				display: flex;
				/*justify-content: flex-start;*/
				justify-content: flex-end;

				@media (max-width: $media-maxwidth-breakpoint) {
					justify-content: center;
					margin-top: 10px;
				}

				@media (min-width: $media-breakpoint-md) {
					margin-top: 0px;
				}

				img {
					max-width: 100%;
				}

				a {
					max-width: 100%;
					/*margin-right: 50px;*/
					margin-right: 30px;
				}

				a:first-child {
					@include arabic() {
						margin-right: 0px;
						margin-left: 50px;
					}
				}

				a:last-child {
					margin-right: 0px;
				}
				.btn-round {
					margin-right: 8px;
					&:last-child {
						margin-right: 0;
					}
				}
			}

			.terms-and-copyright {
				/*margin-top: 15px;*/
				margin-top: 30px;
				/*justify-content: space-between;*/
				display: flex;

				align-items: flex-end;
				flex-wrap: wrap; /*new added*/

				@media (min-width: $media-breakpoint-lg) {
					/*width: 330px;*/
					width: 100%;
				}

				div {
					display: flex;
					/*flex-direction: column;*/
					width: 100%; /*new added*/
					justify-content: flex-end; /*new added*/

					@media (max-width: $media-maxwidth-breakpoint) {
						justify-content: center;
					}

					.mini-link {
						transition: color 0.15s;
						text-decoration: none;
						margin: 0 10px; /*new added*/
						opacity: 0.9; /*new added*/
						&:last-child {
							/*new added*/
							margin-right: 0;
						}

						&:hover {
							color: map-get($color-green-map, 'text') !important;
							text-decoration: underline;
						}
					}
				}
				span {
					/*new added*/
					width: 100%;
					display: flex;
					justify-content: flex-end;
					margin-top: 14px;
					opacity: 0.9;

					@media (max-width: $media-maxwidth-breakpoint) {
						justify-content: center;
					}
				}
			}
		}
	}
}
