.HeroModule {
	padding-bottom: 61px;

	display: flex;
	flex-direction: column;
	align-items: center;
	align-content: center;
	position: relative;

	.background-image {
		width: 100%;

		position: relative;

		overflow-y: hidden;
		background-color: $color-grey-light-background;

		img {
			position: relative;
		}
	}

	.text-wrapper {
		width: 100%;
		max-width: $max-page-width;
		display: flex;
		align-items: center;

		.text {
			/* @include poly-margin-fluid-property('padding-left');
			@include poly-margin-fluid-property('padding-right'); */
			width: 100%;
			@media (max-width: $media-maxwidth-breakpoint) {
				@include poly-margin-fluid-property('padding-left');
				@include poly-margin-fluid-property('padding-right'); 
			}
		}
	}

	.text-container {
		@include color('background-color', 'background');

		display: flex;
		flex-direction: column;
		align-items: center;

		width: 100%;

		@include poly-margin-fluid-property('margin-left');
		@include poly-margin-fluid-property('margin-right');

		.white-page & {
			border-bottom: 1px solid $color-light-grey;
		}

		@include poly-fluid-sizing(
			'padding-top',
			(
				576px: 35px,
				768px: 70px
			)
		);

		@include poly-fluid-sizing(
			'padding-right',
			(
				576px: 10px,
				768px: 0
			)
		);

		@include poly-fluid-sizing(
			'padding-bottom',
			(
				576px: 20px,
				768px: 40px
			)
		);

		@include poly-fluid-sizing(
			'padding-left',
			(
				576px: 10px,
				768px: 0
			)
		);

		position: relative;

		@include poly-fluid-sizing(
			'margin-top',
			(
				1000px: -50px,
				1300px: -50px,
				1600px: -75px
			)
		);
	}

	&.custom {
		//justify-content: flex-end;
		img {
			object-position: center bottom;
		}
	}

	.HeroModule__custom__text {
		position: absolute;
		text-align: center;
		color: $color-white;
		max-width: 1080px;
		margin-top: 10%;
		@media (min-width: $media-breakpoint-sm) {
			margin-top: 20%;
		}

		@media (min-width: $media-breakpoint-lg) {
			margin-top: 10%;
		}

		@media (min-width: $media-breakpoint-xl) {
			margin-top: 10%;
		}

		h1 {
			@include font('VAG Rounded Std', 28px, 34px, 300);

			@media (min-width: $media-breakpoint-sm) {
				font-size: 32px;
				line-height: 38px;
			}

			@media (min-width: $media-breakpoint-lg) {
				font-size: 42px;
				line-height: 48px;
			}

			@media (min-width: $media-breakpoint-xl) {
				font-size: 48px;
				line-height: 53px;
			}
		}

		.HeroModule__custom__text__subheader {
			opacity: 0;
			@include font('Source Sans Pro', 15px, 18px, 500);
		}
	}

	.text-style-h1 {
		color: $color-white;

		.white-page & {
			color: $color-dark-grey;
		}

		padding-bottom: 20px;
	}

	.text-style-subheader {
		color: $color-white;

		.white-page & {
			color: $color-dark-grey;
		}

		/* max-width: 1000px; */

		@media (min-width: $text-inset-breakpoint) {
			padding-left: getColWidth(1, 12, 1);
			padding-right: getColWidth(1, 12, 1);
		}

		@media (min-width: $burger-menu-breakpoint) {
			padding-left: getColWidth(2, 12, 1);
			padding-right: getColWidth(2, 12, 1);
		}

		text-align: center;
	}

	.btn-round {
		@include poly-fluid-sizing(
			'margin-top',
			(
				576px: 20px,
				768px: 50px
			)
		);
	}

	&.fullscreen {
		height: 100%;
		padding-bottom: 0px;
		//height: calc(100% - #{$menu-mobile-height});
		//margin-top: 60px;

		@media (min-width: $burger-menu-breakpoint) {
			//margin-top: 0px;
			//height: calc(100% - #{$menu-desktop-height});
		}

		.aspect-content {
			width: 100%;
			height: 100%;
		}

		.btn-round {
			margin-top: 20px;

			@media (min-width: $burger-menu-breakpoint) {
				margin-top: 0px;
			}
		}

		.background-image {
			width: 100%;
			height: 100%;

			img {
				max-height: inherit;
			}
		}

		.text-wrapper {
			position: absolute;
			left: 0px;
			width: 100%;
			max-width: none;
			display: flex;
			top: 60px;
			bottom: 16px;

			@media (min-width: $media-breakpoint-md) {
				bottom: 40px;
			}

			.text-container {
				display: flex;
				justify-content: space-around;
				flex-direction: column;
				align-items: center;
				align-content: center;
			}

			.text {
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: flex-end;

				.text-style-subheader {
					display: none;
				}

				@media (min-width: $media-breakpoint-sm) {
					justify-content: flex-end;
					margin-bottom: 4vh;

					.text-style-subheader {
						display: inherit;
					}
				}

				@media (min-width: $media-breakpoint-lg) {
					margin-bottom: 7.5vh;
				}

				@media (min-width: $media-breakpoint-xxl) {
					margin-bottom: 10vh;
				}

				h1 {
					margin-top: 60px;
				}
			}

			.btn-round {
				flex-shrink: 0;
			}
		}

		.text-container {
			background-color: transparent;
			width: 100%;
			padding: 0px;
			height: 100%;

			margin-top: 0px;
		}

		.gradient {
			position: absolute;
			bottom: 0px;
			width: 100%;
			height: 75%;
			left: 0px;
			right: 0px;
		}
	}

	&.image-only {
		overflow: hidden;
		background-color: rgba($color: $color-light-grey, $alpha: 0.3);
		display: flex;
		justify-content: space-around;
		align-content: center;
		align-items: center;

		img {
			object-fit: cover;
			font-family: 'object-fit: cover';
			height: 100%;
		}
	}

	&.has-video {
		.header-image {
			cursor: pointer;
		}
	}

	.HeroModule__video-popup {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 100;
		background: rgba(0, 0, 0, 0.9);
		opacity: 0;
		display: flex;
		justify-content: center;
		align-items: center;

		.HeroModule__video-popup__close-button {
			position: fixed;
			top: 20px;
			right: 20px;
			cursor: pointer;
			@media (min-width: $media-breakpoint-md) {
				position: absolute;
			}
			transition: transform 0.2s;
			will-change: transform;

			&:hover {
				transform: scale(1.08);
			}
		}

		.HeroModule__video-popup__inner {
			@include aspect-ratio(16, 9);
			width: 100%;
			height: auto;
			max-height: 100%;
			max-width: 1920px;

			@media (min-width: $media-breakpoint-md) {
				width: calc(100% - 80px);
			}
			@media (min-width: $media-breakpoint-lg) {
				width: calc(100% - 272px);
			}
			@media (min-width: $media-breakpoint-xxl) {
				width: calc(100% - 350px);
			}
		}

		.video-js {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}

	.play-btn {
		position: absolute;
		@include hollowCircle($color-white, 60px, 1, 2px);
		z-index: 0;
		@include poly-fluid-sizing(
			'margin-top',
			(
				1000px: -50px / 2,
				1300px: -50px / 2,
				1600px: -75px / 2
			)
		);
		top: calc(50% - 30px);
		left: calc(50% - 30px);
		transform-origin: center;

		display: flex;
		justify-content: space-around;
		align-items: center;
		padding-left: 4px;
	}

	&:not(.fullscreen) {
		padding-bottom: 0px;

		.background-image {
			max-height: 810px;
			@include aspect-ratio(320px, 400px);
			@media (min-width: $media-breakpoint-md) {
				@include aspect-ratio(768px, 500px);
			}
			@media (min-width: $media-breakpoint-lg) {
				@include aspect-ratio(1440px, 600px);
			}
			@media (min-width: $media-breakpoint-xxl) {
				@include aspect-ratio(2560px, 810px);
			}

			.aspect-content {
				max-height: 810px;
			}
		}
	}

	&.color-only,
	&.color-only-narrow {
		padding-bottom: 0px;

		.text-wrapper {
			max-width: inherit;
			margin: 0px;

			.text-container {
				margin: 0px 0px 0px 0px;
				padding: 0px;

				// min-height: 590px;

				@media (min-width: $burger-menu-breakpoint) {
					height: 10px; //ie11 fix;
					min-height: 600px;
				}

				.text {
					margin-top: 70px;
					margin-bottom: 120px;
				}

				display: flex;
				flex-direction: column;
				justify-content: center;
			}

			.btn-round {
				position: absolute;
				bottom: 30px;
			}
		}
	}

	&.color-only-narrow {
		.text-wrapper {
			height: inherit;

			.text-container {
				height: 10px; //ie11 fix;
				min-height: 332px;

				@media (min-width: $burger-menu-breakpoint) {
					min-height: 402px;
				}

				.text {
					margin-top: 0px;
					margin-bottom: 0px;

					h1 {
						margin-top: 0px;
						padding-top: $gutter-size;
						padding-bottom: 0px;
					}
				}

				h3 {
					padding-top: 10px;
					margin-bottom: $gutter-size;
				}
			}

			.btn-round {
				display: none;
			}
		}
	}
}
