.PressReleasesListModule {
	.list {
		width: 100%;

		.press-item {
			width: 100%;
			justify-content: flex-start;
			flex-direction: column;
			display: flex;
			color: inherit;
			position: relative;

			@include top-margin(0.75);

			@media (min-width: $media-breakpoint-md) {
				flex-direction: row;
				@include top-margin(0.5);
			}

			&:first-child {
				margin-top: 0px;
			}

			&:hover {
				.press-release-name {
					@include color('color', 'links');
				}
			}

			.date {
				@include color('color', 'text');

				width: 100%;

				@media (min-width: $media-breakpoint-md) {
					width: getColWidth(4, 12, 1);
				}
			}

			.press-release-name {
				color: $color-dark-grey;
				width: 100%;

				@media (min-width: $media-breakpoint-md) {
					width: getColWidth(6, 12);
				}
			}
		}
	}

	.pagination {
		@include top-margin();

		display: flex;
		width: 100%;
		justify-content: space-between;
		flex-flow: row;
		flex-wrap: wrap;
		@media (min-width: $media-breakpoint-md) {
			flex-flow: row;
			height: 40px;
			justify-content: flex-start;
		}
		select::-ms-expand {
			display: none;
		}
		.items-per-page {
			display: flex;
			flex-flow: column;
			@media (min-width: $media-breakpoint-md) {
				height: 40px;
				flex-flow: row;
				justify-content: flex-start;
				align-items: center;
			}

			.per-page-dropdown {
				height: 100%;
				margin-right: 12px;

				select {
					appearance: none;
					height: 40px;
					border-radius: 2px;
					border: 1px solid $color-light-grey;
					background-color: transparent;
					padding: 0px 20px;
					padding-right: 60px;
					background: url("data:image/svg+xml,%3Csvg width='40' height='40' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath fill='%233D3D3D' d='M23.5 18.5l-3.5 4-3.5-4z'/%3E%3Cpath fill='%23E2E2E2' fill-rule='nonzero' d='M0 0h1v40H0z'/%3E%3C/g%3E%3C/svg%3E")
						no-repeat right center;
				}
			}

			p {
				color: rgba($color: $color-dark-grey, $alpha: 0.8);
				margin-bottom: 10px;
				@media (min-width: $media-breakpoint-md) {
					margin-bottom: 0px;
				}
			}

			width: getColWidth(4, 12, 1);
		}

		.pages-list {
			display: flex;
			height: 40px;

			.pages-button {
				border: 1px solid $color-light-grey;
				width: 40px;

				display: flex;
				align-items: center;
				justify-content: space-around;

				svg > g {
					fill: $color-dark-grey;
				}

				&.left-arrow {
					border-radius: 3px 0px 0px 3px;
				}

				&.right-arrow {
					border-radius: 0px 3px 3px 0px;
				}

				&.active {
					@include color('background-color', 'links');
					@include color('border-color', 'links');

					svg > g {
						fill: $color-white;
					}
				}
			}

			.pages {
				position: relative;
				display: flex;
				border-top: 1px solid $color-light-grey;
				border-bottom: 1px solid $color-light-grey;
				height: 100%;
				align-content: center;
				align-items: center;

				.page {
					padding: 0px 15px;
					text-align: center;

					z-index: 1;
					position: relative;
					color: $color-dark-grey;

					&.active {
						color: $color-white;

						&:before {
							content: '';
							position: absolute;
							display: block;
							z-index: -1;
							width: 26px;
							height: 26px;
							border-radius: 26px;
							left: calc(50% - 13px);
							top: calc(50% - 12px);

							@include color('background-color', 'links');
						}
					}
				}
			}
		}
	}
}
