.PersonProfileTemplate {
    .person-hero {
        width: 100%;
        display:flex;

        color: $color-white;

        flex-direction: column;
        height: 80vh;

        @media(min-width: $media-breakpoint-sm) {
            flex-direction: row;
            height: 475px;
        }

        .left,.right {
            width: 100%;
        
            @media(min-width: $media-breakpoint-sm) {
                height: 100%;
            }
        }

        .left {
            display:flex;
            justify-content: flex-end;
            flex: 0.4 0 auto;

            @media(min-width: $media-breakpoint-sm) {
                flex: inherit;
            }

            .text {
                @include poly-margin-fluid-property('padding-left');

                width: 100%;
                max-width: $max-page-width*0.5;

                display:flex;
                justify-content: space-around;
                flex-direction: column;

                margin-right: 20px;
                
                .text-container {
                    width: 80%;

                    @media(min-width: $media-breakpoint-sm) {
                        width: 100%;
                    }

                    @media(min-width: $text-inset-breakpoint) {
                        margin-left: getColWidth(1, 6, 0.75);
                        max-width: getColWidth(4, 6, 0.75);
                    }

                    h1 {
                        margin-bottom: 12px;
                    }
                    
                }
            }
        }

        .right {
            background-color: $color-grey-light-background;
            flex: 1 0 auto;
            height: 1px;

            @media(min-width: $media-breakpoint-sm) {
                flex: inherit;
                height: auto;
            }

            .profile-image {
                width: 100%;
                max-width: $max-page-width*0.5;
                height: 100%;

                padding-top: 60px;

                @media (min-width: $burger-menu-breakpoint) {
                    padding-top: 70px;                
                }

                @media(min-width: $media-breakpoint-lg) {
                    @include poly-margin-fluid-property('padding-right');
                }                

                img {
                    width: 100%;
                    height:100%;
                    object-fit: cover;
					font-family: "object-fit: cover";
				}
            }
        }
    }
}

//this has to be out here.
.person-hero {
    @include color("background-color", "background");
}
