$color-green-map: (
	links: #00ab4d,
	illustration: #00953b,
	text: #00953b,
	background: #00ab4d
);

$color-yellow-map: (
	//not corrected.
		links: #ffd400,
	illustration: #ffc600,
	text: #ffb500,
	background: #ffb500
);

$color-orange-map: (
	links: #ff9800,
	illustration: #ff9800,
	text: #ff7500,
	hover: #ff7500,
	background: #ff9800
);

$color-purple-map: (
	links: #d31888,
	illustration: #d31888,
	text: #d31888,
	hover: #9d1d96,
	background: #d31888
);

$color-blue-map: (
	links: #0033a1,
	illustration: #0033a1,
	text: #0050b5,
	hover: #0050b5,
	background: #0033a1
);

$color-teal-map: (
	links: #009999,
	illustration: #009999,
	text: #00c4b3,
	hover: #00c4b3,
	background: #009999
);

$color-teal-map: (
	links: #009999,
	illustration: #009999,
	text: #00c4b3,
	hover: #00c4b3,
	background: #009999
);

$color-white-map: (
	links: #00ab4d,
	illustration: #666666,
	text: #333333,
	hover: #333333,
	background: #ffffff
);

@function get-color-map($color) {
	$color-map: $color-green-map;

	@if $color == 'green' {
		$color-map: $color-green-map;
	} @else if $color == 'orange' {
		$color-map: $color-orange-map;
	} @else if $color == 'purple' {
		$color-map: $color-purple-map;
	} @else if $color == 'blue' {
		$color-map: $color-blue-map;
	} @else if $color == 'teal' {
		$color-map: $color-teal-map;
	} @else if $color == 'yellow' {
		$color-map: $color-yellow-map;
	} @else {
		$color-map: $color-green-map;
	}

	@return $color-map;
}

@mixin color($parameter, $variation) {
	.green-page & {
		#{$parameter}: map-get($color-green-map, $variation);
	}

	.orange-page & {
		#{$parameter}: map-get($color-orange-map, $variation);
	}

	.purple-page & {
		#{$parameter}: map-get($color-purple-map, $variation);
	}

	.blue-page & {
		#{$parameter}: map-get($color-blue-map, $variation);
	}

	.teal-page & {
		#{$parameter}: map-get($color-teal-map, $variation);
	}

	.yellow-page & {
		#{$parameter}: map-get($color-yellow-map, $variation);
	}

	.white-page & {
		#{$parameter}: map-get($color-white-map, $variation);
	}
}
