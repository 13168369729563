.CelebrityAirdatesModule {
	.table {
		display: flex;
		flex-direction: column;

		.table-body {
			border-top: 1px solid $color-light-grey;
			@media (min-width: $media-breakpoint-md) {
				border-top: none;
			}

			.table-line {
				min-height: 100px;
			}
		}

		.table-line {
			display: flex;
			width: 100%;
			color: $color-dark-grey;
			flex-flow: column;

			border-bottom: 1px solid $color-light-grey;
			@media (min-width: $media-breakpoint-md) {
				flex-flow: row;
			}

			&.header-column {
				padding: 0px 0px 10px;
				@include color('color', 'text');
				display: none;
				@media (min-width: $media-breakpoint-md) {
					display: flex;
				}
			}

			padding: 10px 0px;

			> div {
				width: 100%;
				margin-right: $gutter-size;

				margin-top: 17px;

				&:before {
					content: attr(class);
					text-transform: capitalize;
					display: block;
					font-weight: 600;
					@include color('color', 'text');
					@media (min-width: $media-breakpoint-md) {
						content: none;
					}
				}

				&:last-child {
					margin-right: 0px;
					margin-bottom: 17px;
					@media (min-width: $media-breakpoint-md) {
						margin-bottom: 0px;
					}
				}
			}
		}
	}
}
