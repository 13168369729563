.Search {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: $menu-mobile-height;
	z-index: 6;
	opacity: 0;
	visibility: hidden;
	@media (min-width: $burger-menu-breakpoint) {
		height: $menu-desktop-height;
	}

	input {
		-webkit-appearance: none;
		border-radius: 0;
		position: absolute;
		top: 0;
		left: 0;
		margin: 0;
		border: none;
		outline: none;
		height: 100%;
		width: 100%;
		padding-top: 2px;
		padding-left: 58px;
		padding-right: 58px;
		color: #666666;
		padding-bottom: 0;
		@include font('VAG Rounded Std', 18px, 23px, 300);

		@media (min-width: $media-breakpoint-md) {
			padding-left: 100px;
			padding-right: 100px;
			font-size: 22px;
			line-height: 22px;
		}

		@media (min-width: $media-breakpoint-lg) {
			padding-left: 136px;
			padding-right: 136px;
		}
	}

	.Search__close-icon {
		cursor: pointer;
		position: absolute;
		right: 0;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 60px;
		@include arabic() {
			right: auto;
			left: 0;
		}
		@media (min-width: $media-breakpoint-md) {
			padding-right: 20px;
			@include arabic() {
				padding-right: 0;
				padding-left: 20px;
			}
		}
		@media (min-width: $media-breakpoint-lg) {
			margin-right: 28px;
			@include arabic() {
				margin-right: 0;
				margin-left: 28px;
			}
		}
		top: 0;
	}
}

.Search__results-wrapper {
	visibility: hidden;
	opacity: 0;
	display: none;
	position: fixed;
	left: 0;
	right: 0;
	z-index: 0;
	min-height: 100vh;
	//border-top: 1px solid $color-light-grey;
	top: $menu-mobile-height;
	background: $color-white;
	bottom: 0;
	overflow-y: scroll;
	overscroll-behavior: contain;
	-webkit-overflow-scrolling: touch;

	flex-flow: column;
	align-items: center;

	@media (min-width: $burger-menu-breakpoint) {
		top: $menu-desktop-height;
	}

	& > div.default-content-inset {
		position: static;
		display: flex;
		flex-flow: column;
		align-items: center;
		max-width: 874px !important;
	}

	.Search__header {
		color: #333333;
		margin-top: 40px;

		@include font('VAG Rounded Std', 20px, 28px, 300);
		@media (min-width: $media-breakpoint-lg) {
			font-size: 28px;
			line-height: 44px;
			margin-top: 60px;
		}
		@media (min-width: $media-breakpoint-xl) {
			margin-top: 80px;
		}

		span {
			color: map-get($color-green-map, 'links');
		}
	}

	.Search__results-wrapper__results-list {
		border-top: 1px solid $color-grey;
		margin-top: 50px;
		padding-bottom: 70px;
		width: 100%;
		@media (min-width: $media-breakpoint-md) {
			margin-top: 70px;
			padding-bottom: 100px;
		}
		@media (min-width: $media-breakpoint-lg) {
			padding-bottom: 130px;
		}
		@media (min-width: $media-breakpoint-xl) {
			margin-top: 90px;
			padding-bottom: 180px;
		}
	}

	.Search__reponse-item {
		display: flex;
		flex-flow: column;
		align-items: flex-start;
		padding-top: 20px;
		padding-bottom: 20px;
		border-bottom: 1px solid $color-grey;
		color: #333333;
		@media (min-width: $media-breakpoint-md) {
			flex-flow: row;
		}
		@media (min-width: $media-breakpoint-xl) {
			padding-top: 25px;
			padding-bottom: 25px;
		}

		.Search__response-item__image-wrapper {
			@include aspect-ratio(202, 112);
			width: 100%;

			overflow: hidden;
			flex-grow: 0;
			flex-shrink: 0; //ie11 hack
			max-width: 130px;
			@media (min-width: $media-breakpoint-md) {
				margin-right: 80px;
				max-width: 202px;
			}
			@media (min-width: $media-breakpoint-lg) {
				margin-right: 100px;
			}
			@media (min-width: $media-breakpoint-xl) {
				margin-right: 130px;
			}
			background: $color-grey;

			img {
				position: absolute;
				width: 100%;
				transform: translateX(-50%) translateY(-50%);
				top: 50%;
				left: 50%;
				&.fallback {
					width: 80%;
				}
			}
		}

		h4 {
			color: map-get($color-green-map, 'links');
			line-height: 26px;
			margin-top: 19px;
			@media (min-width: $media-breakpoint-md) {
				margin-top: 0px;
			}
		}

		.Search__response-item__highlight {
			margin-top: 6px;
			line-height: 24px;
			@media (min-width: $media-breakpoint-lg) {
				font-size: 18px;
				line-height: 26px;
			}
		}

		em {
			font-style: italic;
			color: map-get($color-green-map, 'links');
		}
	}

	.Search__search-suggestion__word {
		color: map-get($color-green-map, 'links');
	}
}

.Search__background {
	position: fixed;
	top: 0;
	height: 100vh;
	left: 0;
	right: 0;
	max-width: none !important;
	background-color: rgba($color: #000000, $alpha: 0.6);
	visibility: hidden;
	opacity: 0;
}

html.touch {
	.Search {
		//position: fixed;
	}

	.Search__results-wrapper {
		position: absolute;
		overflow-y: initial;
		//min-height: 100%;
		bottom: auto;
		-webkit-overflow-scrolling: initial;
	}
}
@media (max-width: $burger-menu-breakpoint - 1) {
	html.Search__visible {
		.menu {
			position: fixed;
		}
	}
}

html:not(.touch) {
	.Search__results-wrapper {
		min-height: 100vh !important;
	}

	&.Search__visible {
		@media (min-width: $burger-menu-breakpoint) {
			body {
				&:after {
					content: '';
					position: fixed;
					top: 0;
					right: 0;
					width: 20px;
					background: $color-white;
					height: $menu-desktop-height;
					z-index: 1;
				}
			}
		}
	}
}
