.StoryModule {
	picture {
		width: 100%;

		img {
			width: 100%;
		}
	}

	.subheader {
		margin-top: 30px;

		@media (min-width: $burger-menu-breakpoint) {
			margin-top: 55px;
		}
	}

	.btn-pill {
		margin-top: 28px;

		@media (min-width: $burger-menu-breakpoint) {
			margin-top: 40px;
		}
	}

	.img-wrapper {
		@include aspect-ratio(1560px, 700px);
	}
}
