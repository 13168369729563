.PressImagesBreakerModule {
	.media-container {
		display: flex;
		flex-flow: row;
		flex-wrap: wrap;
		justify-content: space-between;
		width: 100%;

		@media (min-width: $text-inset-breakpoint) {
			width: getColWidth(11);
		}

		@media (min-width: $media-breakpoint-lg) {
			width: getColWidth(10);
		}

		.press-image {
			position: relative;
			overflow: hidden;
			width: 100%;
			margin-bottom: 10px;
			@media (min-width: $media-breakpoint-lg) {
				margin-bottom: 20px;
			}

			.aspect-content {
				@include aspect-ratio(1280, 720);
				background-color: $color-grey-light-background;
			}

			&.portrait-mode {
				width: calc(50% - 5px);
				@media (min-width: $media-breakpoint-lg) {
					width: calc(50% - 10px);
				}
				.aspect-content {
					@include aspect-ratio(630, 795);
				}
			}

			picture {
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
				width: 100%;
				display: block;
				img {
					width: 100%;
				}
			}

			.download-btn {
				position: absolute;
				bottom: 10px;
				right: 10px;
				width: 32px;
				height: 32px;

				background-color: $color-white;
				border-radius: 50%;

				display: flex;
				align-items: center;
				justify-content: space-around;
				padding-bottom: 2px;

				svg {
					width: 12px;
					height: 17px;
					@media (min-width: $media-breakpoint-lg) {
						width: 17px;
						height: 24px;
					}
				}

				@media (min-width: $media-breakpoint-lg) {
					bottom: 30px;
					right: 30px;
					width: 44px;
					height: 44px;
				}
			}
			.caption-text {
				width: 100%;
				background-color: $color-grey;
				color: rgba($color: $color-dark-grey, $alpha: 0.5);
				padding: 15px 15px;

				@media (min-width: $media-breakpoint-md) {
					padding: 15px 40px;
				}
			}
		}

		.press-image:last-child {
			margin-right: 0px;
		}
	}
}
