.TextStatsModule {
	> div {
		display: flex;
		position: relative;

		flex-direction: column;

		@media (min-width: $media-breakpoint-md) {
			flex-direction: row;
		}

		color: white;

		> div {
			display: flex;
			flex-direction: column;
			position: relative;
			padding: 32px 25px 32px 25px;
			width: 100%;

			margin-top: 20px;

			&:first-child {
				margin-top: 0px;
			}

			min-height: 300px;

			@media (min-width: $media-breakpoint-md) {
				margin-top: 0px;

				min-height: 400px;

				width: getColWidth(6, 12);
			}

			margin-right: $gutter-size;

			&:last-child {
				margin-right: 0px;
			}

			p {
				width: 100%;

				@media (min-width: $media-breakpoint-md) {
					width: 85%;
				}

				@media (min-width: $media-breakpoint-xl) {
					width: 70%;
				}
			}

			.graphic {
				display: none;

				@media (min-width: $media-breakpoint-lg) {
					display: flex;
				}

				position: absolute;
				bottom: 0px;
				right: 0px;

				opacity: 0.5;

				fill: white;

				svg * {
					fill: $color-white;
					stroke: $color-white;
				}
			}

			@include color('background-color', 'background');
		}
	}
}
