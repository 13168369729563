.ListItem {
	width: 100%;
	background-color: $color-grey-light-background;
	margin-top: $gutter-size * 0.5;

	overflow-y: hidden;
	opacity: 1;

	&.hidden {
		margin-top: 0px;
		height: 0px;
		display: none;
		opacity: 0;
	}

	&:first-child {
		margin-top: 0px;
	}

	.padding {
		.item-content {
			display: flex;
			padding: 25px 0px 20px;
		}
	}
}
