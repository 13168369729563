.DonateInputs {
	width: 100%;
	display: flex;
	flex-direction: column;
	// align-items: flex-start;
	justify-content: space-between;

	.input-btn-text {
		width: 100%;
		text-align: center;
		position: relative;
		
		.postInput {
			position: absolute;
			right: 10px;
			top: 8px;
		}
	}

	.donate-choices {
		align-self: stretch;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin-bottom: 12px;
	}
}
